import { autoUpdate, flip, offset, size, useFloating, type UseFloatingReturn } from '@floating-ui/react';

const useFloatingForDropdown = (): UseFloatingReturn => {
  return useFloating({
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });
};

export default useFloatingForDropdown;
