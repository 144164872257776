import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Select } from '@/components/ui/select';
import { toast } from '@/components/ui/toaster';

import { useCancelMeeting } from '@/hooks/meetings';

import { MEETING_ENDPOINTS } from '@/services/meetings';

import { CANCEL_MEETING_REASON_OPTIONS_BUSINESS } from '@/constants/meeting';

import { cancelMeetingSchema, TCancelMeetingFormData } from '@/validation-schemas/cancel-meeting-schema';

const CancelMeetingForm: React.FC<{ onSubmit: (data: TCancelMeetingFormData) => void; isSubmitting: boolean }> = ({
  onSubmit,
  isSubmitting,
}) => {
  const form = useForm<TCancelMeetingFormData>({
    resolver: zodResolver(cancelMeetingSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  return (
    <FormProvider {...form}>
      <Form className="max-w-lg space-y-4">
        <FormField
          name="reason"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Reason</FormLabel>
              <FormControl>
                <Select
                  options={CANCEL_MEETING_REASON_OPTIONS_BUSINESS}
                  selected={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.reason?.message}
                  disableAlphabeticalSorting
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <AlertDialogFooter>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
        <Button
          type="submit"
          variant={'destructive'}
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
          loadingText="Cancelling"
        >
          Cancel interview
        </Button>
      </AlertDialogFooter>
    </FormProvider>
  );
};

interface IBusinessCancelMeetingButtonProps {
  disabled?: boolean;
  candidateName: string;
  meetingId: number;
}

const BusinessCancelMeetingButton: React.FC<IBusinessCancelMeetingButtonProps> = ({
  disabled,
  meetingId,
  candidateName,
}) => {
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { mutate: cancelMeeting, isPending: isCancelling } = useCancelMeeting({
    params: { path: { meetingId } },
    reactQueryOptions: {
      onSuccess: () => {
        setOpen(false);
        toast.success('Meeting cancelled successfully!');
      },
    },
  });

  const handleSubmit = (data: TCancelMeetingFormData) => {
    cancelMeeting({
      params: {
        path: {
          meetingId,
        },
      },
      body: {
        reason: data.reason,
        description: data?.description || '',
      },
    });
  };

  const renderContent = () => {
    if (step === 1) {
      return (
        <CancelMeetingForm
          onSubmit={handleSubmit}
          isSubmitting={isCancelling}
        />
      );
    }

    return null;
  };

  const renderDescription = () => {
    if (step === 1) {
      return (
        <>
          Please tell us why you would like to cancel the interview.
          <br />
          Candidate will be notified of the cancellation and reason.
        </>
      );
    }
    if (step === 2) {
      return <p>You have successfully cancelled your interview with {candidateName}.</p>;
    }
    return null;
  };

  const renderTitle = () => {
    if (step === 1) {
      return 'Cancel interview';
    }
    if (step === 2) {
      return 'Cancelled';
    }
    return null;
  };

  const renderFooter = () => {
    if (step === 1) return;

    return (
      <AlertDialogFooter>
        <AlertDialogAction
          onClick={() =>
            queryClient.invalidateQueries({
              queryKey: [MEETING_ENDPOINTS.GET_MEETINGS],
            })
          }
        >
          Continue
        </AlertDialogAction>
      </AlertDialogFooter>
    );
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        setTimeout(() => setStep(1), 200);
      }}
    >
      <AlertDialogTrigger asChild>
        <Button
          variant="ghost"
          disabled={disabled}
          className="text-primary-alert-100"
          size={'sm'}
        >
          Need to cancel?
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="max-w-xl gap-6">
        <AlertDialogHeader>
          <AlertDialogTitle iconVariant={step > 1 ? 'success' : 'warning'}>{renderTitle()}</AlertDialogTitle>
          <AlertDialogDescription className="pb-0">{renderDescription()}</AlertDialogDescription>
        </AlertDialogHeader>
        {renderContent()}
        {renderFooter()}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default BusinessCancelMeetingButton;
