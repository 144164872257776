import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';

import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { getOptionsForSelectFromValuesMap } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

import {
  additionalLanguageSchema,
  domainExperienceSchema,
  TAdditionalLanguageFormData,
  TDomainExperienceFormData,
} from '@/validation-schemas/coupled-fields.schema';

import { Button } from '../ui/button';
import ComboBox from '../ui/combo-box';
import { Select } from '../ui/select';

interface IAdditionalLanguageProps {
  defaultValues?: TAdditionalLanguageFormData;
  onSubmit: (data: TAdditionalLanguageFormData) => void;
  selectedLanguages?: string[];
}

export const AdditionalLanguageForm: React.FC<IAdditionalLanguageProps> = ({
  defaultValues,
  selectedLanguages,
  onSubmit,
}) => {
  const form = useForm<TAdditionalLanguageFormData>({
    resolver: zodResolver(additionalLanguageSchema),
    defaultValues,
    values: defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = form;

  const { data: languagesList, isLoading: isLanguageListLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.LANGUAGES,
      },
    },
  });
  const { data: languagesProficiencyList, isLoading: isLanguageProficiencyListLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.ENGLISH_PROFECIENCY,
      },
    },
  });

  const onAdd: SubmitHandler<TAdditionalLanguageFormData> = async (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <FormProvider {...form}>
      <Form className="flex gap-4">
        <div className="grid grid-cols-2 items-start gap-x-8 pt-8">
          <FormField
            name="language"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Additional languages</FormLabel>
                <FormControl>
                  <ComboBox
                    options={getOptionsForSelectFromValuesMap(languagesList).filter(
                      (option) => !selectedLanguages?.includes(option.value)
                    )}
                    selected={field.value ? { value: field.value, label: field.value } : null}
                    placeholder="Select Option"
                    onSelect={(value) => field.onChange(value)}
                    error={!!errors?.language?.message}
                    isLoading={isLanguageListLoading}
                    ref={field.ref}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            name="proficiency"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Competency</FormLabel>
                <FormControl>
                  <Select
                    className="flex-grow-0"
                    options={getOptionsForSelectFromValuesMap(languagesProficiencyList)}
                    selected={field.value}
                    placeholder="Select Option"
                    onChange={(value) => field.onChange(value)}
                    error={!!errors?.proficiency?.message}
                    isLoading={isLanguageProficiencyListLoading}
                    ref={field.ref}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
        </div>
        <div>
          <Button
            onClick={handleSubmit(onAdd)}
            className="mt-[1.90rem]"
          >
            Add
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};

interface IDomainExperienceProps {
  defaultValues?: TDomainExperienceFormData;
  onSubmit: (data: TDomainExperienceFormData) => void;
  selectedDomains?: string[];
  error: boolean;
}

export const DomainExperienceForm: React.FC<IDomainExperienceProps> = ({
  selectedDomains,
  defaultValues,
  onSubmit,
  error,
}) => {
  const form = useForm<TDomainExperienceFormData>({
    resolver: zodResolver(domainExperienceSchema),
    defaultValues,
    values: defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = form;

  const { data: domainsList, isLoading: isDomainsListLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.DOMAIN,
      },
    },
  });
  const { data: domainExperienceList, isLoading: isDomainExperienceListLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.DOMAIN_EXPERIENCE,
      },
    },
  });

  const onAdd: SubmitHandler<TDomainExperienceFormData> = async (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <FormProvider {...form}>
      <div className="flex flex-1 items-start gap-x-8">
        <FormField
          name="domain"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Domain</FormLabel>
              <FormControl>
                <ComboBox
                  className=""
                  options={getOptionsForSelectFromValuesMap(domainsList)
                    .filter((option) => !selectedDomains?.includes(option.value))
                    .filter((option, index, self) => index === self.findIndex((o) => o.value === option.value))}
                  selected={field.value ? { value: field.value, label: field.value } : null}
                  placeholder="Select Option"
                  onSelect={(value) => field.onChange(value)}
                  error={!!errors?.domain?.message || error}
                  isLoading={isDomainsListLoading}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="experience"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Years of experience</FormLabel>
              <FormControl>
                <Select
                  className=""
                  options={getOptionsForSelectFromValuesMap(domainExperienceList)}
                  selected={field.value}
                  placeholder="Select Option"
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.experience?.message || error}
                  isLoading={isDomainExperienceListLoading}
                  disableAlphabeticalSorting
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <Button
          onClick={handleSubmit(onAdd)}
          className="mt-8"
        >
          Add
        </Button>
      </div>
    </FormProvider>
  );
};
