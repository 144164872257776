import { useEffect } from 'react';
// import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Card } from '@/components/ui/card';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { MultiSelect } from '@/components/ui/multi-select';
// import Separator from '@/components/ui/separator';
// import Spinner from '@/components/ui/spinner';
// import { Switch } from '@/components/ui/switch';
import { toast } from '@/components/ui/toaster';

// import PermissionTooltip from '@/components/business/permisson-tooltip';
import FormFooter from '@/components/forms/form-footer';

import { useGetBusinessPartnersDetails, useGetCurrentBusiness, useUpdateBusinessPartner } from '@/hooks/business';

// import { useGetBusinessPartnerPermissions, useUpdateBusinessPartnerPermissions } from '@/hooks/permission';

// import { TBusinessPartnerPermission } from '@/services/permission';

// import {
//   businessPartnerPermissionSchema,
//   BusinessPartnerPermissionSchema,
// } from '@/validation-schemas/business/permissions';

export const Route = createFileRoute('/_authenticated/business/_dashboard/partners/$partnerId/permissions')({
  component: TeamPermissions,
});

function TeamPermissions() {
  return (
    <>
      {/* <Separator /> */}
      <TeamTypeSection />
      {/* <Separator /> */}
      {/* NOTE: We dont need permissions for partners right now */}
      {/* <PermissionSection /> */}
    </>
  );
}

// function PermissionSection() {
//   const { partnerId } = Route.useParams();
//   const { data: business } = useGetCurrentBusiness({});

//   const { data: permissions } = useGetBusinessPartnerPermissions({
//     params: { path: { businessId: String(business!.id!), partnerId } },
//     reactQueryOptions: {
//       enabled: Boolean(!!business && !!business?.id && !!partnerId),
//     },
//   });

//   const { mutateAsync: updateBusinessPartnerPermission, isPending } = useUpdateBusinessPartnerPermissions({
//     params: {
//       path: {
//         businessId: business?.id.toString() as string,
//         partnerId: partnerId,
//       },
//     },
//   });

//   const handlePermissionChange = (data: Partial<BusinessPartnerPermissionSchema>) => {
//     const updatedData = { ...permissions, ...data } as TBusinessPartnerPermission;
//     updateBusinessPartnerPermission({
//       params: {
//         path: {
//           businessId: business?.id.toString() as string,
//           partnerId,
//         },
//       },
//       body: {
//         manage_roles: updatedData.manage_roles,
//         manage_pipelines: updatedData.manage_pipelines,
//       },
//     });
//   };
//   return (
//     <div className="flex w-full space-x-8">
//       <div className="w-[420px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]">
//         <h3 className="flex items-center gap-2 text-xl font-semibold leading-[30px] text-primary-dark-100">
//           Permissions <PermissionTooltip /> {isPending && <Spinner size="sm" />}
//         </h3>
//         <p className="text-base font-medium leading-normal text-primary-dark-60">What is this user able to do?</p>
//       </div>
//       <div className="grow">
//         <Card className="p-8">
//           <PermissionsForm
//             permissions={permissions}
//             onPermissionChange={handlePermissionChange}
//           />
//         </Card>
//       </div>
//     </div>
//   );
// }
function TeamTypeSection() {
  return (
    <div className="flex w-full space-x-8">
      <div className="w-[420px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]">
        <h3 className="text-xl font-semibold leading-[30px] text-primary-dark-100">Role categories</h3>
        <p className="text-base font-medium leading-normal text-primary-dark-60">
          What categories does this partner look after?
        </p>
      </div>
      <div className="grow">
        <Card className="p-8">
          <TeamTypeForm />
        </Card>
      </div>
    </div>
  );
}

function TeamTypeForm() {
  const { data: business } = useGetCurrentBusiness({});
  const { partnerId } = Route.useParams();
  const { data: partnerProfile } = useGetBusinessPartnersDetails({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        partnerId: partnerId,
      },
    },
    reactQueryOptions: {
      enabled: !!partnerId && !!business,
    },
  });

  const categories = ['Product', 'Design', 'HR', 'Admin'];

  const form = useForm<{ categories: string[] }>({
    defaultValues: {
      categories: partnerProfile?.metadata?.categories?.split(';') || [],
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = form;

  const { mutateAsync: updateBusinessPartner, isPending } = useUpdateBusinessPartner({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        partnerId: partnerId,
      },
    },
    reactQueryOptions: {
      onSuccess: () => {
        toast.success('Role categories updated successfully');
      },
    },
  });

  // Use useEffect to reset the form when partnerProfile changes
  useEffect(() => {
    if (partnerProfile) {
      reset({
        categories: partnerProfile.metadata?.categories?.split(';') || [],
      });
    }
  }, [partnerProfile, reset]);

  const onSubmit: SubmitHandler<{ categories: string[] }> = (data) => {
    updateBusinessPartner({
      params: {
        path: {
          businessId: business?.id.toString() as string,
          partnerId: partnerId,
        },
      },
      body: {
        categories: data.categories.join(';'),
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Form>
        <FormField
          control={control}
          name="categories"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Role categories</FormLabel>
              <FormControl>
                <MultiSelect
                  options={categories.map((category) => ({
                    label: category,
                    value: category,
                  }))}
                  selected={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.categories?.message}
                  isLoading={false}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <FormFooter
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isPending}
      />
    </FormProvider>
  );
}

// interface IPermissionsFormProps {
//   permissions?: TBusinessPartnerPermission;
//   onPermissionChange?: (data: Partial<BusinessPartnerPermissionSchema>) => void;
// }

// function PermissionsForm({ permissions, onPermissionChange }: IPermissionsFormProps) {
//   const form = useForm<BusinessPartnerPermissionSchema>({
//     resolver: zodResolver(businessPartnerPermissionSchema),
//     defaultValues: permissions,
//     values: permissions,
//   });

//   const { control } = form;

//   return (
//     <div className="flex flex-col space-y-4">
//       <FormProvider {...form}>
//         <Form>
//           <FormField
//             control={control}
//             name="manage_roles"
//             render={({ field }) => (
//               <FormItem>
//                 <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
//                   <FormControl>
//                     <Switch
//                       checked={field?.value}
//                       onCheckedChange={(checked) => {
//                         field.onChange(checked);
//                         onPermissionChange?.({ manage_roles: checked });
//                       }}
//                     />
//                   </FormControl>
//                   <span>Manage roles</span>
//                 </div>
//                 <FormError />
//               </FormItem>
//             )}
//           />
//           <FormField
//             control={control}
//             name="manage_pipelines"
//             render={({ field }) => (
//               <FormItem>
//                 <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
//                   <FormControl>
//                     <Switch
//                       checked={field?.value}
//                       onCheckedChange={(checked) => {
//                         field.onChange(checked);
//                         onPermissionChange?.({ manage_pipelines: checked });
//                       }}
//                     />
//                   </FormControl>
//                   <span>Manage pipeline</span>
//                 </div>
//                 <FormError />
//               </FormItem>
//             )}
//           />
//         </Form>
//       </FormProvider>
//     </div>
//   );
// }
