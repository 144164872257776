import { memo, Suspense } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import FormLoader from '@/components/forms/form-loader';
import OnboardingHeader from '@/components/onboarding/header';

import { PORTAL_IDS } from '@/constants/portal-ids';

export const Route = createFileRoute('/_authenticated/business/onboarding/_steps')({
  component: memo(OnboardingStepsLayout),
});

function OnboardingStepsLayout() {
  return (
    <section className="relative">
      <div className="h-[calc(100vh-120px)] overflow-y-auto scrollbar">
        <div className="mx-auto flex w-full max-w-[880px] flex-col">
          <OnboardingHeader />
          <main className="h-full w-full flex-grow p-8">
            <Suspense fallback={<FormLoader />}>
              <Outlet />
            </Suspense>
          </main>
        </div>
      </div>
      <div
        className="h-[120px]"
        id={PORTAL_IDS.onBoardingFooter}
      ></div>
    </section>
  );
}
