import { UilSuitcase } from '@iconscout/react-unicons';
import { useQueryClient } from '@tanstack/react-query';

import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/toaster';

import { usePipelineActions } from '@/hooks/pipeline/pipeline.async';

import { PIPELINE_ENDPOINTS, TCandidateForPipelineStatus } from '@/services/pipeline';

interface ShortlistButtonProps {
  candidateId: string;
  postId: string;
  businessId: string;
  fromStatus: TCandidateForPipelineStatus;
}

const ShortlistButton = ({ candidateId, postId, businessId, fromStatus }: ShortlistButtonProps) => {
  const queryClient = useQueryClient();
  const { shortlist } = usePipelineActions({});

  const { mutateAsync: shortlistCandidate, isPending } = shortlist;

  const handleShortlist = () => {
    if (!fromStatus) return;

    shortlistCandidate(
      {
        params: {
          path: {
            userId: candidateId,
            postId,
            businessId,
          },
          query: {
            from_status: fromStatus,
          },
        },
      },
      {
        onSuccess() {
          toast.success('Candidate shortlisted');
          queryClient.invalidateQueries({
            queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATE_DETAILS],
            refetchType: 'all',
          });
        },
        onError() {
          toast.error('Failed to shortlist candidate');
        },
      }
    );
  };
  return (
    <Button
      onClick={handleShortlist}
      disabled={isPending}
      loading={isPending}
      loadingText="Shortlisting..."
    >
      <UilSuitcase className="size-[1.25rem]" />
      Shortlist
    </Button>
  );
};

export default ShortlistButton;
