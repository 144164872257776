import client, { TRequestOptions } from '@/lib/api';

import { TBusiness } from '../business';
import { PARTNER_ENDPOINTS } from './partner.endpoints';
import { TCheckDuplicateCandidateSubmissionRequest, TSubmitCandidateToRoleRequest } from './partner.types';

const isAllBusinessPartnerFieldsEmpty = (partner: TBusiness) => {
  // Check if all business fields are empty except for the name
  // The name is never empty, so we don't include it in our checks
  return !partner?.business_abn && !partner?.logo_url && !partner?.country && !partner?.city && !partner?.bio;
};

export const getBusinessPartnerOnbaordingStatus = (partner: TBusiness) => {
  if (isAllBusinessPartnerFieldsEmpty(partner)) {
    return 0;
  }
  if (!partner?.logo_url || !partner?.name || !partner?.business_abn || !partner?.country || !partner?.city) {
    return 1;
  }
  if (!partner?.bio) {
    return 2;
  }
  return 3;
};

export const submitCandidateToRole = async ({
  signal,
  params,
  body,
}: TRequestOptions<TSubmitCandidateToRoleRequest>) => {
  const { data } = await client.POST(PARTNER_ENDPOINTS.SUBMIT_CANDIDATE_TO_ROLE, { signal, params, body });

  return data?.data;
};

export const checkDuplicateCandidateSubmission = async ({
  signal,
  params,
  body,
}: TRequestOptions<TCheckDuplicateCandidateSubmissionRequest>) => {
  const { data } = await client.GET(PARTNER_ENDPOINTS.CHECK_DUPLICATE_CANDIDATE_SUBMISSION, { signal, params, body });

  return data?.data;
};
