import { paths } from '@/lib/api/v1';

export const CANDIDATE_ENDPOINTS = {
  GET_CURRENT_CANDIDATE: '/v1/candidates/me',
  UPDATE_CURRENT_CANDIDATE: '/v1/candidates/me',
  GET_CURRENT_CANDIDATE_MATCHES: '/v2/candidates/me/job-posts/matches',
  GET_CURRENT_CANDIDATE_APPLIED_MATCHES: '/v2/candidates/me/job-posts/applied',
  GET_CURRENT_CANDIDATE_HIRED_MATCHES: '/v2/candidates/me/job-posts/hired',
  GET_CURRENT_CANDIDATE_SINGLE_JOB_POST: '/v2/candidates/me/job-posts/{postId}',
  GET_CURRENT_CANDIDATE_ARCHIVED_JOB_POST: '/v2/candidates/me/job-posts/archived',
  GET_CURRENT_CANDIDATE_OFFERS: '/v2/candidates/me/job-posts/offers',
  SAVE_POST: '/v2/candidates/me/job-posts/{postId}/save',
  UNSAVE_POST: '/v2/candidates/me/job-posts/{postId}/unsave',
  GET_SAVED_POST: '/v2/candidates/me/job-posts/saved',
  GET_CANDIDATE_BY_ID: '/v1/candidates/{id}',
  CREATE_PASSWORD: '/v1/candidates/invite/setup',
} satisfies Record<string, keyof paths>;
