import { useCreateRoleStore } from '@/store/create-role-store';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';

import { FormProvider } from '@/components/ui/form';

import CreateRoleFooter from '@/components/roles/business/create-role/create-role-footer';
import CreateRoleNavigation from '@/components/roles/business/create-role/create-role-navigation';
import Step7Fields from '@/components/roles/business/create-role/form-fields/step7-fields';
import SaveAsDraftButton from '@/components/roles/business/create-role/save-as-draft-button';
import SaveChangesButton from '@/components/roles/business/create-role/save-changes-button';

import { step7Schema, TStep7FormData } from '@/validation-schemas/business/create-role-schema';

export const Route = createFileRoute('/_authenticated/business/create-role/step7')({
  component: Step7,
});

function Step7() {
  const { data, setStepData } = useCreateRoleStore();
  const form = useForm<TStep7FormData>({
    resolver: zodResolver(step7Schema),
    values: {
      partners: data.partners,
      enableQuickApply: data.enableQuickApply,
    },
    mode: 'onChange',
  });

  const onSubmit = (data: TStep7FormData) => {
    setStepData(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Step7Fields />
        <CreateRoleFooter onSubmit={form.handleSubmit(onSubmit)} />
        <SaveChangesButton />
        <SaveAsDraftButton />
      </form>
      <CreateRoleNavigation saveFormData={form.handleSubmit(onSubmit)} />
    </FormProvider>
  );
}

export default Step7;
