import { UilArrowGrowth } from '@iconscout/react-unicons';

import { Tag } from '@/components/ui/tag';

import { cn } from '@/lib/utils';

const UpplftAutoMatchedTag = ({ status, className }: { status?: string; className?: string }) => {
  return (
    <>
      {status && status === 'MATCHING' && (
        <Tag
          hideDot
          variant={'success'}
          className={cn('pl-1.5 pr-2.5', className)}
        >
          <UilArrowGrowth className="size-4" /> {'Upplft'}
        </Tag>
      )}
    </>
  );
};

export default UpplftAutoMatchedTag;
