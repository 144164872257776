import { memo } from 'react';
import { createFileRoute, Outlet, ToOptions } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { Card } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';

import BackButton from '@/components/back-button';
import FullPageLoader from '@/components/full-page-loader';
import TabNav, { ITabNavItem } from '@/components/navigation/tab-nav';
import { CandidateSidePanel } from '@/components/pipeline/business/candidate-side-panel';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetCandidateDetailsForAPost } from '@/hooks/pipeline';

import { hasCandidateCompletedOnboarding } from '@/services/candidate/candidate.services';

const searchParams = z.object({
  backRoute: z.string().optional(),
});

export const Route = createFileRoute(
  '/_authenticated/business/_dashboard/pipeline-candidate-profile/$postId/$candidateId'
)({
  component: memo(CandidateIdLayout),
  validateSearch: zodSearchValidator(searchParams),
});

function CandidateIdLayout() {
  const { candidateId, postId } = Route.useParams();
  const { data: business } = useGetCurrentBusiness({});
  const { backRoute } = Route.useSearch();

  const { data: candidateDetails, isLoading } = useGetCandidateDetailsForAPost({
    params: {
      path: { businessId: business!.id.toString(), postId: postId, userId: candidateId },
    },
    reactQueryOptions: {
      enabled: Boolean(postId && candidateId && business?.id),
    },
  });

  const isCandidateOnboardingCompleted =
    candidateDetails?.candidate_profile && hasCandidateCompletedOnboarding(candidateDetails?.candidate_profile);

  const profileTab: ITabNavItem = {
    label: 'Profile',
    href: '/business/pipeline-candidate-profile/$postId/$candidateId/profile',
    search: {
      backRoute,
    },
  };

  const resumeTab: ITabNavItem = {
    label: 'Resume',
    href: '/business/pipeline-candidate-profile/$postId/$candidateId/resume',
    search: {
      backRoute,
    },
  };

  const navItems: ITabNavItem[] = isCandidateOnboardingCompleted ? [profileTab, resumeTab] : [resumeTab, profileTab];

  if (isLoading || !candidateDetails) return <FullPageLoader className="w-full" />;

  return (
    <main className="space-y-6">
      <BackButton
        backRoute={(backRoute as ToOptions['to']) || '/business/pipeline'}
        onlyUseBackRoute
      />
      <div className="flex gap-8">
        <section className="sticky top-8 h-[calc(100vh-48px)] w-full max-w-[360px] flex-shrink-0 2xl:max-w-[380px] 3xl:max-w-[460px]">
          <ScrollArea className="h-full">
            <CandidateSidePanel data={candidateDetails} />
          </ScrollArea>
        </section>
        <Card className="min-h-min flex-grow space-y-8 shadow-md">
          <TabNav navItems={navItems} />
          <Outlet />
        </Card>
      </div>
    </main>
  );
}
