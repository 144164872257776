import { AnalyticsCardSkeleton } from './analytics-card-skeleton';

export function PartnerStatsSkeleton() {
  return (
    <div className="grid grid-cols-2 items-center justify-between gap-6 pb-8 3xl:grid-cols-4">
      <AnalyticsCardSkeleton />
      <AnalyticsCardSkeleton />
      <AnalyticsCardSkeleton />
      <AnalyticsCardSkeleton />
    </div>
  );
}

export function PartnersStatsSkeleton() {
  return (
    <div className="flex w-full flex-wrap items-center justify-between gap-6">
      <AnalyticsCardSkeleton />
      <AnalyticsCardSkeleton />
      <AnalyticsCardSkeleton />
    </div>
  );
}
