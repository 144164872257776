import { paths } from '@/lib/api/v1';

export const BUSINESS_ROLES_ENDPOINTS = {
  GET_ALL_ROLES_BY_BUSINESS_ID_V2: '/v2/business/{businessId}/job-posts',
  GET_ROLES_STATS: '/v2/business/{businessId}/job-posts/stats',
  GET_ROLE_DETAIL_BY_BUSINESS_ID_POST_ID_V2: '/v2/business/{businessId}/job-posts/{postId}',
  CREATE_JOB_POST: '/v2/business/{businessId}/job-posts',
  GET_JOB_POST_BY_ID: '/v2/business/{businessId}/job-posts/{postId}',
  UPDATE_JOB_POST: '/v2/business/{businessId}/job-posts/{postId}',
  FAVOURITE_CANDIDATE: '/v2/business/{businessId}/pipeline/job-posts/{postId}/candidates/{userId}/favorite',
  UNFAVOURITE_CANDIDATE: '/v2/business/{businessId}/pipeline/job-posts/{postId}/candidates/{userId}/unfavorite',
  GENERATE_ROLE_REFERRER_TOKEN: '/v2/business/{businessId}/job-posts/{postId}/referrer-token',
  DELETE_A_POST: '/v2/business/{businessId}/job-posts/{postId}',
  CLOSE_JOB_POST: '/v2/business/{businessId}/job-posts/{postId}/close',
  PAUSE_JOB_POST: '/v2/business/{businessId}/job-posts/{postId}/pause',
  REOPEN_JOB_POST: '/v2/business/{businessId}/job-posts/{postId}/open',
} satisfies Record<string, keyof paths>;
