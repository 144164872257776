import React from 'react';

import { Card, CardContent, CardHeader } from '@/components/ui/card';
import Spinner from '@/components/ui/spinner';

import { PersonalityChart } from '@/components/quiz/charts/personality-chart';

import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { parseJobPostPersonalityProfile } from '@/services/business-roles';
import { PersonalityArchetypeCode, PersonalityFactor } from '@/services/candidate';

import { getPersonalityChartData } from '@/utils/role';

import { PERSONALITY_CODE_TO_EMOJI_MAP } from '@/constants/candidate';
import { VALUES_GROUP } from '@/constants/values-map';

import { ChartLabels } from './labels';

interface IProps {
  code: PersonalityArchetypeCode;
  archetype: string;
  facets: string[];
  adjectives: string;
  primaryDataLabel?: string;
  showSecondLabel?: boolean;
}

export const PersonalityBreakdownCard: React.FC<IProps> = ({
  code,
  archetype,
  facets,
  adjectives,
  primaryDataLabel,
  showSecondLabel,
}) => {
  return (
    <Card className="mb-8 flex w-full space-x-8 border-none p-0">
      <CardHeader className="flex flex-row items-center">
        <span className="text-7xl 3xl:text-8xl">{PERSONALITY_CODE_TO_EMOJI_MAP[code]}</span>
      </CardHeader>
      <CardContent className="flex space-x-8">
        <div className="grid max-w-[37rem] grid-cols-1 space-y-2">
          <div className="grid grid-cols-[7rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Archetype:</h3>
            <span>{archetype}</span>
          </div>
          <div className="grid grid-cols-[7rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Facets:</h3>
            <span>{facets.join(', ')}</span>
          </div>
          <div className="grid grid-cols-[7rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Adjectives:</h3>
            <span>{adjectives}</span>
          </div>
        </div>

        <ChartLabels
          showSecondLabel={showSecondLabel}
          primaryLabel={primaryDataLabel}
        />
      </CardContent>
    </Card>
  );
};

interface IPersonalityMatchProps {
  rolePersonalityProfile?: string | null;
  candidatePersonalityProfile?: string;
  isLoading?: boolean;
  primaryDataLabel?: string;
  candidatePersonalityCode?: PersonalityArchetypeCode;
  showCandidateBreakDown?: boolean;
}

export const PersonalityMatch: React.FC<IPersonalityMatchProps> = ({
  rolePersonalityProfile,
  candidatePersonalityProfile,
  isLoading,
  primaryDataLabel,
  candidatePersonalityCode,
  showCandidateBreakDown,
}) => {
  const { data: personalityValuesMap, isLoading: isPersonalityFactorLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.PERSONALITY_FACTORS,
      },
    },
  });

  let breakdownFactor: PersonalityFactor | undefined;

  if (showCandidateBreakDown && !candidatePersonalityCode) throw new Error('candidatePersonalityCode is required');

  const parsedRolePersonalityProfile = rolePersonalityProfile && parseJobPostPersonalityProfile(rolePersonalityProfile);

  const isValidRolePersonalityProfile =
    Array.isArray(parsedRolePersonalityProfile) && parsedRolePersonalityProfile.length > 0;

  const rolePersonalityCode = isValidRolePersonalityProfile ? parsedRolePersonalityProfile[0].code : null;

  if (isLoading || isPersonalityFactorLoading)
    return (
      <Card className="flex min-h-[30rem] items-center justify-center">
        <Spinner />
      </Card>
    );

  const personalityFactors = personalityValuesMap?.items.map((item) => {
    if (!item.description || !item.code)
      return {
        code: '',
        Name: '',
        Adjectives: '',
        Facets: '',
      };
    const desc = JSON.parse(item.description?.replace(' "Adjectives"', ',  "Adjectives"')) as {
      Name: string;
      Adjectives: string;
      Facets: string;
    };
    return {
      code: item.code,
      ...desc,
    };
  });

  const candidatePersonalityData = candidatePersonalityProfile ? JSON.parse(candidatePersonalityProfile || '{}') : {};
  const rolePersonalityData = isValidRolePersonalityProfile && getPersonalityChartData(parsedRolePersonalityProfile);

  if (showCandidateBreakDown && candidatePersonalityCode) {
    breakdownFactor = personalityFactors?.find((item) => item.code === candidatePersonalityCode);
  } else {
    breakdownFactor = personalityFactors?.find((item) => item.code === rolePersonalityCode);
  }

  if (!breakdownFactor || !personalityFactors) return <span>Cannot render graph. Personality factors not found</span>;

  return (
    <Card className="flex flex-col gap-6">
      <PersonalityBreakdownCard
        code={breakdownFactor?.code as PersonalityArchetypeCode}
        archetype={breakdownFactor.Name}
        facets={breakdownFactor.Facets.split(',')}
        adjectives={breakdownFactor.Adjectives}
        primaryDataLabel={primaryDataLabel}
        showSecondLabel={!!candidatePersonalityProfile && !!rolePersonalityData}
      />
      <PersonalityChart
        data={candidatePersonalityProfile ? candidatePersonalityData : rolePersonalityData}
        data2={candidatePersonalityProfile && rolePersonalityData ? rolePersonalityData : undefined}
        factors={personalityFactors!}
      />
    </Card>
  );
};
