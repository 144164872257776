import { useEffect } from 'react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';

import FileUploaderWithDragAndDrop from '@/components/ui/file-uploader-drag-and-drop';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MultiSelect } from '@/components/ui/multi-select';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/toaster';

import { useGetCurrentCandidateSuspense, useUpdateCurrentCandidate } from '@/hooks/candidate';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { getArrayFromString, getFormattedDataForAPI } from '@/utils/format';
import { getOptionsForSelectFromValuesMap } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

import FormFooter from '../form-footer';
import FormNavigationBlocker from '../form-navigation-blocker';
import { IFormProps } from '../types';

type FormValues = {
  bio: string;
  skills: string[];
  candidate_values: string[];
  cv_url: string;
  web_url: string;
  linkedin_url: string;
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: Record<keyof FormValues, { type: string; message: string }> = {} as Record<
    keyof FormValues,
    { type: string; message: string }
  >;

  if (!values.skills || values.skills.length === 0) {
    errors.skills = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.bio || values.bio.length === 0) {
    errors.bio = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (values.bio.length > 1000) {
    errors.bio = {
      type: 'length',
      message: 'Your bio can not be longer than 1000 characters.',
    };
  }
  if (!values.candidate_values || values.candidate_values.length === 0) {
    errors.candidate_values = {
      type: 'required',
      message: 'This is required.',
    };
  }

  if (!values.linkedin_url || values.linkedin_url === '') {
    errors.linkedin_url = {
      type: 'required',
      message: 'This is required.',
    };
  }

  return {
    values: Object.keys(errors).length === 0 ? values : {},
    errors,
  };
};

const BiosExtrasForm: React.FC<IFormProps> = ({ onSuccess, isOnboarding }) => {
  const { data } = useGetCurrentCandidateSuspense({});
  const { data: softSkillsList, isLoading: isSoftSkillLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.SOFT_SKILLS,
      },
    },
  });

  const { data: candidateValuesList, isLoading: isCandidateValueLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.CANDIDATE_VALUES,
      },
    },
  });
  const form = useForm<FormValues>({
    defaultValues: {
      bio: '',
      skills: [],
      candidate_values: [],
      web_url: '',
      linkedin_url: '',
      cv_url: '',
    },
    resolver,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = form;

  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useUpdateCurrentCandidate({
    reactQueryOptions: {
      onSuccess: () => {
        if (!isOnboarding) {
          toast.success('Account updated successfully!');
        }
        onSuccess?.();
      },
    },
  });

  useEffect(() => {
    if (data) {
      const initialValues: FormValues = {
        web_url: data.web_url || '',
        linkedin_url: data.linkedin_url || '',
        cv_url: data.cv_url || '',
        bio: data.bio || '',
        skills: getArrayFromString(data.skills) || [],
        candidate_values: getArrayFromString(data.candidate_values) || [],
      };
      reset(initialValues);
    }
  }, [data, reset]);

  const handleFileUpload = (url: string, name: string) => {
    const cv_url = JSON.stringify({ url, name });
    setValue('cv_url', cv_url, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  };

  const handleClearUpload = () => {
    setValue('cv_url', '', { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  };

  const getCurrentFile = () => {
    if (!data?.cv_url) return null;
    if (data.cv_url == '') return null;
    return JSON.parse(data?.cv_url);
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    updateCandidate({ body: { ...getFormattedDataForAPI(data) } });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name="bio"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Bio (Max 1000 characters)</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter description here"
                  {...field}
                  error={!!errors?.bio?.message}
                  rows={4}
                  maxLength={1000}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="skills"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Soft skills (Max 10)</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value}
                  placeholder="Start typing or select"
                  options={getOptionsForSelectFromValuesMap(softSkillsList)}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.skills?.message}
                  isLoading={isSoftSkillLoading}
                  maxSelections={10}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="candidate_values"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Values (Max 10)</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value}
                  placeholder="Start typing or select"
                  options={getOptionsForSelectFromValuesMap(candidateValuesList)}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.candidate_values?.message}
                  isLoading={isCandidateValueLoading}
                  maxSelections={10}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FileUploaderWithDragAndDrop
          label="CV"
          acceptedFileTypes={[
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          ]}
          keyPrefix={`candidate/${data?.id}`}
          onFileUpload={handleFileUpload}
          currentFile={getCurrentFile()}
          onClearUpload={handleClearUpload}
        />
        <FormField
          control={control}
          name="linkedin_url"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Linkedin</FormLabel>
              <FormControl>
                <Input
                  id="linkedin"
                  prefix="https://"
                  className="w-full"
                  placeholder="www.linkedin.com/in/profilename"
                  {...field}
                  error={!!errors.linkedin_url?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="web_url"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Website / Portfolio (Optional)</FormLabel>
              <FormControl>
                <Input
                  className="w-full"
                  placeholder="www.myportfolio.com"
                  {...field}
                  error={!!errors.web_url?.message}
                  id="portfolio"
                  prefix="https://"
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <FormNavigationBlocker />
      <FormFooter
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isUpdatingCandidate}
      />
    </FormProvider>
  );
};

export default BiosExtrasForm;
