import client, { publicClient, TRequestOptions } from '@/lib/api';

import { expectationsFormSchema } from '@/validation-schemas/expectation-schema';

import { TCandidateForPipelineBoard } from '../pipeline';
import { CANDIDATE_ENDPOINTS } from './candidate.endpoints';
import {
  TCandidate,
  TCreatePasswordRequest,
  TGetCandidateByIdRequest,
  TGetCurrentCandidateAppliedMatchesRequest,
  TGetCurrentCandidateArchivedJobPosts,
  TGetCurrentCandidateHiredMatchesRequest,
  TGetCurrentCandidateMatchesRequest,
  TGetCurrentCandidateOffersRequest,
  TGetCurrentCandidateRequest,
  TGetCurrentCandidateSingleJobDetailsRequest,
  TGetSavedPostRequest,
  TSavePostRequest,
  TUnsavePostRequest,
  TUpdateCurrentCandidateRequest,
} from './candidate.types';

type TCandidateForOnboarding = TCandidate | TCandidateForPipelineBoard['candidate_profile'];

const { GET_CURRENT_CANDIDATE, UPDATE_CURRENT_CANDIDATE } = CANDIDATE_ENDPOINTS;

export const getCurrentCandidate = async ({ signal }: TRequestOptions<TGetCurrentCandidateRequest>) => {
  const { data } = await client.GET(GET_CURRENT_CANDIDATE, {
    signal,
  });

  return data?.data;
};

export const updateCurrentCandidate = async ({ signal, body }: TRequestOptions<TUpdateCurrentCandidateRequest>) => {
  await client.PUT(UPDATE_CURRENT_CANDIDATE, { signal, body });
};

const checkIsExpectationFieldsValid = (candidate: TCandidateForOnboarding) => {
  const expectationData = {
    expectation_currency: candidate?.expectation_currency || '',
    expectation_annual_salary_from: candidate?.expectation_annual_salary_from,
    expectation_annual_salary_to: candidate?.expectation_annual_salary_to,
    expectation_day_rate_from: candidate?.expectation_day_rate_from || 0,
    expectation_day_rate_to: candidate?.expectation_day_rate_to || 0,
    expectation_hourly_rate_from: candidate?.expectation_hourly_rate_from || 0,
    expectation_hourly_rate_to: candidate?.expectation_hourly_rate_to || 0,
    other_expectations: candidate?.other_expectations ? candidate?.other_expectations.split(',') : [],
  };

  const result = expectationsFormSchema.safeParse(expectationData);
  return result.success;
};

const isAllCandidateFieldsEmpty = (candidate: TCandidateForOnboarding) => {
  return (
    !candidate?.looking_for &&
    !candidate?.business_size &&
    !candidate?.roles_interested &&
    !candidate?.experience_1_start_date &&
    !candidate?.experience_1_role &&
    !candidate?.experience_1_end_date &&
    !candidate?.technical_skills &&
    !candidate?.industry_experience &&
    !candidate?.business_experience &&
    !candidate?.domain_exp &&
    !candidate?.education &&
    !candidate?.english_proficiency &&
    !candidate?.country &&
    !candidate?.city &&
    !candidate?.residency_status &&
    !candidate?.bio &&
    !candidate?.skills &&
    !candidate?.candidate_values &&
    !candidate?.cv_url &&
    !candidate?.web_url &&
    !candidate?.linkedin_url &&
    !checkIsExpectationFieldsValid(candidate)
  );
};

export const getCandidateOnbaordingStatus = (candidate: TCandidateForOnboarding) => {
  if (isAllCandidateFieldsEmpty(candidate)) {
    return 0;
  }

  if (!candidate?.looking_for || !candidate.business_size || !candidate.roles_interested) return 1;

  const isExpectationValid = checkIsExpectationFieldsValid(candidate);
  if (!isExpectationValid) return 2;

  if (!candidate?.experience_1_compname || !candidate.experience_1_role || !candidate.experience_1_start_date) return 3;
  if (
    !candidate?.technical_skills ||
    !candidate.industry_experience ||
    !candidate.business_experience ||
    !candidate.domain_exp
  )
    return 4;
  if (!candidate?.education || !candidate.english_proficiency) return 5;
  if (!candidate.country || !candidate.city || !candidate.residency_status) return 6;
  if (!candidate.bio || !candidate.skills || !candidate.candidate_values) return 7;
  if (!candidate.linkedin_url) return 8;

  return 9;
};

export const hasCandidateCompletedCultureQuiz = (candidate: TCandidate) => {
  return !!candidate?.culture;
};

export const hasCandidateCompletedPersonalityQuiz = (candidate: TCandidate) => {
  if (!candidate?.personality_archetype || candidate.personality_archetype === '') {
    return false;
  }
  if (!candidate?.personality_test_info || candidate.personality_test_info === '') {
    return false;
  }
  return true;
};

export const hasCandidateCompletedOnboarding = (candidate: TCandidateForOnboarding) => {
  return getCandidateOnbaordingStatus(candidate) === 9;
};

export const getCurrentCandidateMatches = async ({
  signal,
  params,
}: TRequestOptions<TGetCurrentCandidateMatchesRequest>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_MATCHES, {
    signal,
    params,
  });

  return data?.data;
};

export const getCurrentCandidateSingleJobDetails = async ({
  signal,
  params,
}: TRequestOptions<TGetCurrentCandidateSingleJobDetailsRequest>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_SINGLE_JOB_POST, {
    signal,
    params,
  });

  return data?.data;
};

export const getCurrentCandidateAppliedMatches = async ({
  signal,
  params,
}: TRequestOptions<TGetCurrentCandidateAppliedMatchesRequest>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_APPLIED_MATCHES, {
    signal,
    params,
  });

  return data?.data;
};

export const getCurrentCandidateHiredMatches = async ({
  signal,
  params,
}: TRequestOptions<TGetCurrentCandidateHiredMatchesRequest>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_HIRED_MATCHES, {
    signal,
    params,
  });

  return data?.data;
};

export const savePost = async ({ signal, params }: TRequestOptions<TSavePostRequest>) => {
  await client.POST(CANDIDATE_ENDPOINTS.SAVE_POST, {
    signal,
    params,
  });
};

export const unsavePost = async ({ signal, params }: TRequestOptions<TUnsavePostRequest>) => {
  await client.DELETE(CANDIDATE_ENDPOINTS.UNSAVE_POST, {
    signal,
    params,
  });
};

export const getCurrentCandidateArchivedJobPosts = async ({
  signal,
  params,
}: TRequestOptions<TGetCurrentCandidateArchivedJobPosts>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_ARCHIVED_JOB_POST, {
    signal,
    params,
  });

  return data?.data;
};

export const getSavedPost = async ({ signal, params }: TRequestOptions<TGetSavedPostRequest>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_SAVED_POST, {
    signal,
    params,
  });

  return data?.data;
};

export const getCandidateById = async ({ signal, params }: TRequestOptions<TGetCandidateByIdRequest>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_CANDIDATE_BY_ID, {
    signal,
    params,
  });

  return data?.data;
};

export const getCurrentCandidateOffers = async ({
  signal,
  params,
}: TRequestOptions<TGetCurrentCandidateOffersRequest>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_OFFERS, {
    signal,
    params,
  });

  return data?.data;
};

export const createCandidatePassword = async ({ signal, body }: TRequestOptions<TCreatePasswordRequest>) => {
  await publicClient.POST(CANDIDATE_ENDPOINTS.CREATE_PASSWORD, {
    signal,
    body,
  });
};
