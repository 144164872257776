import { useState } from 'react';
import { UilExclamationCircle } from '@iconscout/react-unicons';
import { QueryKey, useQueryClient } from '@tanstack/react-query';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import TeamMemberSelect, { TTeamMember } from '@/components/ui/team-member-select';
import { toast } from '@/components/ui/toaster';

import { useGetCurrentBusiness } from '@/hooks/business/business.queries';
import { useSelectPartnerForCandidate } from '@/hooks/pipeline/pipeline.mutations';

import { PIPELINE_ENDPOINTS, TCandidateForPipeline, TGetAllCandidatesForPipelineResponse } from '@/services/pipeline';

interface SelectPartnerModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess: () => void;
  onCancel?: () => void;
  candidate: TCandidateForPipeline;
  queryKey?: QueryKey;
}

const SelectPartnerModal = ({
  open,
  onOpenChange,
  onSuccess,
  onCancel,
  candidate,
  queryKey,
}: SelectPartnerModalProps) => {
  const { data: business } = useGetCurrentBusiness({});
  const queryClient = useQueryClient();

  const formattedPartners: TTeamMember[] =
    candidate?.recruiters
      ?.filter((partner) => partner.id)
      .map((partner) => ({
        id: partner.id.toString(),
        fullName: partner.name,
        imageUrl: partner.logo_url,
      })) || [];

  const selectedPartnerId = candidate?.recruiters
    ?.find((partner) => partner.recruiting_candidate?.is_selected)
    ?.id?.toString();

  const [selectedPartner, setSelectedPartner] = useState<TTeamMember | undefined>(
    formattedPartners.find((partner) => partner.id === selectedPartnerId)
  );

  const { mutateAsync: selectPartnerForCandidate, isPending } = useSelectPartnerForCandidate();

  const handleSubmit = async () => {
    await selectPartnerForCandidate(
      {
        params: {
          path: {
            businessId: business?.id.toString() || '',
            postId: candidate.job_post.id.toString(),
            userId: candidate.id.toString(),
            partnerId: selectedPartner?.id?.toString() || '',
          },
        },
      },
      {
        onSuccess: () => {
          onSuccess();
          // Update the cache to reflect the selected partner
          if (queryKey) {
            const isQueryKeyFromPipeline = queryKey.includes(PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES);
            console.log('isQueryKeyFromPipeline', isQueryKeyFromPipeline);
            console.log('queryKey', queryKey);
            if (isQueryKeyFromPipeline) {
              queryClient.setQueryData(queryKey, (oldData: TGetAllCandidatesForPipelineResponse) => {
                if (!oldData) return oldData;

                return {
                  ...oldData,
                  items: oldData.items?.map((item: TCandidateForPipeline) => {
                    if (item.id === candidate.id) {
                      return {
                        ...item,
                        recruiters: item.recruiters?.map((recruiter) => ({
                          ...recruiter,
                          recruiting_candidate: {
                            ...recruiter.recruiting_candidate,
                            is_selected: recruiter.id?.toString() === selectedPartner?.id,
                          },
                        })),
                      };
                    }
                    return item;
                  }),
                };
              });
            } else {
              // FIXME: use complete query key and update cache
              queryClient.invalidateQueries({ queryKey, refetchType: 'all', exact: false });
            }
          }
          toast.success('Partner selected successfully');
        },
        onError: (error) => {
          toast.error(error.message);
        },
      }
    );
  };

  return (
    <>
      <AlertDialog
        open={open}
        onOpenChange={(open) => {
          if (!open) onCancel?.();
          onOpenChange(open);
        }}
      >
        <AlertDialogContent className="max-w-[32.75rem]">
          <AlertDialogHeader>
            <AlertDialogTitle>
              <div className="flex items-center gap-2">
                <span className="flex size-8 items-center justify-center rounded-lg border border-primary-dark-10 shadow-xs">
                  <UilExclamationCircle className="size-6" />
                </span>
                Select Partner
              </div>
            </AlertDialogTitle>
            <AlertDialogDescription className="space-y-4">
              <p>Please select the partner who is authorised to represent this candidate.</p>
              <TeamMemberSelect
                options={formattedPartners}
                selected={selectedPartner}
                onChange={(value) => {
                  if (value && !Array.isArray(value)) {
                    setSelectedPartner(value);
                  }
                }}
                isLoading={false}
                error={false}
              />
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button
              onClick={handleSubmit}
              disabled={isPending}
              loading={isPending}
              loadingText="Selecting ..."
            >
              Select
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SelectPartnerModal;
