import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';

export function AnalyticsCardSkeleton() {
  return (
    <Card className="flex-1 space-y-8 p-6 shadow-md">
      <CardTitle className="flex items-center gap-2 text-base">
        <Skeleton className="h-5 w-28" />
        <Skeleton className="h-5 w-5 rounded-full" />
      </CardTitle>
      <CardContent className="flex items-center justify-between">
        <div className="flex flex-col justify-between space-y-2">
          <Skeleton className="h-12 w-24" />
          <Skeleton className="h-4 w-32" />
        </div>
        <div className="w-[35%] min-w-[92px] max-w-[150px]">
          <Skeleton className="h-20 w-full" />
        </div>
      </CardContent>
    </Card>
  );
}
