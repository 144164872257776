import { useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import FileUploaderWithDragAndDrop, { FileUploaderRef } from '@/components/ui/file-uploader-drag-and-drop';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { PhoneInput } from '@/components/ui/phone-input';
import { Select } from '@/components/ui/select';
import { toast } from '@/components/ui/toaster';

import { useCheckDuplicateCandidateSubmission, useSubmitCandidate } from '@/hooks/partner/partner.mutations';

import { mapSubmitCandidateFormDataToAPI } from '@/services/partner';

import { currencyList, formatCurrency, parseCurrency } from '@/utils/currency';

import {
  BACKGROUND_CHECK_OPTIONS,
  NOTICE_PERIOD_OPTIONS,
  WORKING_RIGHTS,
  WORKING_RIGHTS_OPTIONS,
} from '@/constants/submit-candidate';

import { submitCandidateSchema, TSubmitCandidateSchema } from '@/validation-schemas/partner/submit-candidate-schema';

import DuplicateCandidateModal from './duplicate-candidate';
import PrePlannedLeaveForms from './preplanned-leave-form';
import SubmitSuccessModal from './submit-success-modal';

const defaultValues: Partial<TSubmitCandidateSchema> = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  currency: 'AUD',
  payRate: 0,
  candidateCV: '',
  prePlannedLeaves: [
    {
      dateRange: {
        startDate: undefined,
        endDate: undefined,
      },
    },
  ],
};

const SubmitCandidateForm = ({ roleId, partnerId }: { roleId: string; partnerId: string }) => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [duplicateCandidateModalOpen, setDuplicateCandidateModalOpen] = useState(false);
  const [isDuplicateSubmitBySamePartner, setIsDuplicateSubmitBySamePartner] = useState(false);
  const [isDuplicateSubmitByDifferentPartner, setIsDuplicateSubmitByDifferentPartner] = useState(false);

  const fileUploaderRef = useRef<FileUploaderRef>(null);

  const form = useForm<TSubmitCandidateSchema>({
    resolver: zodResolver(submitCandidateSchema),
    defaultValues,
  });

  const {
    formState: { errors },
    control,
    watch,
    setValue,
    trigger,
    getValues,
  } = form;

  const { mutateAsync: submitCandidateMutation, isPending } = useSubmitCandidate({
    params: {
      path: {
        partnerId,
      },
    },
  });

  const { mutateAsync: checkDuplicateCandidateSubmission, isPending: isCheckingDuplicateCandidateSubmission } =
    useCheckDuplicateCandidateSubmission({
      params: {
        path: { partnerId, businessPostId: roleId },
        query: { candidateEmail: getValues('email') },
      },
    });

  if (!partnerId || !roleId) return null;

  const selectedCurrency = watch('currency');
  const workingRights = watch('workingRights');
  const isCustomWorkingRights = workingRights === WORKING_RIGHTS.OTHER;

  const handleFileUpload = async (url: string, name: string) => {
    const cv_url = JSON.stringify({ url, name });
    setValue('candidateCV', cv_url, { shouldValidate: true });
    await trigger('candidateCV');
  };

  const handleClearUpload = async () => {
    setValue('candidateCV', '', { shouldValidate: true });
    await trigger('candidateCV');
  };

  const getCurrentFile = () => {
    const candidateCV = watch('candidateCV');
    if (!candidateCV) return null;
    try {
      return JSON.parse(candidateCV);
    } catch {
      return null;
    }
  };

  const submitCandidate = async (data: TSubmitCandidateSchema) => {
    const formattedData = mapSubmitCandidateFormDataToAPI(data, roleId);
    await submitCandidateMutation(
      {
        params: {
          path: {
            partnerId,
          },
        },
        body: formattedData,
      },
      {
        onSuccess: () => {
          setSuccessModalOpen(true);
        },
        onError: (error) => {
          toast.error(error.message || 'Something went wrong');
        },
        onSettled: () => {
          setIsDuplicateSubmitBySamePartner(false);
          setIsDuplicateSubmitByDifferentPartner(false);
        },
      }
    );
  };

  const onSubmit = async (data: TSubmitCandidateSchema) => {
    //check for duplicate candidate
    const response = await checkDuplicateCandidateSubmission();

    if (response?.isAppliedByCurrentPartner) {
      setIsDuplicateSubmitBySamePartner(true);
    } else if (response?.isAppliedByOtherPartner) {
      setIsDuplicateSubmitByDifferentPartner(true);
    }

    if (response?.isAppliedByCurrentPartner || response?.isAppliedByOtherPartner) {
      return setDuplicateCandidateModalOpen(true);
    }

    await submitCandidate(data);
  };

  const onSubmitDuplicateConfirm = () => {
    if (isDuplicateSubmitBySamePartner) {
      setSuccessModalOpen(true);
    } else if (isDuplicateSubmitByDifferentPartner) {
      submitCandidate(getValues());
    }
  };

  const onSubmitAnother = () => {
    form.reset(defaultValues);
    fileUploaderRef.current?.clearFileName();
    setSuccessModalOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className="max-w-[30rem] space-y-6">
        <h1 className="text-lg font-semibold">Candidate Details</h1>
        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="First name"
                      error={!!errors?.firstName?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Last name"
                      error={!!errors?.lastName?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Email"
                      type="email"
                      error={!!errors?.email?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="phoneNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone number</FormLabel>
                  <FormControl>
                    <PhoneInput
                      {...field}
                      error={!!errors?.phoneNumber?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <div className="flex-1 space-y-1">
              <FormLabel>Pay Rate (i.e. Candidate Pay Rate incl. Super)</FormLabel>
              <div className="grid grid-cols-[1fr_3fr] gap-2">
                <FormField
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Select
                          className="flex"
                          options={currencyList.map((s) => ({ value: s.label, label: s.label }))}
                          selected={field.value}
                          onChange={field.onChange}
                          error={!!errors?.currency?.message}
                        />
                      </FormControl>
                      <FormError />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="payRate"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          type="text"
                          className="w-full"
                          placeholder="Day rate amount"
                          {...field}
                          value={formatCurrency(field.value, selectedCurrency)}
                          onChange={(event) => field.onChange(parseCurrency(event.target.value))}
                          error={!!errors?.payRate?.message}
                        />
                      </FormControl>
                      <FormError />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="flex-1 space-y-2">
              <FormField
                control={control}
                name="workingRights"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Working rights</FormLabel>
                    <FormControl>
                      <Select
                        {...field}
                        options={WORKING_RIGHTS_OPTIONS}
                        selected={field.value}
                        onChange={field.onChange}
                        error={!!errors?.workingRights?.message}
                        disableAlphabeticalSorting
                      />
                    </FormControl>
                    <FormError />
                  </FormItem>
                )}
              />
              {isCustomWorkingRights && (
                <FormField
                  control={control}
                  name="customWorkingRights"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="Please specify your Visa type"
                          error={!!errors?.customWorkingRights?.message}
                        />
                      </FormControl>
                      <FormError />
                    </FormItem>
                  )}
                />
              )}
            </div>
            <FormField
              control={control}
              name="backgroundChecks"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Background checks</FormLabel>
                  <FormControl>
                    <Select
                      {...field}
                      options={BACKGROUND_CHECK_OPTIONS}
                      selected={field.value}
                      onChange={field.onChange}
                      disableAlphabeticalSorting
                      error={!!errors?.backgroundChecks?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="noticePeriod"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Notice period</FormLabel>
                  <FormControl>
                    <Select
                      {...field}
                      options={NOTICE_PERIOD_OPTIONS}
                      selected={field.value}
                      onChange={field.onChange}
                      disableAlphabeticalSorting
                      error={!!errors?.noticePeriod?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <PrePlannedLeaveForms />
            <FormField
              control={control}
              name="candidateCV"
              render={() => (
                <FormItem>
                  <FormLabel>Candidate CV</FormLabel>
                  <FormControl>
                    <FileUploaderWithDragAndDrop
                      label="CV"
                      ref={fileUploaderRef}
                      acceptedFileTypes={[
                        'application/pdf',
                        'application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      ]}
                      keyPrefix="candidate-submissions"
                      onFileUpload={handleFileUpload}
                      currentFile={getCurrentFile()}
                      onClearUpload={handleClearUpload}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="hasObtainedWrittenAuthority"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <div className="space-y-6">
                    <Checkbox
                      id="written-authority-yes"
                      checked={field.value === true}
                      onCheckedChange={() => field.onChange(true)}
                      size="sm"
                      label="YES, I (Agent) have obtained a written authority to represent from the Applicant."
                    />
                    <Checkbox
                      id="written-authority-no"
                      checked={field.value === false}
                      onCheckedChange={() => field.onChange(false)}
                      size="sm"
                      label="NO, a written authority to represent has NOT been obtained from the Applicant."
                    />
                  </div>
                  <FormError />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="w-full"
              disabled={isPending || isCheckingDuplicateCandidateSubmission}
              loading={isPending || isCheckingDuplicateCandidateSubmission}
              loadingText="Submitting..."
            >
              Submit Candidate
            </Button>
          </Form>
        </FormProvider>
      </div>
      <SubmitSuccessModal
        open={successModalOpen}
        onOpenChange={setSuccessModalOpen}
        onSubmitAnother={onSubmitAnother}
      />
      <DuplicateCandidateModal
        open={duplicateCandidateModalOpen}
        onOpenChange={setDuplicateCandidateModalOpen}
        isSubmitting={isPending}
        onSubmitConfirm={onSubmitDuplicateConfirm}
      />
    </>
  );
};

export default SubmitCandidateForm;
