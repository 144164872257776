import { Calendar, View, Views } from 'react-big-calendar';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import { useState } from 'react';

import Spinner from '@/components/ui/spinner';

import commonComponents from '../config/common-components';
import localizer from '../config/localizer';
import BusinessEvent, { IBusinessEvent } from './business-event';

interface IBusinessCalendarProps {
  view: View;
  onChangeView: (view: View) => void;
  events?: IBusinessEvent[];
  isLoading?: boolean;
}

const BusinessCalendar: React.FC<IBusinessCalendarProps> = ({ view, onChangeView, events, isLoading }) => {
  const [date, setDate] = useState(new Date());

  return (
    <div className="relative max-w-full">
      <Calendar
        components={{
          ...commonComponents,
          event: ({ event }) => (
            <BusinessEvent
              view={view}
              event={event as IBusinessEvent}
            />
          ),
        }}
        localizer={localizer}
        view={view}
        onView={onChangeView}
        style={{ height: '80dvh', minHeight: '680px', width: '100%' }}
        date={date}
        scrollToTime={new Date()}
        doShowMoreDrillDown
        onDrillDown={(date) => {
          setDate(date);
          onChangeView(Views.DAY);
        }}
        onNavigate={setDate}
        events={events}
      />
      {isLoading && (
        <div className="absolute inset-0 z-50 flex h-[80dvh] w-full items-center justify-center rounded-2xl bg-primary-dark-5/50">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default BusinessCalendar;
