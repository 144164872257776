import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

import { BACKGROUND_CHECK, NOTICE_PERIOD, WORKING_RIGHTS } from '@/constants/submit-candidate';

export const submitCandidateSchema = z
  .object({
    firstName: z
      .string({
        required_error: 'First name is required',
      })
      .min(1, 'First name is required'),
    lastName: z
      .string({
        required_error: 'Last name is required',
      })
      .min(1, 'Last name is required'),
    email: z
      .string({
        required_error: 'Email is required',
      })
      .email('Invalid email address'),
    phoneNumber: z
      .string({
        required_error: 'Phone number is required',
      })
      .min(1, 'Phone number is required'),
    currency: z
      .string({
        required_error: 'Currency is required',
      })
      .min(1, 'Currency is required'),
    payRate: z.number().min(1, 'Pay rate is required'),
    workingRights: z.nativeEnum(WORKING_RIGHTS, {
      errorMap: () => ({ message: 'Working rights is required' }),
    }),
    customWorkingRights: z
      .string({
        required_error: 'Please specify Visa type',
      })
      .optional(),
    backgroundChecks: z.nativeEnum(BACKGROUND_CHECK, {
      errorMap: () => ({ message: 'Background check is required' }),
    }),
    noticePeriod: z.nativeEnum(NOTICE_PERIOD, {
      errorMap: () => ({ message: 'Notice period is required' }),
    }),
    prePlannedLeaves: z.array(
      z.object({
        dateRange: z.object({
          startDate: z.date().optional(),
          endDate: z.date().optional(),
        }),
      })
    ),
    candidateCV: z
      .string({
        required_error: 'Please upload a CV',
      })
      .min(1, 'Please upload a CV'),
    hasObtainedWrittenAuthority: z.boolean(),
  })
  .refine((data) => isValidPhoneNumber(data.phoneNumber), {
    message: 'Invalid phone number',
    path: ['phoneNumber'],
  })
  .refine(
    (data) => {
      if (data.workingRights === WORKING_RIGHTS.OTHER) {
        return data.customWorkingRights && data.customWorkingRights.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Please specify Visa type',
      path: ['customWorkingRights'],
    }
  );

export type TSubmitCandidateSchema = z.infer<typeof submitCandidateSchema>;
