import { memo, useEffect } from 'react';
import { useNotificationPanelStore } from '@/store/notification-panel-store';
import {
  UilBell,
  UilBuilding,
  UilCalendarAlt,
  UilChatBubbleUser,
  UilCodeBranch,
  UilSuitcaseAlt,
  UilUsersAlt,
} from '@iconscout/react-unicons';
import { createFileRoute, Outlet, redirect, useLocation } from '@tanstack/react-router';

import Separator from '@/components/ui/separator';

import GlobalSearch from '@/components/business/global-search';
import NavLink, { INavLinkProps } from '@/components/navigation/nav-link';
import ProfileNavLink from '@/components/navigation/profile-nav-link';
import SideBarContainer from '@/components/navigation/sidebar-container';
import SupportNavLink from '@/components/navigation/support-nav-link';
import { Notification, NotificationCount } from '@/components/notification/notification-panel';

import { BUSINESS_ENDPOINTS, getBusinessOnbaordingStatus, getCurrentBusiness, TBusiness } from '@/services/business';
import { getCurrentUser, USER_ENDPOINTS, USER_TYPE } from '@/services/user';

export const Route = createFileRoute('/_authenticated/business/_dashboard')({
  component: memo(BusinessDashboardLayout),
  beforeLoad: async ({ context }) => {
    const queryClient = context.queryClient;

    const user = await queryClient.fetchQuery({
      queryFn: getCurrentUser,
      queryKey: [USER_ENDPOINTS.GET_CURRENT_USER],
      staleTime: Infinity,
    });

    if (!user?.group_id) {
      throw redirect({ to: '/business/no-linked-business' });
    }

    const business = await queryClient.fetchQuery({
      queryFn: getCurrentBusiness,
      queryKey: [BUSINESS_ENDPOINTS.GET_CURRENT_BUSINESS],
      staleTime: Infinity,
    });

    const onboardingStatus = getBusinessOnbaordingStatus(business as TBusiness);
    if (onboardingStatus === 0) {
      throw redirect({ to: '/business/onboarding' });
    }

    if (onboardingStatus < 5) {
      throw redirect({ to: `/business/onboarding/step${onboardingStatus}` });
    }
  },
});

const navItems: INavLinkProps[] = [
  {
    icon: <UilBuilding className="default-icon" />,
    href: '/business',
    label: 'Home',
  },
  {
    icon: <UilBell className="default-icon" />,
    label: 'Notifications',
    onClick: () => {},
  },
  {
    icon: <UilSuitcaseAlt className="default-icon" />,
    href: '/business/roles',
    label: 'Roles',
    exact: false,
  },
  {
    icon: <UilCodeBranch className="default-icon" />,
    href: '/business/pipeline',
    label: 'Pipeline',
    exact: false,
  },
  {
    icon: <UilUsersAlt className="default-icon" />,
    href: '/business/team',
    label: 'Team',
    exact: false,
  },
  {
    icon: <UilCalendarAlt className="default-icon" />,
    href: '/business/calendar',
    label: 'Calendar',
  },
  {
    icon: <UilChatBubbleUser className="default-icon" />,
    href: '/business/partners',
    label: 'Partners',
    exact: false,
  },
];

function BusinessDashboardLayout() {
  const location = useLocation();
  const { showNotifications, setShowNotifications } = useNotificationPanelStore();

  useEffect(() => {
    setShowNotifications(false);
  }, [location.pathname, setShowNotifications]);

  return (
    <main className="flex">
      <SideBarContainer>
        <div className="flex h-full flex-col justify-between">
          <div className="space-y-8">
            <GlobalSearch role={USER_TYPE.BUSINESS} />
            <nav className="flex flex-col gap-2">
              {navItems.map((item) => (
                <NavLink
                  key={item.label}
                  {...item}
                  onClick={item.onClick ? () => setShowNotifications(!showNotifications) : undefined}
                  active={item.onClick && showNotifications}
                />
              ))}
            </nav>
          </div>
          <div>
            <SupportNavLink />
            <Separator size="sm" />
            <ProfileNavLink />
          </div>
        </div>
      </SideBarContainer>
      <Notification />
      <NotificationCount onClick={() => setShowNotifications(!showNotifications)} />
      {/* Add id and position:relative here for the children to be able to open portals */}
      <div
        className="relative flex-1 p-8 pb-20"
        id="dashboard-container"
      >
        <Outlet />
      </div>
    </main>
  );
}
