import { memo, useState } from 'react';
import { usePipelineStore } from '@/store/pipeline-store';
import { UilSuitcaseAlt, UilTimes } from '@iconscout/react-unicons';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import RolesSelectComboBox from '@/components/ui/roles-search-combobox';
import Heading from '@/components/ui/typography/heading';

import PipelineNav from '@/components/pipeline/business/pipeline-nav';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetAllRolesByBusinessIdV2 } from '@/hooks/business-roles';

import { PORTAL_IDS } from '@/constants/portal-ids';

export const Route = createFileRoute('/_authenticated/business/_dashboard/pipeline')({
  component: memo(PipelineLayout),
});

function PipelineLayout() {
  const { data: selectedRoles, setData: setSelectedRoles } = usePipelineStore();

  const { data: business } = useGetCurrentBusiness({});
  const [roleQuery, setRoleQuery] = useState<string>('');

  const { data: roles, isFetching: isRolesLoading } = useGetAllRolesByBusinessIdV2({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        limit: '10',
        offset: '0',
        status: 'active',
        q: roleQuery,
      },
    },

    reactQueryOptions: {
      enabled: !!business,
      queryKey: [roleQuery],
    },
  });

  const handleSelectedRoles = (roleId: number) => {
    setSelectedRoles(selectedRoles.filter((role) => role.id !== roleId));
  };

  return (
    <main className="relative -mx-8 flex h-full flex-col space-y-4">
      <div
        id={PORTAL_IDS.pipelineRoles}
        className="relative w-full"
      ></div>
      <div className="space-y-6 border-b border-primary-dark-10 px-8">
        <Heading
          title="Pipeline"
          size="page"
        />
        <div className="space-y-4 pb-4">
          <div className="flex items-center justify-between gap-4">
            <RolesSelectComboBox
              className="w-[20rem]"
              placeholder="Search role"
              options={roles?.items?.map((s) => ({ value: s.id, label: s.title })) || []}
              selected={selectedRoles}
              onSelect={setSelectedRoles}
              isLoading={isRolesLoading}
              onQueryChange={setRoleQuery}
            />
            <div className="flex justify-end gap-4">
              <div id={PORTAL_IDS.pipelineRolesFilter}></div>
              <PipelineNav />
            </div>
          </div>
          <div className="flex justify-between gap-4">
            {selectedRoles.length > 0 && (
              <div className="inline-flex flex-wrap gap-2">
                {selectedRoles.map((role) => (
                  <Button
                    key={role.id}
                    variant="tertiary"
                    onClick={() => handleSelectedRoles(role.id)}
                  >
                    <UilSuitcaseAlt className="size-4" />
                    <span> {role.title}</span>
                    <UilTimes className="size-[1.125rem] text-primary-dark-100" />
                  </Button>
                ))}
              </div>
            )}
            <div id={PORTAL_IDS.pipelineRolesAppliedFilters}></div>
          </div>
        </div>
      </div>
      <Outlet />
    </main>
  );
}
