import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetPartnerStats } from '@/hooks/business-partner-stats/business-partner-stats.queries';

import { PARTNER_ANALYTICS_TOOLTIPS } from '@/constants/partner-tooltips';

import { AnalyticsCard } from './analytics-card';
import { PartnersStatsSkeleton } from './partner-stats-skeleton';

export function PartnersStats() {
  const { data: business, isPending: isBusinessPending } = useGetCurrentBusiness({});

  const { data: partnerStats, isPending } = useGetPartnerStats({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
    },
    reactQueryOptions: {
      enabled: !!business,
    },
  });

  if (isPending || isBusinessPending) {
    return <PartnersStatsSkeleton />;
  }

  // Transform monthly data for charts
  const activeRolesChartData = partnerStats?.active_roles.monthly_data.map((item) => ({
    number: item.count,
  }));

  const hiresChartData = partnerStats?.hires.monthly_data.map((item) => ({
    number: item.count,
  }));

  const placementRatioChartData = partnerStats?.placement_ratio.monthly_data.map((item) => ({
    // Use numerator as the chart data point
    number: item.numerator,
  }));

  return (
    <div className="flex w-full flex-wrap items-center justify-between gap-6">
      <AnalyticsCard
        title="Active roles"
        description={PARTNER_ANALYTICS_TOOLTIPS.activeRoles}
        value={partnerStats?.active_roles.count}
        trend={partnerStats?.active_roles.trend}
        chartData={activeRolesChartData}
      />
      <AnalyticsCard
        title="Hired"
        description={PARTNER_ANALYTICS_TOOLTIPS.hired}
        value={partnerStats?.hires.count}
        trend={partnerStats?.hires.trend}
        chartData={hiresChartData}
      />
      <AnalyticsCard
        title="Placement ratio"
        description={PARTNER_ANALYTICS_TOOLTIPS.placementRatio}
        value={partnerStats?.placement_ratio.display_ratio}
        trend={partnerStats?.placement_ratio.trend}
        chartData={placementRatioChartData}
      />
    </div>
  );
}
