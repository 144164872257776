import React from 'react';

import { Card, CardContent, CardHeader } from '@/components/ui/card';
import Spinner from '@/components/ui/spinner';

import { CultureChart } from '@/components/quiz/charts/culture-chart';

import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { parseJobPostCultureProfile } from '@/services/business-roles';
import { CultureFactor, CultureName } from '@/services/candidate';

import { getCandidateCultureFromCultureProfile } from '@/utils/candidate';
import { getCultureChartData } from '@/utils/role';
import { parseCandidateCulture, parseCultureDescription } from '@/utils/string';

import { CULTURE_TO_EMOJI_MAP } from '@/constants/candidate';
import { VALUES_GROUP } from '@/constants/values-map';

import { ChartLabels } from './labels';

interface IProps {
  cultureName: CultureName;
  thrust: string;
  means: string;
  ends: string;
  primaryDataLabel?: string;
  showSecondLabel?: boolean;
}

export const CultureBreakdownCard: React.FC<IProps> = ({
  cultureName,
  thrust,
  means,
  ends,
  primaryDataLabel,
  showSecondLabel,
}) => {
  return (
    <Card className="mb-8 flex space-x-8 border-none p-0">
      <CardHeader className="flex flex-row items-center justify-between">
        <span className="text-7xl 3xl:text-8xl">{CULTURE_TO_EMOJI_MAP[cultureName]}</span>
      </CardHeader>
      <CardContent className="flex grow justify-between">
        <div className="grid max-w-[37rem] grid-cols-1 space-y-2">
          <div className="grid grid-cols-[5.25rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Culture:</h3>
            <span>{cultureName}</span>
          </div>
          <div className="grid grid-cols-[5.25rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Thrust:</h3>
            <span>{thrust}</span>
          </div>
          <div className="grid grid-cols-[5.25rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Means:</h3>
            <span>{means}</span>
          </div>
          <div className="grid grid-cols-[5.25rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Ends:</h3>
            <span>{ends}</span>
          </div>
        </div>
        <ChartLabels
          showSecondLabel={showSecondLabel}
          primaryLabel={primaryDataLabel}
        />
      </CardContent>
    </Card>
  );
};

type TCultureMatchProps = {
  roleCultureProfile?: string;
  candidateCultureProfile?: string;
  isLoading?: boolean;
  primaryDataLabel?: string;
  showCandidateBreakDown?: boolean;
};

export const CultureMatch: React.FC<TCultureMatchProps> = ({
  roleCultureProfile,
  isLoading,
  candidateCultureProfile,
  primaryDataLabel,
  showCandidateBreakDown,
}) => {
  const { data: cultureValueMap, isLoading: isCultureFactorLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.CULTURE_FACTORS,
      },
    },
  });

  let breakdownFactor: CultureFactor | undefined;

  if (showCandidateBreakDown && !candidateCultureProfile) throw new Error('candidateCultureProfile is required');
  const cultureFactors = cultureValueMap?.items.map((item) => {
    if (item.description && item.code) {
      const desc = { ...parseCultureDescription(item.description), code: item.code };

      return desc;
    } else
      return {
        code: '',
        name: 'string',
        measures: 'string',
        means: 'string',
        ends: 'string',
        thrust: 'string',
        extras: [''],
      };
  });

  if (!candidateCultureProfile && !roleCultureProfile) return null;

  if (isLoading || isCultureFactorLoading)
    return (
      <Card className="flex min-h-[30rem] items-center justify-center">
        <Spinner />
      </Card>
    );

  const parsedRoleCultureProfile = roleCultureProfile ? parseJobPostCultureProfile(roleCultureProfile) : null;
  const candidateCultureChartData = candidateCultureProfile ? parseCandidateCulture(candidateCultureProfile) : null;

  if (showCandidateBreakDown && candidateCultureChartData) {
    const highestRankCandidateCulture = getCandidateCultureFromCultureProfile(candidateCultureChartData)?.name;
    breakdownFactor = cultureFactors?.find((item) => item.name === highestRankCandidateCulture);
  } else {
    const highestRankRoleCulture = parsedRoleCultureProfile?.[0]?.name;
    breakdownFactor = cultureFactors?.find((item) => item.name === highestRankRoleCulture);
  }

  if (!breakdownFactor || !cultureFactors) return <span>Cannot render graph. Culture factors not found</span>;

  const roleCultureChartData = parsedRoleCultureProfile ? getCultureChartData(parsedRoleCultureProfile) : null;

  return (
    <Card className="flex flex-col gap-6">
      <CultureBreakdownCard
        cultureName={breakdownFactor.name as CultureName}
        thrust={breakdownFactor.thrust}
        means={breakdownFactor.means}
        ends={breakdownFactor.ends}
        primaryDataLabel={primaryDataLabel}
        showSecondLabel={!!candidateCultureProfile && !!roleCultureChartData?.length}
      />
      <CultureChart
        data={candidateCultureChartData ? candidateCultureChartData : roleCultureChartData!}
        data2={candidateCultureProfile && roleCultureChartData ? roleCultureChartData : undefined}
        factors={cultureFactors}
      />
    </Card>
  );
};
