import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  PIPELINE_ENDPOINTS,
  postCandidateFavorite,
  postCandidateHire,
  postCandidateMakeOffer,
  postCandidateMoveToInterview,
  postCandidateReinstate,
  postCandidateReject,
  postCandidateShortlist,
  postCandidateUnFavorite,
  selectPartnerForCandidate,
  TPostCandidateFavoriteRequest,
  TPostCandidateHireRequest,
  TPostCandidateMakeOfferRequest,
  TPostCandidateMoveToInterviewRequest,
  TPostCandidateReinstateRequest,
  TPostCandidateRejectRequest,
  TPostCandidateShortlistRequest,
  TPostCandidateUnFavoriteRequest,
} from '@/services/pipeline';

import { UseMutationOptions } from '../types';

export function useCandidateMakeOffer({ reactQueryOptions }: UseMutationOptions<TPostCandidateMakeOfferRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: postCandidateMakeOffer,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES],
      });
    },
  });
}

export function useCandidateReinstate({
  reactQueryOptions,
  params,
}: UseMutationOptions<TPostCandidateReinstateRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: postCandidateReinstate,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, params.path.businessId],
      });
    },
  });
}

export function useCandidateShortlist({
  reactQueryOptions,
  params,
}: UseMutationOptions<TPostCandidateShortlistRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: postCandidateShortlist,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, params.path.businessId],
      });
    },
  });
}

export function useCandidateMoveToInterview({
  reactQueryOptions,
  params,
}: UseMutationOptions<TPostCandidateMoveToInterviewRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: postCandidateMoveToInterview,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, params.path.businessId],
      });
    },
  });
}

export function useCandidateHire({ reactQueryOptions, params }: UseMutationOptions<TPostCandidateHireRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: postCandidateHire,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, params.path.businessId],
      });
    },
  });
}

export function useCandidateReject({ reactQueryOptions, params }: UseMutationOptions<TPostCandidateRejectRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: postCandidateReject,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, params.path.businessId],
      });
    },
  });
}

export function useCandidateFavorite({ reactQueryOptions, params }: UseMutationOptions<TPostCandidateFavoriteRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: postCandidateFavorite,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, params.path.businessId],
      });
    },
  });
}

export function useCandidateUnFavorite({
  reactQueryOptions,
  params,
}: UseMutationOptions<TPostCandidateUnFavoriteRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: postCandidateUnFavorite,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, params.path.businessId],
      });
    },
  });
}

export function useSelectPartnerForCandidate() {
  return useMutation({ mutationFn: selectPartnerForCandidate });
}
