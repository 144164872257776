export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getGMTOffset = () => {
  const now = new Date();
  const offsetMinutes = now.getTimezoneOffset();

  // Convert minutes to hours and minutes
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMins = Math.abs(offsetMinutes) % 60;

  // Determine if the offset is positive or negative
  const sign = offsetMinutes <= 0 ? '+' : '-';

  // Format the offset as GMT±HH:MM
  const formattedOffset = `GMT${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMins).padStart(2, '0')}`;

  return formattedOffset;
};

/**
 * Formats a date range into day, date, and time components
 * @param startDate - The start date and time
 * @param endDate - The end date and time
 * @returns Object containing formatted strings for:
 *  - day: Full weekday name (e.g. "Monday")
 *  - date: Month and day (e.g. "February 15")
 *  - startTime: Start time in 12-hour format (e.g. "9:00 AM")
 *  - endTime: End time in 12-hour format (e.g. "10:00 AM")
 */
export function formatDateRange(
  startDate: Date,
  endDate: Date
): { day: string; date: string; startTime: string; endTime: string } {
  const dayFormatter = new Intl.DateTimeFormat('en-US', { weekday: 'long' });
  const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' });
  const timeFormatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  const day = dayFormatter.format(startDate);
  const date = dateFormatter.format(startDate);
  const startTime = timeFormatter.format(startDate);
  const endTime = timeFormatter.format(endDate);

  return {
    day,
    date,
    startTime,
    endTime,
  };
}
