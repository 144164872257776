import {
  UilBuilding,
  UilCalendarAlt,
  UilHeadphonesAlt,
  UilMapMarkerAlt,
  UilSuitcaseAlt,
  UilUser,
} from '@iconscout/react-unicons';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button, type ButtonProps } from '@/components/ui/button';

import { useGetCurrentUser } from '@/hooks/user';

import { USER_TYPE } from '@/services/user';

import { formatDateRange } from '@/utils/dateTime';

interface IViewMeetingDetailsButtonProps {
  roleTitle: string;
  meetingLocation: string;
  meetingStartTime: string;
  meetingEndTime: string;
  companyName?: string;
  candidateName?: string;
  className?: string;
  size?: ButtonProps['size'];
}

export const ViewMeetingDetailsButton: React.FC<IViewMeetingDetailsButtonProps> = ({
  roleTitle,
  meetingLocation,
  meetingStartTime,
  meetingEndTime,
  companyName,
  candidateName,
  className,
  size,
}) => {
  const { data: user } = useGetCurrentUser({});
  const isCandidate = user?.user_type === USER_TYPE.CANDIDATE;

  const startTime = new Date(meetingStartTime);
  const endTime = new Date(meetingEndTime);
  const { day, date, startTime: formattedStartTime, endTime: formattedEndTime } = formatDateRange(startTime, endTime);

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          className={className}
          size={size}
        >
          View Details
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            <div className="flex items-center gap-3">
              <div className="flex h-10 w-10 items-center justify-center gap-2.5 rounded-lg bg-[#12b76a] px-1 py-0.5">
                <UilHeadphonesAlt className="text-primary-white-100" />
              </div>
              Meeting Details
            </div>
          </AlertDialogTitle>
        </AlertDialogHeader>
        <section className="space-y-3 font-semibold">
          {isCandidate ? (
            <div className="grid grid-cols-[12rem_auto] items-center rounded-lg border border-primary-dark-10">
              <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
                <UilBuilding className="!text-primary-dark-40 default-icon-md" />
                <span className="text-primary-dark-40">Company</span>
              </div>
              <p className="px-4 py-3 text-primary-dark-100">{companyName}</p>
            </div>
          ) : (
            <div className="grid grid-cols-[12rem_auto] items-center rounded-lg border border-primary-dark-10">
              <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
                <UilUser className="!text-primary-dark-40 default-icon-md" />
                <span className="text-primary-dark-40">Candidate</span>
              </div>
              <p className="px-4 py-3 text-primary-dark-100">{candidateName}</p>
            </div>
          )}
          <div className="grid grid-cols-[12rem_auto] items-center rounded-lg border border-primary-dark-10">
            <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
              <UilSuitcaseAlt className="!text-primary-dark-40 default-icon-md" />
              <span className="text-primary-dark-40">Role</span>
            </div>
            <p className="px-4 py-3 text-primary-dark-100">{roleTitle}</p>
          </div>
          <div className="grid grid-cols-[12rem_auto] items-center rounded-lg border border-primary-dark-10">
            <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
              <UilCalendarAlt className="!text-primary-dark-40 default-icon-md" />
              <span className="text-primary-dark-40">Date & Time</span>
            </div>
            <p className="px-4 py-3 text-primary-dark-100">
              {day}, {date}
              <br />
              {formattedStartTime} - {formattedEndTime}
            </p>
          </div>
          <div className="grid grid-cols-[12rem_auto] items-center rounded-lg border border-primary-dark-10">
            <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
              <UilMapMarkerAlt className="!text-primary-dark-40 default-icon-md" />
              <span className="text-primary-dark-40">Location</span>
            </div>
            <p className="px-4 py-3 text-primary-dark-100">{meetingLocation}</p>
          </div>
        </section>
        <AlertDialogFooter>
          <AlertDialogCancel>Close</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
