import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';

import {
  postCandidateFavorite,
  postCandidateHire,
  postCandidateMakeOffer,
  postCandidateMoveToInterview,
  postCandidateReinstate,
  postCandidateReject,
  postCandidateShortlist,
  postCandidateUnFavorite,
  TGetAllCandidatesForPipelineResponseForBoard,
} from '@/services/pipeline';

import { ROLE_CANDIDATE_STATUS } from '@/utils/application-status';

type PipelineCommonRequestOptionsParams = {
  businessId: string;
  postId: string;
  userId: string;
};

export const usePipelineActions = ({ queryKey }: { queryKey?: QueryKey }) => {
  const queryClient = useQueryClient();

  const handlePipelineActionEffect = (
    requestParams: PipelineCommonRequestOptionsParams,
    status: ROLE_CANDIDATE_STATUS
  ) => {
    if (!queryKey) return;

    queryClient.setQueryData(queryKey, (oldData: TGetAllCandidatesForPipelineResponseForBoard) => {
      const userId = parseInt(requestParams.userId);
      const postId = parseInt(requestParams.postId);
      const actioningCandidate = oldData.items.find(
        (item) => item.id == userId && item.job_post.id == postId && item.metadata?.candidate_status !== status
      );

      if (!actioningCandidate) return oldData;

      return {
        ...oldData,
        items: oldData.items.map((item) => {
          if (item.cardId === actioningCandidate?.cardId) {
            return {
              ...item,
              metadata: {
                ...item.metadata,
                candidate_status: status,
              },
            };
          }
          return item;
        }),
      };
    });
  };

  const makeOfferMutation = useMutation({
    mutationFn: postCandidateMakeOffer,
    onSuccess(_, variables) {
      handlePipelineActionEffect(variables.params.path, ROLE_CANDIDATE_STATUS.OFFER);
    },
    onError(_, variables) {
      handlePipelineActionEffect(
        variables.params.path,
        ROLE_CANDIDATE_STATUS[variables.params.query.from_status as ROLE_CANDIDATE_STATUS]
      );
    },
  });

  const reinstateMutation = useMutation({
    mutationFn: postCandidateReinstate,
    onSuccess(_, variables) {
      handlePipelineActionEffect(variables.params.path, ROLE_CANDIDATE_STATUS.APPLIED);
    },
    onError(_, variables) {
      handlePipelineActionEffect(
        variables.params.path,
        ROLE_CANDIDATE_STATUS[variables.params.query.from_status as ROLE_CANDIDATE_STATUS]
      );
    },
  });

  const shortlistMutation = useMutation({
    mutationFn: postCandidateShortlist,
    onSuccess(_, variables) {
      handlePipelineActionEffect(variables.params.path, ROLE_CANDIDATE_STATUS.SHORTLIST_CANDIDATE);
    },
    onError(_, variables) {
      handlePipelineActionEffect(
        variables.params.path,
        ROLE_CANDIDATE_STATUS[variables.params.query.from_status as ROLE_CANDIDATE_STATUS]
      );
    },
  });

  const moveToInterviewMutation = useMutation({
    mutationFn: postCandidateMoveToInterview,
    onSuccess(_, variables) {
      handlePipelineActionEffect(variables.params.path, ROLE_CANDIDATE_STATUS.INTERVIEWING);
    },
    onError(_, variables) {
      handlePipelineActionEffect(
        variables.params.path,
        ROLE_CANDIDATE_STATUS[variables.params.query.from_status as ROLE_CANDIDATE_STATUS]
      );
    },
  });

  const hireMutation = useMutation({
    mutationFn: postCandidateHire,
    onSuccess(_, variables) {
      handlePipelineActionEffect(variables.params.path, ROLE_CANDIDATE_STATUS.HIRED);
    },
    onError(_, variables) {
      handlePipelineActionEffect(
        variables.params.path,
        ROLE_CANDIDATE_STATUS[variables.params.query.from_status as ROLE_CANDIDATE_STATUS]
      );
    },
  });

  const rejectMutation = useMutation({
    mutationFn: postCandidateReject,
    onSuccess(_, variables) {
      handlePipelineActionEffect(variables.params.path, ROLE_CANDIDATE_STATUS.REJECT_CANDIDATE);
    },
    onError(_, variables) {
      handlePipelineActionEffect(
        variables.params.path,
        ROLE_CANDIDATE_STATUS[variables.params.query.from_status as ROLE_CANDIDATE_STATUS]
      );
    },
  });

  const favoriteMutation = useMutation({
    mutationFn: postCandidateFavorite,
  });

  const unFavoriteMutation = useMutation({
    mutationFn: postCandidateUnFavorite,
  });

  return {
    makeOffer: makeOfferMutation,
    reinstate: reinstateMutation,
    shortlist: shortlistMutation,
    moveToInterview: moveToInterviewMutation,
    hire: hireMutation,
    reject: rejectMutation,
    favorite: favoriteMutation,
    unFavorite: unFavoriteMutation,
  };
};
