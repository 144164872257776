import { TSubmitCandidateToRoleRequestBody } from '@/services/partner';

import { WORKING_RIGHTS } from '@/constants/submit-candidate';

import { TSubmitCandidateSchema } from '@/validation-schemas/partner/submit-candidate-schema';

export const mapSubmitCandidateFormDataToAPI = (
  data: TSubmitCandidateSchema,
  businessPostId: string
): TSubmitCandidateToRoleRequestBody => {
  const workingRights = data.workingRights === WORKING_RIGHTS.OTHER ? data.customWorkingRights : data.workingRights;

  return {
    business_post_id: Number(businessPostId),
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    phone_number: data.phoneNumber,
    currency: data.currency,
    pay_rate: data.payRate,
    working_rights: workingRights,
    background_checks: data.backgroundChecks,
    notice_period: data.noticePeriod,
    pre_planned_leaves: data.prePlannedLeaves
      .filter((leave) => leave.dateRange.startDate && leave.dateRange.endDate)
      .map((leave) => ({
        start_date: leave.dateRange.startDate!.toISOString(),
        end_date: leave.dateRange.endDate!.toISOString(),
      })),
    cv_url: data.candidateCV || '',
    has_obtained_written_authority: data.hasObtainedWrittenAuthority,
  };
};
