import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';

import Avatar from '@/components/ui/avatar';
import ComboBox from '@/components/ui/combo-box';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select } from '@/components/ui/select';
import { toast } from '@/components/ui/toaster';

import FileUploader from '@/components/file-uploader';
import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentBusinessSuspense, useUpdateCurrentBusiness } from '@/hooks/business';
import { useGetCurrentUser } from '@/hooks/user';
import { useGetValuesMapByGroup, useGetValuesMapByGroupAndCode } from '@/hooks/values-map';

import { getFormattedDataForAPI } from '@/utils/format';
import { getOptionsForSelectFromValuesMap } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

import { businessProfileInfoSchema, TBusinessProfileInfoFormData } from '@/validation-schemas/business/business-info';

import FormFooter from '../form-footer';
import FormNavigationBlocker from '../form-navigation-blocker';
import { IFormProps } from '../types';

const BusinessProfileInfoForm: React.FC<IFormProps> = ({ onSuccess, isOnboarding, isDisabled }) => {
  const { data: currentUser } = useGetCurrentUser({});
  const { data: currentBusiness } = useGetCurrentBusinessSuspense({});
  const { mutateAsync: updateBusiness, isPending: isUpdatingBusiness } = useUpdateCurrentBusiness({
    reactQueryOptions: {
      onSuccess: () => {
        if (!isOnboarding) {
          toast.success('Account updated successfully!');
        }
        onSuccess?.();
      },
    },
  });
  const form = useForm<TBusinessProfileInfoFormData>({
    disabled: isDisabled,
    resolver: zodResolver(businessProfileInfoSchema),
    mode: 'onChange',
    defaultValues: {
      logo_url: currentBusiness?.logo_url || '',
      name: currentBusiness?.name || '',
      business_abn: currentBusiness?.business_abn || '',
      country: currentBusiness?.country || '',
      city: currentBusiness?.city || '',
    },
    values: {
      logo_url: currentBusiness?.logo_url || '',
      name: currentBusiness?.name || '',
      business_abn: currentBusiness?.business_abn || '',
      country: currentBusiness?.country || '',
      city: currentBusiness?.city || '',
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = form;

  const selectedCountry = watch('country');
  const selectedCity = watch('city');

  const handleFileUpload = (url: string) => {
    setValue('logo_url', url, { shouldDirty: true, shouldValidate: true });
  };
  const { data: countriesList, isLoading: isCountriesLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.COUNTRY,
      },
    },
  });
  const selectedCountryCode =
    countriesList?.items?.find((country) => country.description?.toLowerCase() === selectedCountry.toLowerCase())
      ?.code || null;

  const {
    data: citiesList,
    isLoading: isCitiesListLoading,
    refetch: refetchCitiesList,
  } = useGetValuesMapByGroupAndCode({
    params: {
      query: {
        value_group: VALUES_GROUP.CITY,
        code: selectedCountryCode || '',
      },
    },
    reactQueryOptions: {
      enabled: Boolean(selectedCountryCode),
    },
  });

  useEffect(() => {
    if (!selectedCountryCode) return; // Early return if no country is selected

    const formattedCitiesList =
      citiesList?.items
        .map((city) => city.description)
        .filter((description): description is string => description !== undefined && description !== null) || [];

    // Check if the citiesList is available and whether the selected city exists in the list
    if (citiesList && selectedCity && !formattedCitiesList.includes(selectedCity)) {
      setValue('city', ''); // Clear the city value if it's not found in the list
      refetchCitiesList(); // Refetch the cities list when necessary
    }
  }, [selectedCountryCode, selectedCity, citiesList, refetchCitiesList]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit: SubmitHandler<TBusinessProfileInfoFormData> = (data) => {
    const formattedData = getFormattedDataForAPI(data);
    updateBusiness({ body: formattedData });
  };
  return (
    <FormProvider {...form}>
      <Form
        aria-disabled={isDisabled}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormField
          control={control}
          name="logo_url"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className={'flex h-[70px] items-center gap-4'}>
                  <Avatar
                    src={getValues('logo_url')}
                    size="xl"
                    fallbackType="company"
                  />
                  <div className="flex flex-col gap-4">
                    <FileUploader
                      acceptedFileTypes={['image/jpeg', 'image/png']}
                      keyPrefix={`business/${currentBusiness?.id}/${currentUser?.id}/`}
                      onFileUpload={handleFileUpload}
                      formError={!!errors?.logo_url?.message}
                      buttonText="Upload logo"
                      disabled={isDisabled}
                      ref={field.ref}
                    />
                    <p className="text-xs font-medium leading-[18px] text-primary-dark-60">
                      Upload a JPEG or PNG file no bigger than 6mb
                    </p>
                  </div>
                </div>
              </FormControl>
              <FormError className="pt-2" />
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Business name</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="text"
                  placeholder="Enter Business Name"
                  error={!!errors?.name}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="business_abn"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Business number (ABN or ACN)</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="text"
                  placeholder="Enter Business Number (ABN or ACN)"
                  error={!!errors?.business_abn?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="country"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Country</FormLabel>
              <FormControl>
                <Select
                  disabled={field.disabled}
                  className=""
                  options={getOptionsForSelectFromValuesMap(countriesList)}
                  selected={field.value as string | null}
                  placeholder="Select Option"
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.country?.message}
                  isLoading={isCountriesLoading}
                  ref={field.ref}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="city"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>City</FormLabel>
              <FormControl>
                <ComboBox
                  options={getOptionsForSelectFromValuesMap(citiesList)}
                  selected={field.value ? { value: field.value, label: field.value } : null}
                  onSelect={(value) => field.onChange(value)}
                  error={!!errors?.city?.message}
                  isLoading={isCitiesListLoading}
                  placeholder="Start typing here…"
                  disabled={field.disabled}
                  ref={field.ref}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>

      {isOnboarding ? (
        <OnboardingFooter
          handleNext={handleSubmit(onSubmit)}
          domain="business"
          isLoading={isUpdatingBusiness}
        />
      ) : (
        <>
          <FormNavigationBlocker />
          <FormFooter
            isDisabled={isDisabled}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isUpdatingBusiness}
          />
        </>
      )}
    </FormProvider>
  );
};
export default BusinessProfileInfoForm;
