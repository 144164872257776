import { useEffect } from 'react';
import { UilPlus } from '@iconscout/react-unicons';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { FormControl, FormError, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select } from '@/components/ui/select';
import Separator from '@/components/ui/separator';
import TeamMemberSelect from '@/components/ui/team-member-select';
import Heading from '@/components/ui/typography/heading';

import InterviewPlatformRadioGroup from '@/components/roles/business/create-role/interview-platform-radio-group';
import SettingContainer from '@/components/settings/setting-container';

import { useGetBusinessTeamMembers, useGetCurrentBusiness } from '@/hooks/business';

import { INTERVIEW_TYPE, INTERVIEW_TYPES_TO_DISPLAY_LABEL } from '@/constants/post';

import { TStep6FormData } from '@/validation-schemas/business/create-role-schema';

const interviewTypeOptions = [
  {
    value: INTERVIEW_TYPE.VIDEO,
    label: INTERVIEW_TYPES_TO_DISPLAY_LABEL[INTERVIEW_TYPE.VIDEO],
  },
  {
    value: INTERVIEW_TYPE.VIDEO_CALL_ASSESSMENT,
    label: INTERVIEW_TYPES_TO_DISPLAY_LABEL[INTERVIEW_TYPE.VIDEO_CALL_ASSESSMENT],
  },
  {
    value: INTERVIEW_TYPE.IN_PERSON,
    label: INTERVIEW_TYPES_TO_DISPLAY_LABEL[INTERVIEW_TYPE.IN_PERSON],
  },
];

const durationOptions = [
  {
    label: '15 minutes',
    value: '15',
  },
  {
    label: '30 minutes',
    value: '30',
  },
  {
    label: '45 minutes',
    value: '45',
  },
  {
    label: '1 hour',
    value: '60',
  },
];

const StageForms = () => {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<TStep6FormData>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'stages',
    keyName: 'RHFId', // differentiates default 'id' from react-hook-form and stageId from the backend
  });

  return (
    <div className="space-y-8">
      {fields.map((field, index) => (
        <div
          key={field.RHFId}
          className={field.isPreScreen ? 'hidden' : ' '}
        >
          <div className="flex gap-8">
            <FormField
              name={`stages.${index}.name`}
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Stage Name</FormLabel>
                  <FormControl>
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors?.stages?.[index]?.name}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <FormField
              name={`stages.${index}.interviewType`}
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Interview type</FormLabel>
                  <FormControl>
                    <Select
                      options={interviewTypeOptions}
                      selected={field.value}
                      onChange={field.onChange}
                      error={!!errors?.stages?.[index]?.interviewType}
                      disableAlphabeticalSorting
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <FormField
              name={`stages.${index}.duration`}
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Duration</FormLabel>
                  <FormControl>
                    <Select
                      options={durationOptions}
                      selected={field.value ? field.value.toString() : null}
                      onChange={(value) => {
                        return field.onChange(parseInt(value));
                      }}
                      error={!!errors?.stages?.[index]?.duration}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            {/* Only enable remove button if the stage has not been created in the BE yet */}
            {fields.length > 1 && !field?.id && (
              <Button
                variant="link"
                onClick={() => remove(index)}
                className="mt-6 self-center"
              >
                Remove
              </Button>
            )}
          </div>
          {index < fields.length - 1 && <Separator />}
        </div>
      ))}
      <Button
        variant="link"
        onClick={(e) => {
          e.preventDefault();
          append({
            name: '',
            interviewType: '',
            duration: 0,
          });
          trigger([
            `stages.${fields.length - 1}.name`,
            `stages.${fields.length - 1}.interviewType`,
            `stages.${fields.length - 1}.duration`,
          ]);
        }}
      >
        <UilPlus size={18} /> Add stage
      </Button>
    </div>
  );
};

const Step6Fields = () => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useFormContext<TStep6FormData>();

  const { data: currentBusiness } = useGetCurrentBusiness({});

  const { data: teamMembersData, isPending: isLoadingTeamMembers } = useGetBusinessTeamMembers({
    params: {
      path: {
        businessId: currentBusiness?.id.toString() ?? '',
      },
      query: {
        sort: 'last_active',
      },
    },
    reactQueryOptions: {
      enabled: !!currentBusiness,
    },
  });

  const roleOwner = watch('roleOwner');

  useEffect(() => {
    if (roleOwner) {
      const currentMembers = getValues('members') || [];
      const updatedMembers = currentMembers.filter((member) => member.id !== roleOwner.id);

      if (updatedMembers.length !== currentMembers.length) {
        setValue('members', updatedMembers, { shouldValidate: true });
      }
    }
  }, [roleOwner, setValue, getValues]);

  const teamMembersOptions = teamMembersData?.items?.map((member) => ({
    id: member.id.toString(),
    fullName: `${member.first_name} ${member.last_name}`,
    imageUrl: member.image_url,
  }));

  return (
    <>
      <SettingContainer>
        <Heading
          title="Role owner"
          subtitle="Select one owner for this role."
        />
        <FormField
          name="roleOwner"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Role owner</FormLabel>
              <FormControl>
                <TeamMemberSelect
                  selected={field.value}
                  options={teamMembersOptions ?? []}
                  isLoading={isLoadingTeamMembers}
                  onChange={(value) => {
                    if (value && 'id' in value) {
                      field.onChange(value);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  error={!!errors?.roleOwner}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />

      <SettingContainer>
        <Heading
          title="Team member/s"
          subtitle="Select one or multiple team members for this role."
        />
        <FormField
          name="members"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Team Members</FormLabel>
              <FormControl>
                <TeamMemberSelect
                  selected={field.value}
                  options={teamMembersOptions?.filter((member) => member.id !== roleOwner?.id) ?? []}
                  isLoading={isLoadingTeamMembers}
                  onChange={(value) => {
                    if (Array.isArray(value)) {
                      return field.onChange(value);
                    }
                    return field.onChange([]);
                  }}
                  error={!!errors?.members}
                  isMulti
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Interview platform"
          subtitle="Select your preferred interview platform for this role."
        />
        <FormField
          name="interviewPlatform"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <InterviewPlatformRadioGroup
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      {/* <SettingContainer>
        <Heading
          title="Interview template"
          subtitle="Select your preferred interview type for this role."
        />
        <FormField
          name="interviewTemplate"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Role types</FormLabel>
              <FormControl>
                <Select
                  selected={field.value}
                  options={[
                    { label: 'Fast (Single Interview)', value: 'Fast (Single Interview)' },
                    { label: 'Basic (2 stages)', value: 'Basic (2 stages)' },
                    { label: 'Standard (3 stages)', value: 'Standard (3 stages)' },
                  ]}
                  onChange={field.onChange}
                  error={!!errors?.interviewTemplate}
                  disableAlphabeticalSorting
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer> */}
      {/* <Separator /> */}
      <SettingContainer>
        <Heading
          title="Stage"
          subtitle="Add one or multiple stages to your interview process for this role."
        />
        <div className="space-y-8">
          <FormField
            control={control}
            name="hasPreScreenInterview"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="flex h-[3.75rem] items-center rounded-lg border border-primary-dark-20 px-4">
                    <Checkbox
                      id="hasPreScreenInterview"
                      checked={!!field.value}
                      onCheckedChange={field.onChange}
                      label="30 minute pre-screen interview"
                    />
                  </div>
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <StageForms />
        </div>
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="References"
          subtitle="Are references required for this role?"
        />
        <FormField
          control={control}
          name="requestReferences"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="flex h-[3.75rem] items-center rounded-lg border border-primary-dark-20 px-4">
                  <Checkbox
                    id="requestReferences"
                    checked={!!field.value}
                    onCheckedChange={field.onChange}
                    label="Request references with offer"
                  />
                </div>
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
    </>
  );
};

export default Step6Fields;
