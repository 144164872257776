import React from 'react';
import { createPortal } from 'react-dom';

import { Button } from '@/components/ui//button';

import useStepper from '@/hooks/useStepper';

import { getStepDetails } from '@/constants/onboarding';
import { PORTAL_IDS } from '@/constants/portal-ids';

export interface IOnboardingFooterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  handleNext?: () => void;
  domain?: 'candidate' | 'business' | 'partner';
}

const OnboardingFooter: React.FC<IOnboardingFooterProps> = ({
  domain = 'candidate',
  isDisabled,
  isLoading,
  handleNext,
}) => {
  const { currentStep, goToPreviousStep } = useStepper(domain);

  const currentList = getStepDetails(domain);

  const stepsContainer = document.getElementById(PORTAL_IDS.onBoardingFooter);
  if (!stepsContainer) return null;

  const isNextDisabled = isLoading || isDisabled;

  return createPortal(
    <section className="flex w-full items-center justify-center overflow-hidden bg-primary-white-100">
      <div className="flex w-full max-w-[880px] justify-between p-8">
        <Button
          variant="tertiary"
          size={'lg'}
          onClick={goToPreviousStep}
          className={currentStep == 1 ? 'invisible' : 'visible'}
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          type="submit"
          disabled={isNextDisabled}
          loading={isLoading}
          size={'lg'}
          className="min-w-24"
        >
          {currentStep === currentList.length ? 'Done' : 'Next'}
        </Button>
      </div>
    </section>,
    stepsContainer
  );
};

export default OnboardingFooter;
