import client, { publicClient, TRequestOptions } from '@/lib/api';

import { BUSINESS_ENDPOINTS } from './business.endpoints';
import {
  TBusiness,
  TBusinessInviteSetupRequest,
  TBusinessInviteSetupVerifyTokenRequest,
  TGetAllCalendarEventsRequest,
  TGetBusinessAuthMethodsRequest,
  TGetBusinessByIdRequest,
  TGetBusinessPartnersDetailsRequest,
  TGetBusinessPartnersRequest,
  TGetBusinessPartnerTeamMembersRequest,
  TGetBusinessTeamMembersDetailsRequest,
  TGetBusinessTeamMembersRequest,
  TGetCurrentBusinessRequest,
  TGetPartnerBusinessesRequest,
  TInviteBusinessPartnerRequest,
  TInviteBusinessTeamMemberRequest,
  TInvitePartnerBusinessTeamMemberRequest,
  TRemoveBusinessPartnerRequest,
  TRemoveBusinessTeamMemberRequest,
  TResendInviteBusinessPartnerRequest,
  TResendInviteBusinessTeamMemberRequest,
  TResetBusinessTeamMemberPasswordRequest,
  TUpdateBusinessPartnerRequest,
  TUpdateBusinessTeamMembersDetailsRequest,
  TUpdateCurrentBusinessRequest,
} from './business.types';

const { GET_BUSINESS_BY_ID, GET_CURRENT_BUSINESS, UPDATE_CURRENT_BUSINESS, GET_BUSINESS_AUTH_METHODS } =
  BUSINESS_ENDPOINTS;

export const getBusinessById = async ({ signal, params }: TRequestOptions<TGetBusinessByIdRequest>) => {
  const { data } = await client.GET(GET_BUSINESS_BY_ID, {
    signal,
    params,
  });

  return data?.data;
};

export const getBusinessAuthMethods = async ({ signal, params }: TRequestOptions<TGetBusinessAuthMethodsRequest>) => {
  const { data } = await client.GET(GET_BUSINESS_AUTH_METHODS, {
    signal,
    params,
  });

  return data?.data;
};

export const getCurrentBusiness = async ({ signal }: TRequestOptions<TGetCurrentBusinessRequest>) => {
  const { data } = await client.GET(GET_CURRENT_BUSINESS, {
    signal,
  });

  return data?.data;
};

export const updateCurrentBusiness = async ({ signal, body }: TRequestOptions<TUpdateCurrentBusinessRequest>) => {
  await client.PUT(UPDATE_CURRENT_BUSINESS, { signal, body });
};

export const getAllCalendarEvents = async ({ signal, params }: TRequestOptions<TGetAllCalendarEventsRequest>) => {
  const { data } = await client.GET(BUSINESS_ENDPOINTS.GET_ALL_CALENDAR_EVENTS, { signal, params });

  return data?.data;
};

const isAllBusinessFieldsEmpty = (business: TBusiness) => {
  // Check if all business fields are empty except for the name
  // The name is never empty, so we don't include it in our checks
  return !business?.business_abn && !business?.logo_url && !business?.country && !business?.city && !business?.bio;
};

export const getBusinessOnbaordingStatus = (business: TBusiness) => {
  if (isAllBusinessFieldsEmpty(business)) {
    return 0;
  }
  if (!business?.logo_url || !business?.name || !business?.business_abn || !business?.country || !business?.city) {
    return 1;
  }
  if (!business?.business_type || !business?.industry || !business?.company_values) {
    return 2;
  }
  if (!business?.bio || !business?.cover_url) {
    return 4;
  }
  return 5;
};

// Business Invitations setup

export const businessInviteSetupVerifyToken = async ({
  signal,
  params,
}: TRequestOptions<TBusinessInviteSetupVerifyTokenRequest>) => {
  const { data } = await publicClient.GET(BUSINESS_ENDPOINTS.BUSINESS_INVITE_SETUP_VERIFY_TOKEN, { signal, params });

  return data;
};

export const businessInviteSetup = async ({ signal, body }: TRequestOptions<TBusinessInviteSetupRequest>) => {
  const { data } = await publicClient.POST(BUSINESS_ENDPOINTS.BUSINESS_INVITE_SETUP, { signal, body });

  return data?.success;
};

// Team Members

export const inviteBusinessTeamMember = async ({
  signal,
  params,
  body,
}: TRequestOptions<TInviteBusinessTeamMemberRequest>) => {
  const { data } = await client.POST(BUSINESS_ENDPOINTS.INVITE_BUSINESS_TEAM_MEMBER, { signal, body, params });

  return data?.success;
};

export const invitePartnerBusinessTeamMember = async ({
  signal,
  params,
  body,
}: TRequestOptions<TInvitePartnerBusinessTeamMemberRequest>) => {
  const { data } = await client.POST(BUSINESS_ENDPOINTS.INVITE_PARTNER_BUSINESS_TEAM_MEMBER, { signal, body, params });

  return data?.success;
};

export const resendInviteBusinessTeamMember = async ({
  signal,
  params,
}: TRequestOptions<TResendInviteBusinessTeamMemberRequest>) => {
  const { data } = await client.POST(BUSINESS_ENDPOINTS.RESEND_INVITE_BUSINESS_TEAM_MEMBER, { signal, params });

  return data?.success;
};

export const resendInviteBusinessPartner = async ({
  signal,
  params,
}: TRequestOptions<TResendInviteBusinessPartnerRequest>) => {
  const { data } = await client.POST(BUSINESS_ENDPOINTS.RESEND_INVITE_BUSINESS_PARTNER, { signal, params });

  return data?.success;
};

export const resetBusinessTeamMemberPassword = async ({
  signal,
  params,
  body,
}: TRequestOptions<TResetBusinessTeamMemberPasswordRequest>) => {
  const { data } = await client.POST(BUSINESS_ENDPOINTS.RESET_BUSINESS_TEAM_MEMBER_PASSWORD, { signal, params, body });

  return data?.success;
};

export const removeBusinessTeamMember = async ({
  signal,
  params,
}: TRequestOptions<TRemoveBusinessTeamMemberRequest>) => {
  const { data } = await client.DELETE(BUSINESS_ENDPOINTS.REMOVE_BUSINESS_TEAM_MEMBER, { signal, params });

  return data?.success;
};

export const removeBusinessPartner = async ({ signal, params }: TRequestOptions<TRemoveBusinessPartnerRequest>) => {
  const { data } = await client.DELETE(BUSINESS_ENDPOINTS.REMOVE_BUSINESS_PARTNER, { signal, params });

  return data?.success;
};

export const getBusinessTeamMembers = async ({ signal, params }: TRequestOptions<TGetBusinessTeamMembersRequest>) => {
  const { data } = await client.GET(BUSINESS_ENDPOINTS.GET_BUSINESS_TEAM_MEMBERS, { signal, params });

  return data?.data;
};

export const getBusinessPartnerTeamMembers = async ({
  signal,
  params,
}: TRequestOptions<TGetBusinessPartnerTeamMembersRequest>) => {
  const { data } = await client.GET(BUSINESS_ENDPOINTS.GET_BUSINESS_PARTNER_TEAM_MEMBERS, { signal, params });

  return data?.data;
};

export const updateBusinessTeamMember = async ({
  signal,
  params,
  body,
}: TRequestOptions<TUpdateBusinessTeamMembersDetailsRequest>) => {
  const { data } = await client.PUT(BUSINESS_ENDPOINTS.UPDATE_BUSINESS_TEAM_MEMBER, {
    signal,
    body,
    params: {
      path: {
        businessId: params.path.businessId,
        memberId: params.path.memberId,
      },
    },
  });
  return data?.data;
};

// Team Partners

export const inviteBusinessPartner = async ({
  signal,
  params,
  body,
}: TRequestOptions<TInviteBusinessPartnerRequest>) => {
  const { data } = await client.POST(BUSINESS_ENDPOINTS.INVITE_BUSINESS_PARTNER, { signal, body, params });

  return data?.success;
};

export const getBusinessPartners = async ({ signal, params }: TRequestOptions<TGetBusinessPartnersRequest>) => {
  const { data } = await client.GET(BUSINESS_ENDPOINTS.GET_BUSINESS_PARTNERS, { signal, params });

  return data?.data;
};

export const getBusinessTeamMembersDetails = async ({
  signal,
  params,
}: TRequestOptions<TGetBusinessTeamMembersDetailsRequest>) => {
  const { data } = await client.GET(BUSINESS_ENDPOINTS.GET_BUSINESS_TEAM_MEMBERS_DETAILS, { signal, params });

  return data?.data;
};

export const getBusinessPartnersDetails = async ({
  signal,
  params,
}: TRequestOptions<TGetBusinessPartnersDetailsRequest>) => {
  const { data } = await client.GET(BUSINESS_ENDPOINTS.GET_BUSINESS_PARTNERS_DETAILS, { signal, params });

  return data?.data;
};

export const updateBusinessPartner = async ({
  signal,
  params,
  body,
}: TRequestOptions<TUpdateBusinessPartnerRequest>) => {
  const { data } = await client.PUT(BUSINESS_ENDPOINTS.UPDATE_BUSINESS_PARTNER_DETAILS, { signal, body, params });

  return data?.success;
};

export const getAllPartnerBusinesses = async ({ signal, params }: TRequestOptions<TGetPartnerBusinessesRequest>) => {
  const { data } = await client.GET(BUSINESS_ENDPOINTS.GET_PARTNER_BUSINESSES, { signal, params });

  return data?.data;
};
