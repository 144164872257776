import { createFileRoute } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import Confetti from '@/components/ui/confetti';

import FeaturedIcon from '@/components/icons/featured-icon';

import useAuth from '@/hooks/useAuth';

export const Route = createFileRoute('/invite-candidate/$token/success')({
  component: InvitationSuccessPage,
});

function InvitationSuccessPage() {
  const { logout } = useAuth();

  const handleLogin = () => {
    logout();
  };

  return (
    <div className={'flex h-screen w-screen items-center justify-center'}>
      <Confetti />
      <Card className="w-[32.75rem] p-8 shadow-2xl">
        <CardContent className="space-y-4">
          <CardHeader>
            <CardTitle className="flex items-center gap-3 text-2xl">
              <FeaturedIcon variant="success" />
              <span>Password created!</span>
            </CardTitle>
          </CardHeader>
          <CardDescription className="py-4 text-base text-primary-dark-60">
            You have successfully created your password.
            <br />
            <br />
            Please login to continue.
          </CardDescription>
          <CardFooter>
            <Button onClick={handleLogin}>Login</Button>
          </CardFooter>
        </CardContent>
      </Card>
    </div>
  );
}
