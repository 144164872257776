import { createFileRoute } from '@tanstack/react-router';

import Heading from '@/components/ui/typography/heading';

import BackButton from '@/components/back-button';
import CandidateRolesTable from '@/components/roles/candidate-roles-table';

import { useGetCurrentCandidateArchivedMatches } from '@/hooks/candidate';
import useFilters from '@/hooks/useFilters';

import { TCandidatePostsFilters, TGetCurrentCandidateArchivedSort } from '@/services/candidate';

import { sortByToQuery } from '@/utils/table-sort-mapper';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@/constants/table';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/(roles-pages)/archived')({
  component: Archived,
  validateSearch: () => ({}) as TCandidatePostsFilters,
});

function Archived() {
  const { filters, setFilters } = useFilters<TCandidatePostsFilters>(Route.id);

  const paginationState = {
    pageIndex: filters.pageIndex ?? DEFAULT_PAGE_INDEX,
    pageSize: filters.pageSize ?? DEFAULT_PAGE_SIZE,
  };

  const sortQuery = sortByToQuery<TGetCurrentCandidateArchivedSort>(filters.sortBy);

  const { data, isPending } = useGetCurrentCandidateArchivedMatches({
    reactQueryOptions: {
      queryKey: [filters],
    },
    params: {
      query: {
        limit: paginationState.pageSize.toString(),
        offset: (paginationState.pageIndex * paginationState.pageSize).toString(),
        q: filters.query,
        ...(sortQuery && {
          sort: sortQuery.id,
          order: sortQuery.order,
        }),
      },
    },
  });

  return (
    <main className="space-y-6">
      <div className="flex items-center gap-6">
        <BackButton backRoute="/candidate/roles/applied" />
        <Heading
          title="Archived"
          size="page"
        />
      </div>
      <CandidateRolesTable
        data={data}
        isPending={isPending}
        filters={filters}
        setFilters={setFilters}
        paginationState={paginationState}
        isArchived
      />
    </main>
  );
}
