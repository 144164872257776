import { QueryKey } from '@tanstack/react-query';

import Separator from '@/components/ui/separator';

import { ProfileBubbles } from '@/components/business/profile-bubbles';

import { useGetCurrentUser } from '@/hooks/user';

import { TCandidateForPipeline } from '@/services/pipeline';
import { hasPartnerBeenSelectedForPipelineCandidate } from '@/services/pipeline/piepline.utils';
import { USER_TYPE } from '@/services/user';

import SelectPartnerButton from './select-partner-button';

interface PartnerInfoProps {
  candidate: TCandidateForPipeline;
  queryKey: QueryKey;
}

// Todo if candidate has a referer show referer info
const PartnerInfo: React.FC<PartnerInfoProps> = ({ candidate, queryKey }) => {
  const { data: user } = useGetCurrentUser({});
  const isBusinessUser = user?.user_type === USER_TYPE.BUSINESS;

  // Only show for business users
  if (!isBusinessUser) return null;

  // Find the selected partner if one exists
  const selectedPartner = hasPartnerBeenSelectedForPipelineCandidate(candidate.recruiters || [])
    ? candidate?.recruiters?.find((recruiter) => recruiter.recruiting_candidate?.is_selected)
    : null;

  // Create partner bubbles data
  const partners = selectedPartner
    ? [
        {
          id: selectedPartner.id,
          name: selectedPartner.name,
          image_url: selectedPartner.logo_url,
          fallbackType: 'company' as const,
        },
      ]
    : candidate.recruiters?.map((recruiter) => ({
        id: recruiter.id,
        name: recruiter.name,
        image_url: recruiter.logo_url,
        fallbackType: 'company' as const,
      })) || [];

  // Don't render if there are no partners
  if (partners.length === 0) return null;

  return (
    <div>
      <Separator size="xs" />
      <div className="space-y-4">
        <ProfileBubbles bubbles={partners} />
        <SelectPartnerButton
          candidate={candidate}
          queryKey={queryKey}
        />
      </div>
    </div>
  );
};

export default PartnerInfo;
