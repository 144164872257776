import { Badge } from '@/components/ui/badge';
import Separator from '@/components/ui/separator';
import Heading from '@/components/ui/typography/heading';

import NoData from '@/components/no-data';
import { ExperienceCard } from '@/components/onboarding/experience-card';
import { CultureMatch } from '@/components/roles/details/new-culture-breakdown-card';
import { PersonalityMatch } from '@/components/roles/details/new-personality-breakdown';

import { PersonalityArchetypeCode } from '@/services/candidate';
import { hasCandidateCompletedOnboarding } from '@/services/candidate/candidate.services';
import { TCandidateDetails } from '@/services/pipeline';

import { getArrayFromString } from '@/utils/format';

interface PipelineCandidateProfileProps {
  candidateProfile: TCandidateDetails['candidate_profile'];
  jobPost: TCandidateDetails['job_post'];
}
const PipelineCandidateProfile: React.FC<PipelineCandidateProfileProps> = ({ candidateProfile, jobPost }) => {
  if (!hasCandidateCompletedOnboarding(candidateProfile)) {
    return (
      <NoData
        title="Candidate information not found"
        description="Candidate has not completed onboarding yet"
      />
    );
  }
  return (
    <main className="space-y-6">
      <Heading
        title="Profile"
        size="title"
      />
      <section className="space-y-2">
        <Heading
          title="Candidate bio"
          size="subtitle"
        />
        {candidateProfile?.bio
          ?.split('\n')
          .filter((line) => line.trim() !== '')
          .map((line, index) => (
            <p
              key={index}
              className="text-sm font-medium text-primary-dark-60"
            >
              {index > 0 && <br />}
              {line}
            </p>
          ))}
      </section>
      <Separator />
      <section className="space-y-2">
        <Heading
          title="Experience"
          size="subtitle"
        />
        <div className="space-y-4">
          {candidateProfile?.experience_1_role && (
            <ExperienceCard
              companyName={candidateProfile?.experience_1_compname || ''}
              role={candidateProfile?.experience_1_role}
              startDate={new Date(candidateProfile?.experience_1_start_date || '')}
              workingCurrently={candidateProfile?.experience_1_is_current}
              endDate={new Date(candidateProfile?.experience_1_end_date || '')}
            />
          )}
          {candidateProfile?.experience_2_role && (
            <ExperienceCard
              companyName={candidateProfile?.experience_2_compname || ''}
              role={candidateProfile?.experience_2_role || ''}
              startDate={new Date(candidateProfile?.experience_2_start_date || '')}
              workingCurrently={candidateProfile?.experience_2_is_current}
              endDate={new Date(candidateProfile?.experience_2_end_date || '')}
            />
          )}
          {candidateProfile?.experience_3_role && (
            <ExperienceCard
              companyName={candidateProfile?.experience_3_compname || ''}
              role={candidateProfile?.experience_3_role || ''}
              startDate={new Date(candidateProfile?.experience_3_start_date || '')}
              workingCurrently={candidateProfile?.experience_3_is_current}
              endDate={new Date(candidateProfile?.experience_3_end_date || '')}
            />
          )}
        </div>
      </section>
      <Separator />
      <section className="space-y-6">
        <div className="space-y-2">
          <Heading
            title="Education"
            size="subtitle"
          />
          {candidateProfile?.education && (
            <Badge
              variant="success"
              hideDot
            >
              <span>{candidateProfile?.education}</span>
            </Badge>
          )}
        </div>
        <div className="space-y-2">
          <Heading
            title="Qualifications"
            size="subtitle"
          />
          <div className="flex flex-wrap gap-2">
            {getArrayFromString(candidateProfile?.qualification || '').map((qualification, index) => (
              <Badge
                key={index}
                variant="success"
                hideDot
              >
                <span>{qualification}</span>
              </Badge>
            ))}
          </div>
        </div>
        <div className="space-y-2">
          <Heading
            title="Languages"
            size="subtitle"
          />
          <div className="flex flex-wrap gap-2">
            <Badge
              variant="success"
              hideDot
            >
              <span>English - {candidateProfile?.english_proficiency}</span>
            </Badge>
            {getArrayFromString(candidateProfile?.languages || '').map((language, index) => (
              <Badge
                key={index}
                variant="success"
                hideDot
              >
                <span>{language}</span>
              </Badge>
            ))}
          </div>
        </div>
      </section>
      <Separator />
      <section className="space-y-6">
        <div className="space-y-2">
          <Heading
            title="Technical skills"
            size="subtitle"
          />
          <div className="flex flex-wrap gap-2">
            {getArrayFromString(candidateProfile?.technical_skills || '').map((skill, index) => (
              <Badge
                key={index}
                variant="success"
                hideDot
              >
                <span>{skill}</span>
              </Badge>
            ))}
          </div>
        </div>
        <div className="space-y-2">
          <Heading
            title="Domain"
            size="subtitle"
          />
          <div className="flex flex-wrap gap-2">
            {getArrayFromString(candidateProfile?.domain_exp || '').map((domain, index) => (
              <Badge
                key={index}
                variant="success"
                hideDot
              >
                <span>{domain}</span>
              </Badge>
            ))}
          </div>
        </div>
      </section>
      <Separator />
      <section className="space-y-6">
        <div className="space-y-2">
          <Heading
            title="Soft Skills"
            size="subtitle"
          />
          <div className="flex flex-wrap gap-2">
            {getArrayFromString(candidateProfile?.skills || '').map((skill, index) => (
              <Badge
                key={index}
                variant="success"
                hideDot
              >
                <span>{skill}</span>
              </Badge>
            ))}
          </div>
        </div>
        <div className="space-y-2">
          <Heading
            title="Values"
            size="subtitle"
          />
          <div className="flex flex-wrap gap-2">
            {getArrayFromString(candidateProfile?.candidate_values || '').map((value, index) => (
              <Badge
                key={index}
                variant="success"
                hideDot
              >
                <span>{value}</span>
              </Badge>
            ))}
          </div>
        </div>
      </section>
      {candidateProfile?.personality_test_info && (
        <>
          <Separator />
          <section className="space-y-2">
            <Heading
              title="Personality"
              size="subtitle"
            />
            <PersonalityMatch
              candidatePersonalityProfile={candidateProfile?.personality_test_info}
              rolePersonalityProfile={jobPost?.personality_profile}
              primaryDataLabel="Candidate"
              showCandidateBreakDown
              candidatePersonalityCode={candidateProfile?.personality_archetype as PersonalityArchetypeCode}
            />
          </section>
        </>
      )}
      {candidateProfile?.culture && jobPost?.culture_profile && (
        <>
          <Separator />
          <section className="space-y-2">
            <Heading
              title="Culture"
              size="subtitle"
            />
            <CultureMatch
              candidateCultureProfile={candidateProfile?.culture}
              roleCultureProfile={jobPost?.culture_profile}
              primaryDataLabel="Candidate"
              showCandidateBreakDown
            />
          </section>
        </>
      )}
    </main>
  );
};

export default PipelineCandidateProfile;
