import { useParams } from '@tanstack/react-router';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetPartnerDetailStats } from '@/hooks/business-partner-stats/business-partner-stats.queries';

import { PARTNER_ANALYTICS_TOOLTIPS } from '@/constants/partner-tooltips';

import { AnalyticsCard } from './analytics-card';
import { PartnerStatsSkeleton } from './partner-stats-skeleton';

export function PartnerStats() {
  const { partnerId } = useParams({ from: '/_authenticated/business/_dashboard/partners/$partnerId/placements' });
  const { data: business, isPending: isBusinessPending } = useGetCurrentBusiness({});

  const { data: partnerStats, isPending } = useGetPartnerDetailStats({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        partnerId: partnerId,
      },
    },
    reactQueryOptions: {
      enabled: !!business && !!partnerId,
    },
  });

  if (isPending || isBusinessPending) {
    return <PartnerStatsSkeleton />;
  }

  // Transform monthly data for charts
  const activeRolesChartData = partnerStats?.active_roles.monthly_data.map((item) => ({
    number: item.count,
  }));

  const hiresChartData = partnerStats?.hires.monthly_data.map((item) => ({
    number: item.count,
  }));

  const timeToFillChartData = partnerStats?.time_to_fill.monthly_data.map((item) => ({
    number: item.days,
  }));

  const placementRatioChartData = partnerStats?.placement_ratio.monthly_data.map((item) => ({
    // Use numerator as the chart data point
    number: item.numerator,
  }));

  return (
    <div className="grid grid-cols-2 items-center justify-between gap-6 pb-8 3xl:grid-cols-4">
      <AnalyticsCard
        title="Active roles"
        description={PARTNER_ANALYTICS_TOOLTIPS.activeRoles}
        value={partnerStats?.active_roles.count}
        trend={partnerStats?.active_roles.trend}
        chartData={activeRolesChartData}
      />
      <AnalyticsCard
        title="Hired"
        description={PARTNER_ANALYTICS_TOOLTIPS.hired}
        value={partnerStats?.hires.count}
        trend={partnerStats?.hires.trend}
        chartData={hiresChartData}
      />
      <AnalyticsCard
        title="Time to fill"
        description={PARTNER_ANALYTICS_TOOLTIPS.timeToFill}
        value={partnerStats?.time_to_fill.average_days ? `${partnerStats.time_to_fill.average_days} days` : undefined}
        trend={partnerStats?.time_to_fill.trend}
        chartData={timeToFillChartData}
      />
      <AnalyticsCard
        title="Placement ratio"
        description={PARTNER_ANALYTICS_TOOLTIPS.placementRatio}
        value={partnerStats?.placement_ratio.display_ratio}
        trend={partnerStats?.placement_ratio.trend}
        chartData={placementRatioChartData}
      />
    </div>
  );
}
