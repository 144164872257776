import { useQuery } from '@tanstack/react-query';

import {
  getCandidateForAPost,
  getCurrentPostMatches,
  getPostById,
  getPostByIdPublicly,
  POST_ENDPOINTS,
  TGetCandidateForAPostRequest,
  TGetCurrentPostMatchesRequest,
  TGetPostByIdPubliclyRequest,
  TGetPostByIdRequest,
} from '@/services/post';

import { type UseQueryOptions } from '../types';

export function useGetPostById({ reactQueryOptions, params }: UseQueryOptions<TGetPostByIdRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [POST_ENDPOINTS.GET_POST_BY_ID, params.path.id],
    queryFn: ({ signal }) => getPostById({ signal, params }),
    staleTime: Infinity,
  });
}
export function useGetPostByIdPublicly({ reactQueryOptions, params }: UseQueryOptions<TGetPostByIdPubliclyRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [POST_ENDPOINTS.GET_POST_PUBLICLY, params.path.postId],
    queryFn: ({ signal }) => getPostByIdPublicly({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetCurrentPostMatches({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentPostMatchesRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;

  return useQuery({
    ...restOptions,
    queryKey: [POST_ENDPOINTS.GET_CURRENT_POST_MATCHES, ...queryKey],
    queryFn: ({ signal }) => getCurrentPostMatches({ signal, params }),
  });
}

export function useGetCandidateForAPost({ reactQueryOptions, params }: UseQueryOptions<TGetCandidateForAPostRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [POST_ENDPOINTS.GET_CANDIDATE_FOR_A_POST, params.path.id, params.path.userId],
    queryFn: ({ signal }) => getCandidateForAPost({ signal, params }),
  });
}
