import { useState } from 'react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { z } from 'zod';

import BackButton from '@/components/back-button';
import FullPageLoader from '@/components/full-page-loader';
import PersonalityQuizCard from '@/components/quiz/personality/personality-option';

import { useUpdateCurrentCandidate } from '@/hooks/candidate';

import { PersonalityArchetypeCode } from '@/services/candidate';

import { PERSONALITY_QUIZ_OPTIONS } from '@/constants/quiz';

const quizSearchParamsSchema = z.object({
  retake: z.boolean().optional(),
});
export const Route = createFileRoute('/_authenticated/candidate/quiz/personality/')({
  component: PersonalityQuiz,
  validateSearch: (searchParams) => quizSearchParamsSchema.parse(searchParams),
});

const INITIAL_PERSONALITY_CODE_MAP: Record<PersonalityArchetypeCode, number> = {
  X: 0,
  H: 0,
  O: 0,
  A: 0,
  E: 0,
  C: 0,
};

const getPersonalityArchetype = (obj: Record<PersonalityArchetypeCode, number>) => {
  const sortedValues = Object.entries(obj).sort(([, a], [, b]) => b - a);
  return sortedValues[0][0] as PersonalityArchetypeCode;
};

function PersonalityQuiz() {
  const navigate = useNavigate();
  const { retake } = Route.useSearch();

  const { mutateAsync: updateCandidate, isPending } = useUpdateCurrentCandidate({
    reactQueryOptions: {
      onSuccess: () => navigate({ to: '/candidate/quiz/personality/result' }),
    },
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(INITIAL_PERSONALITY_CODE_MAP);

  const handleClick = (code: PersonalityArchetypeCode) => {
    if (isPending) return;

    const updatedOptions = { ...selectedOptions, [code]: (selectedOptions[code] || 0) + 1 };

    if (currentStep >= PERSONALITY_QUIZ_OPTIONS.length - 1) {
      const personality_archetype = getPersonalityArchetype(updatedOptions);
      const personality_test_info = JSON.stringify(updatedOptions);
      updateCandidate({ body: { personality_archetype, personality_test_info } });
    } else {
      setSelectedOptions(updatedOptions);
      setCurrentStep(currentStep + 1);
    }
  };

  if (isPending) return <FullPageLoader />;

  return (
    <section className="flex justify-center">
      <div className="w-lg">
        <header className="mt-2 py-16">
          <p className="text-sm font-medium leading-tight text-primary-dark-60">
            {currentStep + 1} of {PERSONALITY_QUIZ_OPTIONS.length}
          </p>
          <h1 className="text-2xl font-semibold leading-loose text-primary-dark-100">
            For each of the following traits, please choose the one that is most like you.
          </h1>
          <p className="mt-6 text-base font-medium leading-normal text-primary-dark-60 3xl:mt-8">
            Please select a trait.
          </p>
        </header>
        <main className="flex justify-between py-8">
          <PersonalityQuizCard
            onClick={handleClick}
            {...PERSONALITY_QUIZ_OPTIONS[currentStep].option1}
          />
          <PersonalityQuizCard
            onClick={handleClick}
            {...PERSONALITY_QUIZ_OPTIONS[currentStep].option2}
          />
        </main>
        {retake && (
          <BackButton
            backRoute="/"
            onlyUseBackRoute
            className="mt-8"
          />
        )}
      </div>
    </section>
  );
}

export default PersonalityQuiz;
