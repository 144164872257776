import { useRouter } from '@tanstack/react-router';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import Confetti from '@/components/ui/confetti';

interface SubmitSuccessModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmitAnother: () => void;
}

const SubmitSuccessModal = ({ open, onOpenChange, onSubmitAnother }: SubmitSuccessModalProps) => {
  const router = useRouter();

  return (
    <>
      <AlertDialog
        open={open}
        onOpenChange={onOpenChange}
      >
        {open && (
          <Confetti
            usePortal
            duration={1000}
          />
        )}
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle iconVariant="success">Candidate Submitted!</AlertDialogTitle>
            <AlertDialogDescription>
              The candidate has been submitted! Make sure you check your notifications for any updates.
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() =>
                router.navigate({
                  to: '/',
                })
              }
            >
              Go to dashboard
            </AlertDialogCancel>
            <AlertDialogAction onClick={onSubmitAnother}>Submit another</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SubmitSuccessModal;
