import { useRef } from 'react';
import { UilUsersAlt } from '@iconscout/react-unicons';

import { Badge } from '@/components/ui/badge';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

import { cn } from '@/lib/utils';

interface RoleCandidatesCountPillProps {
  filledCount: number;
  requiredCount: number;
  variant?: 'compact' | 'full'; // 'compact' for "0/5", 'full' for "0 of 5 Candidates filled"
  showIcon?: boolean;
  tooltipText?: string; // Optional tooltip text
  className?: string;
}

const RoleCandidatesCountPill = ({
  filledCount,
  requiredCount,
  variant = 'compact',
  showIcon = true,
  tooltipText,
  className,
}: RoleCandidatesCountPillProps) => {
  const tooltipTriggerRef = useRef<HTMLButtonElement>(null);

  // Determine if we should show the success state (all candidates filled)
  const isComplete = requiredCount > 0 && filledCount >= requiredCount;

  // Handle edge case where required count is 0
  const displayFilledCount = Math.max(0, filledCount);
  const displayRequiredCount = Math.max(0, requiredCount);

  // Content based on variant
  const content =
    variant === 'compact'
      ? `${displayFilledCount}/${displayRequiredCount}`
      : `${displayFilledCount} of ${displayRequiredCount} Candidate${displayRequiredCount !== 1 ? 's' : ''} filled`;

  // Generate the tooltip description based on current state
  const getTooltipDescription = () => {
    if (isComplete) {
      return 'All required candidates have been filled for this role.';
    }

    if (filledCount > 0) {
      return variant === 'compact'
        ? `This role has ${filledCount} of ${requiredCount} candidates filled.`
        : 'Click to view candidate details.';
    }

    return 'No candidates have been filled for this role yet.';
  };

  // For standalone text
  if (variant === 'full' && !tooltipText) {
    return (
      <div
        className={cn(
          'flex items-center gap-1.5 text-sm font-medium',
          isComplete ? 'text-primary-success-100' : 'text-primary-dark-60',
          className
        )}
        aria-label={`${filledCount} of ${requiredCount} candidates filled`}
      >
        {showIcon && (
          <UilUsersAlt className={cn('size-4', isComplete ? 'text-primary-success-100' : 'text-primary-dark-40')} />
        )}
        {content}
      </div>
    );
  }

  // For pill or when tooltip is needed
  const pill = (
    <Badge
      variant={isComplete ? 'success' : 'grey'}
      className={cn('flex items-center gap-1.5', className)}
    >
      {showIcon && <UilUsersAlt className="size-4" />}
      {content}
    </Badge>
  );

  // Wrap in tooltip if tooltipText is provided
  if (tooltipText) {
    return (
      <TooltipProvider delayDuration={200}>
        <Tooltip>
          <TooltipTrigger
            ref={tooltipTriggerRef}
            className="flex items-center gap-1.5"
            onClick={(event) => event.preventDefault()}
            aria-label={`${filledCount} of ${requiredCount} candidates filled`}
          >
            {pill}
          </TooltipTrigger>
          <TooltipContent
            onPointerDownOutside={(event) => {
              if (tooltipTriggerRef.current?.contains(event.target as Node)) event.preventDefault();
            }}
            className="max-w-[320px] rounded-xl bg-primary-dark-100 px-3 py-2 text-xs text-primary-white-100"
          >
            <h4 className="pb-2 font-semibold leading-4">{tooltipText}</h4>
            <span className="font-medium leading-4">{getTooltipDescription()}</span>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return pill;
};

export default RoleCandidatesCountPill;
