import { useState } from 'react';
import { UilArrowLeft, UilClipboardNotes } from '@iconscout/react-unicons';
import { createFileRoute, useRouter } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';

import FullPageLoader from '@/components/full-page-loader';
import RoleDetails from '@/components/roles/business/role-details/role-details';
import RoleDetailsSidePanel from '@/components/roles/business/role-details/roles-details-side-panel';
import { CultureMatch } from '@/components/roles/details/new-culture-breakdown-card';
import { PersonalityMatch } from '@/components/roles/details/new-personality-breakdown';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetJobPostById } from '@/hooks/business-roles';
import { useGetCurrentUserPermissions } from '@/hooks/permission';

import { JOB_POST_STATUS } from '@/constants/post';

export const Route = createFileRoute('/_authenticated/business/_dashboard/role/$roleId')({
  component: RolePage,
});

function RolePage() {
  const { roleId } = Route.useParams();
  const router = useRouter();
  const { data: business, isPending: isBusinessPending } = useGetCurrentBusiness({});
  const [showPersonalityBreakdown, setShowPersonalityBreakdown] = useState(false);
  const [showCultureBreakdown, setShowCultureBreakdown] = useState(false);
  const { data: permissions } = useGetCurrentUserPermissions();
  const hasManageRolesPermission = permissions?.manage_roles;

  const togglePersonalityBreakdown = () => {
    setShowPersonalityBreakdown(!showPersonalityBreakdown);
    setShowCultureBreakdown(false);
    window.scrollTo(0, 0);
  };

  const toggleCultureBreakdown = () => {
    setShowCultureBreakdown(!showCultureBreakdown);
    setShowPersonalityBreakdown(false);
    window.scrollTo(0, 0);
  };

  const { data: role, isPending } = useGetJobPostById({
    params: {
      path: {
        businessId: business?.id?.toString() ?? '',
        postId: roleId,
      },
    },
    reactQueryOptions: {
      enabled: Boolean(business?.id),
    },
  });

  if (isPending || isBusinessPending) return <FullPageLoader className="w-full" />;

  if (!role)
    return (
      <Card className="w-full">
        <CardContent>
          <CardTitle>Role not found</CardTitle>
        </CardContent>
      </Card>
    );

  const renderDetails = () => {
    if (showPersonalityBreakdown && role.personality_profile) {
      return <PersonalityMatch rolePersonalityProfile={role.personality_profile} />;
    }
    if (showCultureBreakdown && role.culture_profile) {
      return <CultureMatch roleCultureProfile={role.culture_profile} />;
    }
    return <RoleDetails role={role} />;
  };

  return (
    <main className="space-y-6">
      <div className="flex justify-between">
        <Button
          variant="tertiary"
          onClick={() => {
            if (showPersonalityBreakdown || showCultureBreakdown) {
              setShowPersonalityBreakdown(false);
              setShowCultureBreakdown(false);
            } else {
              router.history.back();
            }
          }}
        >
          <UilArrowLeft size={18} />
          Back
        </Button>
        {role.status === JOB_POST_STATUS.DRAFT && hasManageRolesPermission && (
          <Button
            variant="tertiary"
            onClick={() => {
              router.navigate({
                to: '/business/create-role',
                search: { id: role.id, mode: 'draft' },
              });
            }}
          >
            Edit draft
            <UilClipboardNotes className="size-[1.125rem]" />
          </Button>
        )}
      </div>

      <div className="flex gap-8">
        <RoleDetailsSidePanel
          role={role}
          showCultureBreakdown={showCultureBreakdown}
          showPersonalityBreakdown={showPersonalityBreakdown}
          toggleCultureBreakdown={toggleCultureBreakdown}
          togglePersonalityBreakdown={togglePersonalityBreakdown}
          className="sticky top-8 h-[calc(100vh-48px)] w-full max-w-[320px] flex-shrink-0 2xl:max-w-[380px] 3xl:max-w-[460px]"
        />
        <section className="flex-grow">{renderDetails()}</section>
      </div>
    </main>
  );
}
