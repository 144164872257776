import { useCreateRoleStore } from '@/store/create-role-store';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { SubmitHandler, useForm } from 'react-hook-form';

import { FormProvider } from '@/components/ui/form';

import CreateRoleFooter from '@/components/roles/business/create-role/create-role-footer';
import CreateRoleNavigation from '@/components/roles/business/create-role/create-role-navigation';
import Step6Fields from '@/components/roles/business/create-role/form-fields/step6-fields';
import SaveAsDraftButton from '@/components/roles/business/create-role/save-as-draft-button';
import SaveChangesButton from '@/components/roles/business/create-role/save-changes-button';

import ReactHookFormDevTools from '@/lib/react-hook-form/dev-tools';

import { step6Schema, TStep6FormData } from '@/validation-schemas/business/create-role-schema';

export const Route = createFileRoute('/_authenticated/business/create-role/step6')({
  component: Step6,
});

function Step6() {
  const { data, setStepData } = useCreateRoleStore();
  const form = useForm<TStep6FormData>({
    resolver: zodResolver(step6Schema),
    values: {
      ...data,
    },
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<TStep6FormData> = async (data) => {
    setStepData(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Step6Fields />
        <CreateRoleFooter onSubmit={form.handleSubmit(onSubmit)} />
        <SaveAsDraftButton />
        <SaveChangesButton />
      </form>
      <ReactHookFormDevTools />
      <CreateRoleNavigation saveFormData={form.handleSubmit(onSubmit)} />
    </FormProvider>
  );
}
