export const PORTAL_IDS = {
  rolesApplied: 'roles-filter-applied-portal',
  rolesButtons: 'roles-buttons-portal',
  createRole: 'create-role-portal',
  pipelineRoles: 'pipeline-roles-select-portal',
  saveAsDraft: 'save-as-draft-portal',
  pipelineRolesFilter: 'pipeline-roles-filter-portal',
  pipelineRolesAppliedFilters: 'pipeline-roles-applied-filters-portal',
  notificationsCount: 'notifications-count-portal',
  createRoleNavigation: 'create-role-navigation-portal',
  onBoardingFooter: 'onboarding-footer-portal',
};
