import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  disableUserNotificationSetting,
  enableUserNotificationSetting,
  NOTIFICATION_ENDPOINTS,
  NOTIFICATION_SETTINGS_ENDPOINTS,
  TGetAllUserNotificationSettingsResponse,
  TPutDisableUserNotificationSettingRequest,
  TPutEnableUserNotificationSettingRequest,
  TPutNotificationMarkAllAsReadRequest,
  TPutNotificationMarkAsReadRequest,
  TPutUserNotificationPlatformRequest,
  TUserNotificationSetting,
  updateAllNotificationsAsRead,
  updateNotificationAsRead,
  updateUserNotificationPlatform,
} from '@/services/notification';

import { UseMutationOptions } from '../types';

export function useUpdateUserNotificationPlatform({
  reactQueryOptions,
}: UseMutationOptions<TPutUserNotificationPlatformRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateUserNotificationPlatform,
    onSuccess: (_, variables) => {
      reactQueryOptions?.onSuccess?.();
      queryClient.setQueryData(
        [NOTIFICATION_SETTINGS_ENDPOINTS.GET_USER_NOTIFICATION_SETTINGS],
        (oldData: TGetAllUserNotificationSettingsResponse) => {
          return {
            ...oldData,
            ...variables.body,
          };
        }
      );
    },
  });
}

export function useDisableUserNotificationSetting({
  reactQueryOptions,
}: UseMutationOptions<TPutDisableUserNotificationSettingRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: disableUserNotificationSetting,
    onSuccess: (_, variables) => {
      reactQueryOptions?.onSuccess?.();
      queryClient.setQueryData(
        [NOTIFICATION_SETTINGS_ENDPOINTS.GET_USER_NOTIFICATION_SETTINGS],
        (oldData: TGetAllUserNotificationSettingsResponse) => {
          const notificationSetting = oldData.settings.find(
            (item: TUserNotificationSetting) =>
              item.notification_topic === variables.body?.notification_topic &&
              item.notification_subtopic === variables.body?.notification_subtopic
          );

          return {
            ...oldData,
            settings: oldData.settings.map((item: TUserNotificationSetting) => {
              if (item.id === notificationSetting?.id) {
                return {
                  ...item,
                  enabled: false,
                };
              }
              return item;
            }),
          };
        }
      );
    },
  });
}

export function useEnableUserNotificationSetting({
  reactQueryOptions,
}: UseMutationOptions<TPutEnableUserNotificationSettingRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: enableUserNotificationSetting,
    onSuccess: (_, variables) => {
      reactQueryOptions?.onSuccess?.();
      queryClient.setQueryData(
        [NOTIFICATION_SETTINGS_ENDPOINTS.GET_USER_NOTIFICATION_SETTINGS],
        (oldData: TGetAllUserNotificationSettingsResponse) => {
          const notificationSetting = oldData.settings.find(
            (item: TUserNotificationSetting) =>
              item.notification_topic === variables.body?.notification_topic &&
              item.notification_subtopic === variables.body?.notification_subtopic
          );

          return {
            ...oldData,
            settings: oldData.settings.map((item: TUserNotificationSetting) => {
              if (item.id === notificationSetting?.id) {
                return {
                  ...item,
                  enabled: true,
                };
              }
              return item;
            }),
          };
        }
      );
    },
  });
}

export function useMarkAllNotificationsAsRead({
  reactQueryOptions,
}: UseMutationOptions<TPutNotificationMarkAllAsReadRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateAllNotificationsAsRead,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [NOTIFICATION_ENDPOINTS.GET_USER_NOTIFICATIONS],
        exact: false,
        refetchType: 'all',
      });
    },
  });
}

export function useMarkNotificationAsRead({
  reactQueryOptions,
}: UseMutationOptions<TPutNotificationMarkAsReadRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateNotificationAsRead,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [NOTIFICATION_ENDPOINTS.GET_USER_NOTIFICATIONS],
        exact: false,
        refetchType: 'all',
      });
    },
  });
}
