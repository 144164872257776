import { forwardRef, Fragment, ReactNode, useState } from 'react';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { UilAngleDown, UilTimes } from '@iconscout/react-unicons';

import useFloatingForDropdown from '@/hooks/useFloatingForDropdown';

import { cn } from '@/lib/utils';

import { Badge } from './badge';
import { Checkbox } from './checkbox';
import { Input, inputVariants } from './input';
import Spinner from './spinner';

export interface MultiSelectOption {
  label: string | ReactNode;
  value: string;
  description?: string;
}

interface MultiSelectProps {
  // Support both simple strings and complex options
  options: (string | MultiSelectOption)[];
  selected: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  error?: boolean;
  className?: string;
  isLoading?: boolean;
  maxSelections?: number;
  // Additional features
  hideSelected?: boolean;
  disableAlphabeticalSorting?: boolean;
  disabled?: boolean;
  // External query control (optional)
  onQueryChange?: (query: string) => void;
  enableInternalSearch?: boolean;
}

export const MultiSelect = forwardRef<HTMLInputElement, MultiSelectProps>(
  (
    {
      options,
      selected,
      onChange,
      placeholder = 'Select one or more',
      error,
      className,
      isLoading,
      maxSelections,
      hideSelected = false,
      disableAlphabeticalSorting = false,
      disabled,
      onQueryChange,
      enableInternalSearch = true,
    },
    ref
  ) => {
    const [query, setQuery] = useState('');
    const { refs, floatingStyles } = useFloatingForDropdown();
    // Normalize options to MultiSelectOption format
    const normalizedOptions = options.map((option): MultiSelectOption => {
      if (typeof option === 'string') {
        return { label: option, value: option };
      }
      return option;
    });

    const hasReachedLimit = maxSelections ? selected.length >= maxSelections : false;

    const handleSelect = (values: string[]) => {
      if (maxSelections && values.length > maxSelections) return;
      onChange(values);
    };

    const handleQueryChange = (value: string) => {
      setQuery(value);
      onQueryChange?.(value);
    };

    const deleteSelected = (value: string) => {
      const newValues = selected.filter((v) => v !== value);
      onChange(newValues);
    };

    // Filter options based on search query
    const filteredOptions =
      enableInternalSearch && query
        ? normalizedOptions.filter((option) => String(option.label).toLowerCase().includes(query.toLowerCase()))
        : normalizedOptions;

    // Sort options if needed
    const sortedOptions = disableAlphabeticalSorting
      ? filteredOptions
      : [...filteredOptions].sort((a, b) => String(a.label).localeCompare(String(b.label)));

    return (
      <div className="relative w-full">
        <Combobox
          as="div"
          value={selected}
          onChange={handleSelect}
          disabled={disabled || hasReachedLimit}
          multiple
          onClose={() => setQuery('')}
        >
          <ComboboxButton
            as="div"
            ref={refs.setReference}
            className="relative w-full [&[data-open]>div>svg]:rotate-180"
          >
            <ComboboxInput as={Fragment}>
              <Input
                ref={ref}
                className={cn(inputVariants({ variant: error ? 'error' : 'default' }), className)}
                placeholder={hasReachedLimit ? `Limit reached (max ${maxSelections})` : placeholder}
                onChange={(e) => handleQueryChange(e.target.value)}
                disabled={disabled || hasReachedLimit}
                error={error}
              />
            </ComboboxInput>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2">
              <UilAngleDown className="size-6 text-primary-dark-60 transition-transform duration-200" />
            </div>
          </ComboboxButton>

          <ComboboxOptions
            as="div"
            ref={refs.setFloating}
            style={floatingStyles}
            className="z-50 !max-h-[18.75rem] w-[var(--input-width)] overflow-y-auto rounded-lg border border-primary-dark-10 bg-white shadow-lg scrollbar"
          >
            {isLoading ? (
              <div className="flex h-[18.75rem] w-full items-center justify-center">
                <Spinner />
              </div>
            ) : sortedOptions.length === 0 ? (
              <div className="flex min-h-[18.75rem] w-full items-center justify-center">
                <span>No results found</span>
              </div>
            ) : (
              <>
                {hasReachedLimit && (
                  <div className="px-4 py-2 text-sm text-primary-dark-60">
                    Maximum {maxSelections} items can be selected
                  </div>
                )}
                {sortedOptions.map((option) => (
                  <ComboboxOption
                    key={option.value}
                    value={option.value}
                    className={cn(
                      'relative cursor-pointer select-none px-4 py-3 pl-10 font-medium text-primary-dark-60',
                      'data-[focus]:bg-primary-dark-10 data-[selected]:text-primary-dark-100 hover:bg-primary-dark-10',
                      hasReachedLimit && !selected.includes(option.value) && 'cursor-not-allowed opacity-50'
                    )}
                    disabled={hasReachedLimit && !selected.includes(option.value)}
                  >
                    <Checkbox
                      checked={selected.includes(option.value)}
                      className="absolute left-3 top-3.5 z-20"
                      size="sm"
                      tabIndex={-1}
                    />
                    <div>
                      <p className="block truncate">{option.label}</p>
                      {option.description && <p className="text-sm text-primary-dark-40">{option.description}</p>}
                    </div>
                  </ComboboxOption>
                ))}
              </>
            )}
          </ComboboxOptions>
        </Combobox>

        {selected.length > 0 && !hideSelected && (
          <div className="mt-2 flex flex-wrap gap-2">
            {selected.map((value) => {
              const option = normalizedOptions.find((o) => o.value === value);
              return (
                <Badge
                  key={value}
                  variant="success"
                  hideDot
                  onClick={() => !disabled && deleteSelected(value)}
                  className={cn('cursor-pointer', disabled && 'cursor-not-allowed opacity-50')}
                >
                  <span>{option?.label || value}</span>
                  <UilTimes className="ml-1 size-3.5 text-primary-success-80" />
                </Badge>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);

MultiSelect.displayName = 'MultiSelect';
