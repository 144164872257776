import { Logo } from '@/components/logo';

import { cn } from '@/lib/utils';

interface IProps {
  className?: string;
  children: React.ReactNode;
}
const SideBarContainer: React.FC<IProps> = ({ children, className }) => {
  return (
    <div
      className={cn(
        'sticky top-0 z-40 flex h-screen w-[280px] flex-col gap-8 border-r border-primary-dark-10 bg-white px-4 py-8 3xl:w-[314px]',
        className
      )}
      id="sidebar-container"
    >
      <Logo />
      <div className="flex-1 overflow-y-auto">{children}</div>
    </div>
  );
};

export default SideBarContainer;
