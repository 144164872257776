import { format } from 'date-fns/format';

import { TCandidateCulture } from '@/services/candidate';
import { TCandidatePrePlannedLeave } from '@/services/pipeline';

import {
  BACKGROUND_CHECK,
  BACKGROUND_CHECK_TO_DISPLAY_LABEL,
  NOTICE_PERIOD,
  NOTICE_PERIOD_TO_DISPLAY_LABEL,
  WORKING_RIGHTS,
  WORKING_RIGHTS_TO_DISPLAY_LABEL,
} from '@/constants/submit-candidate';

export const getCandidateCultureFromCultureProfile = (culture: TCandidateCulture[]) =>
  culture.reduce((highest, current) => (current.rank < highest.rank ? current : highest));

export const parseAvailabilityFromNoticePeriod = (noticePeriod: NOTICE_PERIOD) => {
  if (!noticePeriod) {
    return NOTICE_PERIOD_TO_DISPLAY_LABEL[NOTICE_PERIOD.IMMEDIATELY_AVAILABLE];
  }

  return NOTICE_PERIOD_TO_DISPLAY_LABEL[noticePeriod];
};

export const parseWorkingRightsFromWorkingRights = (workingRights: WORKING_RIGHTS | undefined | null) => {
  if (!workingRights) {
    return null;
  }

  if (workingRights === WORKING_RIGHTS.OTHER) {
    return workingRights;
  }

  return WORKING_RIGHTS_TO_DISPLAY_LABEL[workingRights];
};

export const parseBackgroundCheckFromBackgroundChecks = (backgroundChecks: BACKGROUND_CHECK | undefined | null) => {
  if (!backgroundChecks) {
    return null;
  }

  return BACKGROUND_CHECK_TO_DISPLAY_LABEL[backgroundChecks];
};

export const parsePrePlannedLeaves = (prePlannedLeaves: TCandidatePrePlannedLeave[] | undefined | null): string[] => {
  if (!prePlannedLeaves) {
    return [];
  }

  return prePlannedLeaves.map(({ start_date, end_date }) => {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    const formattedStart = format(startDate, 'do MMM yyyy'); // Example: 4th Apr 2025
    const formattedEnd = format(endDate, 'do MMM yyyy');

    return startDate.getTime() === endDate.getTime()
      ? formattedStart // Single-day leave
      : `${formattedStart} - ${formattedEnd}`; // Multi-day leave
  });
};
