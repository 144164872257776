import { paths } from '@/lib/api/v1';

export const MEETING_ENDPOINTS = {
  GET_MEETINGS: '/v2/calendar/me',
  CANCEL_MEETING: '/v2/calendar/cancel/{meetingId}',
  GET_UPCOMING_MEETINGS: '/v2/calendar/me/upcoming',
  CREATE_MEETING_DRAFT: '/v2/calendar/create/draft',
  ADD_OWNER_AVAILABILITY: '/v2/calendar/add/availabilities',
  GET_CANDIDATE_MEETING_FOR_POST: '/v2/calendar/candidate/meeting/{post_id}',
  CANDIDATE_BOOK_MEETING: '/v2/calendar/candidate/meeting/book',
  GET_MEETING_DETAILS: '/v2/calendar/meeting/{meeting_id}',
  GET_MEETING_TIME_SLOTS: '/v2/calendar/meeting/time-slots/{meeting_id}',
  RESCHEDULE_MEETING: '/v2/calendar/meeting/reschedule',
  UPDATE_MEETING_OWNER_AVAILABILITY: '/v2/calendar/update/availabilities',
  REMOVE_MEETING_FROM_USER_CALENDAR: '/v2/calendar/meeting/remove/{meeting_id}',
  OWNER_REQUEST_RESCHEDULE: '/v2/calendar/meeting/reschedule-request',
  CANDIDATE_REQUEST_NEW_TIME_SLOTS: '/v2/calendar/meeting/candidate/request-time-slots',
  CHECK_INTERVIEW_COMPLETION: '/v2/calendar/interview/stage/check-completion',
  MOVE_CANDIDATE_TO_NEXT_INTERVIEW_STAGE: '/v2/calendar/interview/stage/move',
} satisfies Record<string, keyof paths>;
