import { useCallback, useEffect, useRef, useState } from 'react';
import { UilBookmark } from '@iconscout/react-unicons';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { createPortal } from 'react-dom';

import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import Spinner from '@/components/ui/spinner';

import NoData from '@/components/no-data';
import RoleCard from '@/components/roles/role-card';
import RolesFilter from '@/components/roles/roles-filter';

import { useGetCurrentCandidateMatchesInfinite } from '@/hooks/candidate';
import useFilters from '@/hooks/useFilters';

import { TCandidatePostsFilters, TCandidatePostsSort } from '@/services/candidate';

import { sortByToQuery } from '@/utils/table-sort-mapper';

import { PORTAL_IDS } from '@/constants/portal-ids';
import { DEFAULT_PAGE_SIZE } from '@/constants/table';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/(roles-pages)/roles/')({
  component: Matched,
  validateSearch: () => ({}) as TCandidatePostsFilters,
});

const MatchesLoading = () => {
  return (
    <div className="grid grid-cols-1 gap-x-9 gap-y-8 xl:grid-cols-2 3xl:grid-cols-3">
      {new Array(6).fill(null).map((_, index) => (
        <Skeleton
          key={index}
          className="h-[300px]"
        />
      ))}
    </div>
  );
};

const PortalButtons = () => {
  const navigate = useNavigate();
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);
  const { filters, setFilters } = useFilters<TCandidatePostsFilters>(Route.id);

  useEffect(() => {
    setPortalNode(document.getElementById(PORTAL_IDS.rolesButtons));
  }, []);

  if (!portalNode) return null;

  return createPortal(
    <div className="flex items-center gap-4">
      <Button
        variant="tertiary"
        onClick={() =>
          navigate({
            to: '/candidate/saved',
          })
        }
      >
        <span>Saved</span>
        <UilBookmark className="size-[1.125rem] text-primary-dark-100" />
      </Button>
      <RolesFilter
        filters={filters}
        setFilters={setFilters}
        usePortalForAppliedFilters
      />
    </div>,
    portalNode
  );
};

const InnerMatched = () => {
  const { filters } = useFilters<TCandidatePostsFilters>(Route.id);
  const sortQuery = sortByToQuery<TCandidatePostsSort>(filters.sortBy);
  const observerTarget = useRef<HTMLDivElement>(null);

  const queryKey = [filters];

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } = useGetCurrentCandidateMatchesInfinite({
    params: {
      query: {
        limit: DEFAULT_PAGE_SIZE.toString(),
        q: filters.query,
        ...(sortQuery
          ? {
              sort: sortQuery.id,
              order: sortQuery.order,
            }
          : {
              sort: 'metadata.match_percentage',
              order: 'desc',
            }),
      },
    },
    reactQueryOptions: {
      queryKey,
    },
  });

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  useEffect(() => {
    const element = observerTarget.current;
    if (!element) return;

    // Create observer with 10% visibility threshold
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 0.1,
    });

    observer.observe(element);

    return () => observer.disconnect();
  }, [handleObserver]);

  if (isPending) return <MatchesLoading />;

  // Flatten all pages of data into a single array
  const roles = data?.pages.flatMap((page) => page?.items) ?? [];

  if (roles.length === 0)
    return (
      <NoData
        title="No new matches"
        description={
          "You don't have any new matches.\nTake a look at your profile to see if there are any changes that you could make to stand out."
        }
        isSearchResults={!!filters.query}
      />
    );

  return (
    <>
      <div className="grid grid-cols-1 gap-x-9 gap-y-8 xl:grid-cols-2 3xl:grid-cols-3">
        {roles.map(
          (role) =>
            role && (
              <RoleCard
                key={role.id}
                role={role}
                showSaveButton
                queryKey={queryKey}
              />
            )
        )}
      </div>
      <div
        ref={observerTarget}
        className="h-4"
      />
      {isFetchingNextPage && (
        <Spinner
          size="lg"
          className="mx-auto my-8"
        />
      )}
    </>
  );
};

function Matched() {
  return (
    <>
      <InnerMatched />
      <PortalButtons />
    </>
  );
}
