export enum WORKING_RIGHTS {
  AUSTRALIAN_OR_NEW_ZEALAND_CITIZEN = 'AUSTRALIAN_OR_NEW_ZEALAND_CITIZEN',
  AUSTRALIAN_PERMANENT_RESIDENT = 'AUSTRALIAN_PERMANENT_RESIDENT',
  OTHER = 'OTHER',
}

export const WORKING_RIGHTS_TO_DISPLAY_LABEL: Record<WORKING_RIGHTS, string> = {
  [WORKING_RIGHTS.AUSTRALIAN_OR_NEW_ZEALAND_CITIZEN]: 'Australian or New Zealand Citizen',
  [WORKING_RIGHTS.AUSTRALIAN_PERMANENT_RESIDENT]: 'Australian Permanent Resident',
  [WORKING_RIGHTS.OTHER]: 'Other Visa',
};

export const WORKING_RIGHTS_OPTIONS = Object.values(WORKING_RIGHTS).map((workingRight) => ({
  value: workingRight,
  label: WORKING_RIGHTS_TO_DISPLAY_LABEL[workingRight],
}));

export enum NOTICE_PERIOD {
  IMMEDIATELY_AVAILABLE = 'IMMEDIATELY_AVAILABLE',
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  THREE_WEEKS = 'THREE_WEEKS',
  FOUR_WEEKS = 'FOUR_WEEKS',
  FOUR_PLUS_WEEKS = 'FOUR_PLUS_WEEKS',
}

export const NOTICE_PERIOD_TO_DISPLAY_LABEL: Record<NOTICE_PERIOD, string> = {
  [NOTICE_PERIOD.IMMEDIATELY_AVAILABLE]: 'Immediately Available',
  [NOTICE_PERIOD.ONE_WEEK]: '1 week',
  [NOTICE_PERIOD.TWO_WEEKS]: '2 weeks',
  [NOTICE_PERIOD.THREE_WEEKS]: '3 weeks',
  [NOTICE_PERIOD.FOUR_WEEKS]: '4 weeks',
  [NOTICE_PERIOD.FOUR_PLUS_WEEKS]: '4+ weeks',
};

export const NOTICE_PERIOD_OPTIONS = Object.values(NOTICE_PERIOD).map((noticePeriod) => ({
  value: noticePeriod,
  label: NOTICE_PERIOD_TO_DISPLAY_LABEL[noticePeriod],
}));

export enum BACKGROUND_CHECK {
  INFORMED_AND_WILLING = 'INFORMED_AND_WILLING',
  NOT_INFORMED = 'NOT_INFORMED',
  INFORMED_AND_NOT_WILLING = 'INFORMED_AND_NOT_WILLING',
}

export const BACKGROUND_CHECK_TO_DESCRIPTION: Record<BACKGROUND_CHECK, string> = {
  [BACKGROUND_CHECK.INFORMED_AND_WILLING]:
    'Yes, The Applicant has been informed of the Allianz Background Check requirement and is willing to undertake this process upon successful application',
  [BACKGROUND_CHECK.NOT_INFORMED]: 'No, The Applicant has not been informed of the Background Check requirement',
  [BACKGROUND_CHECK.INFORMED_AND_NOT_WILLING]:
    'No, The Applicant has been informed of the Background Check requirement, but is NOT willing to undertake this process upon successful application',
};

export const BACKGROUND_CHECK_TO_DISPLAY_LABEL: Record<BACKGROUND_CHECK, string> = {
  [BACKGROUND_CHECK.INFORMED_AND_WILLING]: 'Yes - Informed & Willing',
  [BACKGROUND_CHECK.NOT_INFORMED]: 'No - Not Informed',
  [BACKGROUND_CHECK.INFORMED_AND_NOT_WILLING]: 'No - Informed & Unwilling',
};

export const BACKGROUND_CHECK_OPTIONS = Object.values(BACKGROUND_CHECK).map((backgroundCheck) => ({
  value: backgroundCheck,
  label: BACKGROUND_CHECK_TO_DISPLAY_LABEL[backgroundCheck],
  description: BACKGROUND_CHECK_TO_DESCRIPTION[backgroundCheck],
}));
