import { Suspense } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import { PartnerStats } from '@/components/business/partner/partner-stats';
import FormLoader from '@/components/forms/form-loader';

export const Route = createFileRoute('/_authenticated/business/_dashboard/partners/$partnerId/placements')({
  component: PartnerPlacementLayout,
});

function PartnerPlacementLayout() {
  return (
    <div>
      <Suspense fallback={<FormLoader />}>
        <PartnerStats />
        <Outlet />
      </Suspense>
    </div>
  );
}
