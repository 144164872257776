import { UilPlus } from '@iconscout/react-unicons';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Button } from '@/components/ui/button';
import DatePicker from '@/components/ui/date-picker';
import { FormControl, FormError, FormField, FormItem, FormLabel } from '@/components/ui/form';

import { TSubmitCandidateSchema } from '@/validation-schemas/partner/submit-candidate-schema';

const PrePlannedLeaveForms = () => {
  const { control, trigger } = useFormContext<TSubmitCandidateSchema>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prePlannedLeaves',
  });

  return (
    <div className="flex-1">
      <FormLabel>Pre Planned Leave</FormLabel>
      <div className="space-y-4">
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="flex items-center gap-2"
          >
            <FormField
              control={control}
              name={`prePlannedLeaves.${index}.dateRange`}
              render={({ field }) => {
                const dateRange = field.value;
                const startDate = dateRange.startDate || undefined;
                const endDate = dateRange.endDate || undefined;

                return (
                  <FormItem>
                    <FormControl>
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={new Date()}
                        onChange={(dates) => {
                          field.onChange({
                            startDate: dates[0] || undefined,
                            endDate: dates[1] || undefined,
                          });
                        }}
                        placeholderText="dd/mm/yyyy - dd/mm/yyyy"
                      />
                    </FormControl>
                    <FormError />
                  </FormItem>
                );
              }}
            />
            {fields.length > 1 && (
              <Button
                variant="link"
                onClick={() => remove(index)}
                className="self-center"
              >
                Remove
              </Button>
            )}
          </div>
        ))}
      </div>

      <Button
        variant="link"
        onClick={(e) => {
          e.preventDefault();
          append({
            dateRange: {
              startDate: undefined,
              endDate: undefined,
            },
          });
          trigger([`prePlannedLeaves.${fields.length - 1}.dateRange`]);
        }}
      >
        <UilPlus size={18} /> Add Pre Planned Leave
      </Button>
    </div>
  );
};

export default PrePlannedLeaveForms;
