import { useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';

import CultureCard from '@/components/culture-card';

import { useGetCurrentCandidate } from '@/hooks/candidate';

import { parseCandidateCulture } from '@/utils/string';

import LoadingCard from './loading-card';

const CandidateCultureCard = () => {
  const { data: candidate, isLoading } = useGetCurrentCandidate({});
  const navigate = useNavigate();

  if (isLoading) return <LoadingCard />;

  if (!candidate?.culture) return null;

  const culture = parseCandidateCulture(candidate.culture);
  if (!culture || culture.length === 0) return null;

  const highestRankCulture = culture.reduce((highest, current) => (current.rank < highest.rank ? current : highest));

  return (
    <Card className="space-y-3">
      <CardTitle>Culture</CardTitle>
      <CardContent className="space-y-4">
        <CultureCard cultureName={highestRankCulture.name} />
        <div className="flex w-full space-x-4">
          <Button
            className="flex-1"
            onClick={() => navigate({ to: '/candidate/quiz/culture/result' })}
          >
            View score
          </Button>
          <Button
            variant="tertiary"
            className="flex-1"
            onClick={() => navigate({ to: '/candidate/quiz/culture', search: { retake: true } })}
          >
            Re-take the quiz
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default CandidateCultureCard;
