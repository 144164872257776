import { TCreateJobPostRequestBody, TCultureProfile, TJobPost, TPersonalityProfile } from '@/services/business-roles';

import { getArrayFromString } from '@/utils/format';
import { convertArrayToString } from '@/utils/string';

import { INTERVIEW_PLATFORM_ID, INTERVIEW_TYPE, PAY_TYPE } from '@/constants/post';

import { TFinalFormData } from '@/validation-schemas/business/create-role-schema';

import { CultureName, PersonalityArchetypeCode } from '../candidate';

export const mapCreateRoleFormDataToAPI = (data: TFinalFormData, publishRole: boolean): TCreateJobPostRequestBody => {
  return {
    ...mapCommonRoleFormDataToAPI(data),
    should_publish: publishRole,
  };
};

export const mapCommonRoleFormDataToAPI = (data: TFinalFormData): Omit<TCreateJobPostRequestBody, 'should_publish'> => {
  const hasPreScreenInterview = data.hasPreScreenInterview;

  const stages = [
    ...(hasPreScreenInterview
      ? [
          {
            stage_name: 'Pre-screen Interview',
            stage_duration: '30',
            stage_type: INTERVIEW_TYPE.VIDEO,
            is_prescreening: true,
          },
        ]
      : []),
    ...data.stages
      .filter((stage) => stage.name && !stage.isPreScreen)
      .map((stage) => ({
        id: stage.id,
        stage_name: stage.name,
        stage_duration: stage.duration?.toString() ?? '',
        stage_type: stage.interviewType,
        is_prescreening: false,
      })),
  ];

  const personalityProfile = data.personalityTraits.map((trait, index) => ({
    rank: index + 1,
    code: trait,
  }));

  const cultureProfile = data.cultureFactors.map((factor, index) => ({
    rank: index + 1,
    name: factor,
  }));

  return {
    reference_number: data.referenceNumber,
    cover_image_url: data.coverImageUrl,
    title: data.roleTitle,
    description: data.description,
    role_type: data.roleType,
    is_hiring_multiple_candidates: data.hiringMultiple,
    workplace_type: data.workplaceType,
    city: data.location,
    country: 'Australia',
    employment_type: data.employmentType,
    pay_type: data.payType,
    pay_currency: data.payTypeCurrency,
    ...(data.payType === PAY_TYPE.DAY_RATE && {
      pay_day_rate_from: data.amountFrom,
      pay_day_rate_to: data.amountTo,
    }),
    ...(data.payType === PAY_TYPE.HOURLY_RATE && {
      pay_hourly_rate_from: data.amountFrom,
      pay_hourly_rate_to: data.amountTo,
    }),
    ...(data.payType === PAY_TYPE.ANNUAL_SALARY && {
      pay_annual_salary_from: data.amountFrom,
      pay_annual_salary_to: data.amountTo,
    }),
    start_by: data.startDate?.toISOString(),
    end_by: data.endDate?.toISOString(),
    benefits: convertArrayToString(data.benefits),
    experience: convertArrayToString(
      data.domainExperience.map((domainExperience) => `${domainExperience.domain} - ${domainExperience.experience}`)
    ),
    skills: convertArrayToString(data.technicalSkills),
    soft_skills: convertArrayToString(data.softSkills),
    personality_profile: JSON.stringify(personalityProfile),
    culture_profile: JSON.stringify(cultureProfile),
    role_owner: parseInt(data.roleOwner.id),
    members: data.members.map((member) => parseInt(member.id)),
    interview_platform: data.interviewPlatform,
    stages,
    is_references_required: data.requestReferences,
    is_quick_apply_enabled: data.enableQuickApply,
    partners: data.partners.map((partner) => ({
      partner_id: partner.id,
      partner_members: partner.members.map((member) => parseInt(member.id)),
    })),
  };
};

export const mapUpdateRoleFormDataToAPI = (data: TFinalFormData): TCreateJobPostRequestBody => {
  return {
    ...mapCommonRoleFormDataToAPI(data),
  };
};

export const mapRoleAPIDataToFormData = (data: TJobPost): TFinalFormData => {
  const amountFrom =
    data.pay_type === PAY_TYPE.DAY_RATE
      ? data.pay_day_rate_from
      : data.pay_type === PAY_TYPE.HOURLY_RATE
        ? data.pay_hourly_rate_from
        : data.pay_annual_salary_from;
  const amountTo =
    data.pay_type === PAY_TYPE.DAY_RATE
      ? data.pay_day_rate_to
      : data.pay_type === PAY_TYPE.HOURLY_RATE
        ? data.pay_hourly_rate_to
        : data.pay_annual_salary_to;
  const benefits = data.benefits ? getArrayFromString(data.benefits) : [];
  const domainExperience = data.experience
    ? getArrayFromString(data.experience).map((experience) => ({
        domain: experience.split(' - ')[0],
        experience: experience.split(' -')[1],
      }))
    : [];

  return {
    referenceNumber: data.reference_number ?? '',
    coverImageUrl: data.cover_image_url ?? '',
    roleTitle: data.title ?? '',
    description: data.description ?? '',
    roleType: data.role_type ?? '',
    hiringMultiple: data.is_hiring_multiple_candidates ?? false,
    workplaceType: data.workplace_type ?? '',
    location: data.city ?? '',
    employmentType: data.employment_type ?? '',
    payType: (data.pay_type as PAY_TYPE) || PAY_TYPE.DAY_RATE,
    amountFrom: amountFrom || 0,
    amountTo: amountTo || 0,
    payTypeCurrency: data.pay_currency ?? '',
    startDate: data.start_by ? new Date(data.start_by) : null,
    endDate: data.end_by ? new Date(data.end_by) : null,
    benefits: benefits,
    domainExperience: domainExperience,
    technicalSkills: data.skills ? getArrayFromString(data.skills) : [],
    softSkills: data.soft_skills ? getArrayFromString(data.soft_skills) : [],
    personalityTraits: data.personality_profile
      ? parseJobPostPersonalityProfile(data.personality_profile).map((trait) => trait.code)
      : [],
    cultureFactors: data.culture_profile
      ? parseJobPostCultureProfile(data.culture_profile).map((factor) => factor.name)
      : [],
    roleOwner: data.owner
      ? {
          id: data!.owner.id.toString(),
          fullName: `${data.owner!.first_name} ${data!.owner.last_name}`,
          imageUrl: data!.owner.image_url,
        }
      : {
          id: '',
          fullName: '',
          imageUrl: '',
        },
    members: data.members.map((member) => ({
      id: member.id.toString(),
      fullName: `${member.first_name} ${member.last_name}`,
      imageUrl: member.image_url,
    })),
    // interviewPlatform: (data.interview_platform as INTERVIEW_PLATFORM_ID) || INTERVIEW_PLATFORM_ID.GOOGLE_MEET,
    interviewPlatform: INTERVIEW_PLATFORM_ID.MICROSOFT_TEAMS, // hardcoded for now
    hasPreScreenInterview: data.stages.some((stage) => stage.is_prescreening),
    stages:
      data.stages.length > 0
        ? data.stages.map((stage) => ({
            name: stage.stage_name,
            id: stage.id,
            isPreScreen: stage.is_prescreening,
            interviewType: stage.stage_type,
            duration: parseInt(stage.stage_duration),
          }))
        : [
            {
              name: '',
              interviewType: '',
              duration: null,
            },
          ],
    requestReferences: data.is_references_required,
    enableQuickApply: Boolean(data.is_quick_apply_enabled),
    partners: data.partners.map((partner) => ({
      id: partner.id,
      name: partner.name,
      description: '',
      logoUrl: partner.logo_url,
      members: partner.members.map((member) => ({
        id: member.id.toString(),
        fullName: `${member.first_name} ${member.last_name}`,
        imageUrl: member.image_url,
      })),
    })),
  };
};

export function parseJobPostPersonalityProfile(input: string): TPersonalityProfile[] {
  const rawData = JSON.parse(input) as { rank: string; code: string }[];

  return rawData.map((item) => ({
    rank: parseInt(item.rank),
    code: item.code as PersonalityArchetypeCode,
  }));
}

export function parseJobPostCultureProfile(input: string): TCultureProfile[] {
  const rawData = JSON.parse(input) as { rank: string; name: string }[];

  return rawData.map((item) => ({
    rank: parseInt(item.rank),
    name: item.name as CultureName,
  }));
}
