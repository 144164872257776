import { usePipelineStore } from '@/store/pipeline-store';
import { createFileRoute } from '@tanstack/react-router';

import BusinessPipelineBoard from '@/components/pipeline/business/business-pipeline-board';
import PipelineFilter from '@/components/pipeline/business/business-pipeline-filter';
import { RoleSelectModal } from '@/components/pipeline/business/role-select-modal';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetCurrentUserPermissions } from '@/hooks/permission';
import { useGetAllCandidatesForPipelineBoard } from '@/hooks/pipeline';
import useFilters from '@/hooks/useFilters';

import { TCandidateForPipelineSort, TPipelineCandidateFilters } from '@/services/pipeline/pipeline.types';

import { sortByToQuery } from '@/utils/table-sort-mapper';

export const Route = createFileRoute('/_authenticated/business/_dashboard/pipeline/')({
  component: Pipeline,
});

function Pipeline() {
  const { data: selectedRoles, setData: setSelectedRoles } = usePipelineStore();
  const { filters, setFilters } = useFilters<TPipelineCandidateFilters>(Route.id);

  const sortQuery = sortByToQuery<TCandidateForPipelineSort>(filters.sortBy);

  const selectedRolesIds = selectedRoles.map((item) => item.id);
  const { data: business } = useGetCurrentBusiness({});
  const { data: permissions } = useGetCurrentUserPermissions();
  const { data, isFetching: isLoading } = useGetAllCandidatesForPipelineBoard({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        limit: '0',
        offset: '0',
        job_post_ids: selectedRolesIds.join(','),
        q: filters.query,
        status: filters.status,
        show_only_favorite: !!filters?.showOnlyFavorites,
        ...(sortQuery && {
          sort: sortQuery.id,
          order: sortQuery.order,
        }),
      },
    },
    reactQueryOptions: {
      queryKey: [filters, selectedRolesIds],
      enabled: !!business && selectedRolesIds.length > 0,
    },
  });

  if (selectedRoles?.length === 0 && business?.id) {
    return (
      <RoleSelectModal
        businessId={business?.id}
        onSubmit={setSelectedRoles}
      />
    );
  }

  return (
    <>
      <PipelineFilter
        filters={filters}
        setFilters={setFilters}
      />
      <BusinessPipelineBoard
        isViewOnly={!permissions?.manage_pipelines}
        className="flex-1"
        candidates={data?.items || []}
        isLoading={isLoading}
      />
    </>
  );
}
