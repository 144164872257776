import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

import { TCandidateDetails } from '@/services/pipeline';

import '@cyntler/react-doc-viewer/dist/index.css';

import { UilFileDownload } from '@iconscout/react-unicons';

import { Button } from '@/components/ui/button';
import Spinner from '@/components/ui/spinner';
import Heading from '@/components/ui/typography/heading';

import NoData from '@/components/no-data';

type TResume = {
  url: string;
  name: string;
};

interface PipelineCandidateResumeProps {
  cvUrl?: TCandidateDetails['candidate_profile']['cv_url'];
}
export default function PipelineCandidateResume({ cvUrl }: PipelineCandidateResumeProps) {
  if (!cvUrl)
    return (
      <NoData
        title="No resume found"
        description="Candidate has not uploaded their resume"
      />
    );

  const resume = JSON.parse(cvUrl) as TResume;

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = resume.url;
    link.setAttribute('download', resume.name); // Optional: specify a file name
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <main className="flex h-full flex-col gap-6">
      <Heading
        title="Resume"
        size="title"
      />
      <div>
        <Button
          variant="tertiary"
          onClick={handleDownload}
        >
          <UilFileDownload className="!text-primary-dark-100 default-icon-sm" />
          Download
        </Button>
      </div>
      <DocViewer
        documents={[
          {
            uri: resume.url,
            fileType: resume.name.split('.').pop(),
          },
        ]}
        pluginRenderers={DocViewerRenderers}
        className="flex h-full w-full max-w-full flex-1 overflow-x-hidden !bg-transparent scrollbar scrollbar-track-transparent scrollbar-thumb-primary-dark-20"
        config={{
          header: {
            disableHeader: true,
          },
          loadingRenderer: {
            overrideComponent: () => (
              <div className="flex h-full w-full items-center justify-center">
                <Spinner />
              </div>
            ),
          },
          pdfZoom: {
            defaultZoom: 1.109,
            zoomJump: 0.5,
          },
          pdfVerticalScrollByDefault: true,
        }}
      />
    </main>
  );
}
