import { createFileRoute } from '@tanstack/react-router';

import Spinner from '@/components/ui/spinner';

import PipelineCandidateResume from '@/components/pipeline/business/pipeline-candidate-resume';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetCandidateDetailsForAPost } from '@/hooks/pipeline';

import { getReferrerInformation } from '@/services/pipeline/piepline.utils';

export const Route = createFileRoute(
  '/_authenticated/business/_dashboard/pipeline-candidate-profile/$postId/$candidateId/resume'
)({
  component: Resume,
});

function Resume() {
  const { candidateId, postId } = Route.useParams();
  const { data: business } = useGetCurrentBusiness({});

  const { data, isLoading } = useGetCandidateDetailsForAPost({
    params: {
      path: { businessId: business!.id.toString(), postId: postId, userId: candidateId },
    },
    reactQueryOptions: {
      enabled: Boolean(postId && candidateId && business?.id),
    },
  });

  const referrerInformation = getReferrerInformation(data?.recruiters || []);

  const cvUrl = data?.candidate_profile?.cv_url || referrerInformation?.recruiting_candidate?.cv_url;

  if (isLoading) return <Spinner className="mx-auto" />;

  return <PipelineCandidateResume cvUrl={cvUrl} />;
}
