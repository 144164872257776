import { useState } from 'react';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/toaster';

import TimeSlotBooker from '@/components/time-slot-booker';

import { useOwnerRequestReschedule } from '@/hooks/meetings';

import { TMeetings } from '@/services/meetings';

import { TRescheduleMeetingFormData } from '@/validation-schemas/reschedule-meeting-schema';

import RescheduleForm from './reschedule-form';

const RescheduleButtonForOwner: React.FC<{ meeting: TMeetings[number]; disabled?: boolean; companyName: string }> = ({
  disabled,
  companyName,
  meeting,
}) => {
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<TRescheduleMeetingFormData>();

  const [selectedTimeSlots, setSelectedTimeSlots] = useState<Date[]>([]);

  const { mutate: ownerRequestReschedule, isPending } = useOwnerRequestReschedule({
    reactQueryOptions: {
      onSuccess: () => {
        toast.success('Availability saved successfully');
        setSelectedTimeSlots([]);
        setOpen(false);
      },
    },
  });

  const onRequestReschedule = () => {
    if (!formData || !selectedTimeSlots.length) return;
    ownerRequestReschedule({
      body: {
        meeting_id: meeting.id,
        reason: formData?.reason || '',
        description: formData?.description || '',
        dateTimes: selectedTimeSlots.map((slot) => slot.toISOString()),
        owner_id: meeting.owner_id,
      },
    });
  };

  const handleSelectSlot = (slot: Date) => {
    //If the slot is already selected, deselect it, else add it to the selected slots
    setSelectedTimeSlots((prevSlots) =>
      prevSlots.some((selectedSlot) => selectedSlot.getTime() === slot.getTime())
        ? prevSlots.filter((selectedSlot) => selectedSlot.getTime() !== slot.getTime())
        : [...prevSlots, slot]
    );
  };

  const renderContent = () => {
    if (step === 1) {
      return (
        <RescheduleForm
          onSubmit={(data) => {
            setFormData(data);
            setStep((prev) => prev + 1);
          }}
        />
      );
    }
    if (step === 2) {
      return (
        <TimeSlotBooker
          selectedSlots={selectedTimeSlots}
          onSlotSelect={handleSelectSlot}
          interval={Number(meeting.stage_duration)}
          isSettingAvailability
        />
      );
    }
    return null;
  };

  const renderDescription = () => {
    if (step === 1) {
      return 'Please tell us why you would you can’t make this interview time?';
    }
    if (step === 2) {
      return 'We understand that sometimes life gets in the way but please be cautious rescheduling more than once.';
    }
    if (step === 3) {
      return (
        <>
          <p>You have successfully rescheduled your interview with {companyName}! We wish you the best of luck.</p>
          <br />
          <p>Make sure to check your notifications for any updates!</p>
        </>
      );
    }
    return null;
  };

  const renderTitle = () => {
    if (step === 1) {
      return 'Reschedule';
    }
    if (step === 2) {
      return 'Book a new slot';
    }
    if (step === 3) {
      return 'Rescheduled';
    }
    return null;
  };

  const renderFooter = () => {
    if (step === 1) return;

    if (step === 2) {
      return (
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            onClick={onRequestReschedule}
            disabled={selectedTimeSlots.length === 0}
            loading={isPending}
          >
            Request reschedule
          </Button>
        </AlertDialogFooter>
      );
    }

    return (
      <AlertDialogFooter>
        <AlertDialogAction>Continue</AlertDialogAction>
      </AlertDialogFooter>
    );
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        setTimeout(() => setStep(1), 200);
      }}
    >
      <AlertDialogTrigger asChild>
        <Button
          variant="tertiary"
          disabled={disabled}
          className="w-full"
        >
          Reschedule
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="gap-6">
        <AlertDialogHeader>
          <AlertDialogTitle iconVariant={step > 1 ? 'success' : null}>{renderTitle()}</AlertDialogTitle>
          <AlertDialogDescription className="pb-0">{renderDescription()}</AlertDialogDescription>
        </AlertDialogHeader>
        {renderContent()}
        {renderFooter()}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RescheduleButtonForOwner;
