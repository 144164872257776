import { z } from 'zod';

import { TTeamMember } from '@/components/ui/team-member-select';

import { CultureName, PersonalityArchetypeCode } from '@/services/candidate';

import { INTERVIEW_PLATFORM_ID, PAY_TYPE } from '@/constants/post';

import { TDomainExperienceFormData } from '../coupled-fields.schema';

export const step1Schema = z.object({
  referenceNumber: z.string(),
  coverImageUrl: z.string().min(1, 'Cover Image is required'),
  roleTitle: z.string().min(1, 'Role Title is required'),
  roleType: z.string().min(1, 'Role Family is required'),
  description: z.string().min(2, 'Description is required'),
  hiringMultiple: z.boolean().optional().nullable(),
});
export type TStep1FormData = z.infer<typeof step1Schema>;

export const step2Schema = z
  .object({
    workplaceType: z.string().min(1, 'Workplace Type is required'),
    location: z.string().min(1, 'Location is required'),
    payType: z.nativeEnum(PAY_TYPE),
    employmentType: z.string().min(1, 'Role Type is required'),
    amountFrom: z
      .number()
      .nullable()
      .refine((val) => val !== null && val > 0, {
        message: 'Amount From is required',
      }),
    amountTo: z.number().nullable(),
    payTypeCurrency: z.string().min(1, 'Pay Type Currency is required'),
    startDate: z
      .date()
      .nullable()
      .refine((val) => val !== null, { message: 'Start Date is required' }),
    endDate: z.date().nullable(),
    benefits: z.array(z.string()),
  })
  .refine((data) => !(data.employmentType === 'Contracting' && !data.endDate), {
    message: 'End Date is required for Contracting roles',
    path: ['endDate'],
  })
  .refine(
    (data) =>
      !(data.startDate && data.employmentType === 'Contracting' && (!data.endDate || data.endDate <= data.startDate)),
    {
      message: 'End Date must be greater than Start Date',
      path: ['endDate'],
    }
  );

export type TStep2FormData = z.infer<typeof step2Schema>;

export const step3Schema = z.object({
  domainExperience: z.array(z.custom<TDomainExperienceFormData>()).min(1, 'Domain Experience are required'),
  technicalSkills: z
    .array(z.string())
    .min(1, 'Technical Skills are required')
    .max(10, 'Technical Skills must be less than 10'),
  softSkills: z.array(z.string()).min(1, 'Soft Skills are required').max(10, 'Soft Skills must be less than 10'),
});
export type TStep3FormData = z.infer<typeof step3Schema>;

export const step4Schema = z.object({
  personalityTraits: z.array(z.custom<PersonalityArchetypeCode>()),
});
export type TStep4FormData = z.infer<typeof step4Schema>;

export const step5Schema = z.object({
  cultureFactors: z.array(z.custom<CultureName>()),
});
export type TStep5FormData = z.infer<typeof step5Schema>;

export const step6Schema = z.object({
  roleOwner: z.custom<TTeamMember>().refine((val) => !!val.id, {
    message: 'Role Owner is required',
  }),
  members: z.array(z.custom<TTeamMember>()),
  interviewPlatform: z.nativeEnum(INTERVIEW_PLATFORM_ID),
  // interviewTemplate: z.string(),
  hasPreScreenInterview: z.boolean().optional().nullable(),
  requestReferences: z.boolean().optional().nullable(),
  stages: z
    .array(
      z.object({
        id: z.number().optional().nullable(),
        isPreScreen: z.boolean().optional().nullable(),
        name: z.string().min(1, 'Stage Name is required'),
        interviewType: z.string().min(1, 'Interview Type is required'),
        duration: z
          .number()
          .nullable()
          .refine((val) => val !== null && val > 0, {
            message: 'Duration is required',
          }),
      })
    )
    .min(1, 'Stages are required'),
});
export type TStep6FormData = z.infer<typeof step6Schema>;

export const step7Schema = z.object({
  partners: z.array(
    z.object({
      id: z.number().min(1, 'Partner ID is required'),
      name: z.string(),
      logoUrl: z.string().nullable(),
      description: z.string(),
      members: z.array(z.custom<TTeamMember>()).min(1, 'Members are required'),
    })
  ),
  enableQuickApply: z.boolean().optional(),
});
export type TStep7FormData = z.infer<typeof step7Schema>;

export const finalSchema = z
  .object({})
  .and(step1Schema)
  .and(step2Schema)
  .and(step3Schema)
  .and(step4Schema)
  .and(step5Schema)
  .and(step6Schema)
  .and(step7Schema);

export type TFinalFormData = z.input<typeof finalSchema>;
