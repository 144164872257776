import { UilExclamationCircle, UilSuitcase, UilTrashAlt, UilUserCircle } from '@iconscout/react-unicons';
import { useNavigate, useRouter } from '@tanstack/react-router';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownTriggerButton,
} from '@/components/ui/dropdown-menu';

import { useGetCurrentUserPermissions } from '@/hooks/permission';
import { useGetCurrentDashboardURL, useGetCurrentUser } from '@/hooks/user';

import { TCandidateForPipelineStatus } from '@/services/pipeline';
import { USER_TYPE } from '@/services/user';

import { ROLE_CANDIDATE_STATUS } from '@/utils/application-status';

interface IProps {
  candidateId: number;
  postId: number;
  onRejectClick: (candidateId: number) => void;
  onNextStepClick: (candidateId: number) => void;
  onShortlistClick: () => void;
  candidateStatus: TCandidateForPipelineStatus;
  onSelectPartnerClick?: (candidateId: number) => void;
  hasPartnerBeenSelectedForPipelineCandidate?: boolean;
  hasMultiplePartners?: boolean;
  hasCandidateCompletedOnboarding?: boolean;
}

const PipelineActionButton: React.FC<IProps> = ({
  candidateId,
  postId,
  onRejectClick,
  onNextStepClick,
  onSelectPartnerClick,
  hasPartnerBeenSelectedForPipelineCandidate,
  candidateStatus,
  onShortlistClick,
  hasMultiplePartners,
  hasCandidateCompletedOnboarding,
}) => {
  const navigate = useNavigate();
  const router = useRouter();
  const backRoute = router.state.location.href;
  const { data: permissions } = useGetCurrentUserPermissions();
  const { data: user } = useGetCurrentUser({});
  const dashboardUrl = useGetCurrentDashboardURL();

  const isBusinessUser = user?.user_type === USER_TYPE.BUSINESS;
  const hasManagePermission = permissions?.manage_pipelines;

  const nextStepStatuses = [
    ROLE_CANDIDATE_STATUS.SHORTLIST_CANDIDATE,
    ROLE_CANDIDATE_STATUS.INTERVIEWING,
    ROLE_CANDIDATE_STATUS.MEETING_COMPLETED,
    ROLE_CANDIDATE_STATUS.MEETING_SCHEDULED,
  ];

  const canGoToNextStep =
    nextStepStatuses.includes(candidateStatus as ROLE_CANDIDATE_STATUS) &&
    hasManagePermission &&
    hasCandidateCompletedOnboarding;
  const canReject =
    candidateStatus !== ROLE_CANDIDATE_STATUS.REJECT_CANDIDATE && candidateStatus !== ROLE_CANDIDATE_STATUS.HIRED;
  const isApplied = candidateStatus === 'APPLIED';
  const canSelectPartner = isBusinessUser && onSelectPartnerClick && isApplied && hasMultiplePartners;
  const canShortlist =
    isBusinessUser && isApplied && (hasPartnerBeenSelectedForPipelineCandidate || !hasMultiplePartners);

  const candidateDetailsRoute = hasCandidateCompletedOnboarding
    ? './pipeline-candidate-profile/$postId/$candidateId/profile'
    : './pipeline-candidate-profile/$postId/$candidateId/resume';
  const handleProfileClick = () => {
    navigate({
      from: dashboardUrl,
      to: candidateDetailsRoute,
      search: { backRoute },
      params: {
        postId: postId.toString(),
        candidateId: candidateId.toString(),
      },
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownTriggerButton />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={handleProfileClick}>
          <UilUserCircle className="default-icon-sm" /> Profile
        </DropdownMenuItem>

        {canGoToNextStep && (
          <DropdownMenuItem
            onClick={() => onNextStepClick(candidateId)}
            disabled={!hasManagePermission}
          >
            <UilSuitcase className="default-icon-sm" />
            Next Steps
          </DropdownMenuItem>
        )}

        {canSelectPartner && (
          <DropdownMenuItem
            onClick={() => onSelectPartnerClick(candidateId)}
            disabled={!hasManagePermission}
          >
            <UilExclamationCircle className="default-icon-sm" />
            {hasPartnerBeenSelectedForPipelineCandidate ? 'Change Partner' : 'Select Partner'}
          </DropdownMenuItem>
        )}

        {canShortlist && (
          <DropdownMenuItem
            onClick={onShortlistClick}
            disabled={!hasManagePermission}
          >
            <UilSuitcase className="default-icon-sm" />
            Shortlist
          </DropdownMenuItem>
        )}

        {canReject && (
          <DropdownMenuItem
            onClick={() => onRejectClick(candidateId)}
            disabled={!hasManagePermission}
          >
            <UilTrashAlt className="default-icon-sm" />
            Reject
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default PipelineActionButton;
