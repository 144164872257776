import { useState } from 'react';
import { usePipelineStore } from '@/store/pipeline-store';
import { useNavigate } from '@tanstack/react-router';
import { ColumnDef, PaginationState } from '@tanstack/react-table';

import { Button } from '@/components/ui/button';
import { DataTable } from '@/components/ui/data-table';
import { Tag } from '@/components/ui/tag';

import { ProfileBubbles } from '@/components/business/profile-bubbles';
import LocationTag from '@/components/tags/location-tag';
import { MatchStatusTag } from '@/components/tags/match-status-tag';

import { useGetCurrentDashboard } from '@/hooks/user';

import { BusinessRolesFilters, TBusinessRolesV2, TBusinessRolesV2Response } from '@/services/business-roles';
import { TCandidateJobStatus } from '@/services/candidate';

import { getEmptyBusinessRolesDescription } from '@/utils/role';
import { capitalizeFirstLetter, getFullName } from '@/utils/string';
import { sortByToState, stateToSortBy } from '@/utils/table-sort-mapper';
import { getPipelineUrl } from '@/utils/urls';

import { JOB_POST_STATUS } from '@/constants/post';

import OncoreLogo from '@/assets/brands/oncore.png';

import RoleCandidatesCountPill from '../role-candidates-count-pill';
import CloseRoleModal from './close-role-modal';
import DeleteDraftModal from './delete-draft-modal';
import { RoleTitle } from './role-title';
import RolesActionButton from './roles-action-button';
import BusinessRolesFilter from './roles-filter-business';

interface IBusinessRolesTableProps {
  data?: TBusinessRolesV2Response;
  isPending: boolean;
  filters: BusinessRolesFilters;
  setFilters: (filters: BusinessRolesFilters) => void;
  paginationState: PaginationState;
}

export default function BusinessRolesTable({
  data,
  isPending,
  setFilters,
  filters,
  paginationState,
}: IBusinessRolesTableProps) {
  const dashboard = useGetCurrentDashboard();
  const { setData: setPipelineData } = usePipelineStore();
  const navigate = useNavigate();
  const [openDeleteDraftModal, setOpenDeleteDraftModal] = useState(false);
  const [openCloseRoleModal, setOpenCloseRoleModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState<number | null>(null);

  const sortingState = sortByToState(filters.sortBy);
  const columnDefs: ColumnDef<TBusinessRolesV2[0]>[] = [
    {
      accessorKey: 'title',
      id: 'title',
      header: 'Role',
      cell: ({ getValue, row }) => {
        const shouldDisplayTailLogo = !!row.original.oncore_position_id;
        return (
          <RoleTitle
            title={getValue<string>()}
            logo={shouldDisplayTailLogo ? OncoreLogo : undefined}
          />
        );
      },
      size: 200,
    },
    {
      header: 'Status',
      accessorKey: 'status',
      id: 'status',
      enableSorting: false,
      cell: ({ getValue }) => {
        return <MatchStatusTag jobPostStatus={getValue<TCandidateJobStatus>()} />;
      },
    },
    {
      header: 'Type',
      accessorKey: 'workplace_type',
      cell: ({ getValue, row }) => {
        const workplaceType = getValue<string>();
        const isHiringMultiple = row.original.is_hiring_multiple_candidates;
        const hasWorkers = row.original.number_of_workers && row.original.number_of_workers > 0;

        return (
          <div className="flex items-center gap-2">
            <div className="min-w-[80px]">
              {workplaceType ? <Tag variant="primary">{capitalizeFirstLetter(workplaceType)}</Tag> : <span>-</span>}
            </div>

            {isHiringMultiple && hasWorkers && (
              <div className="flex items-center gap-2">
                <span className="text-primary-dark-40">•</span>
                <RoleCandidatesCountPill
                  filledCount={row.original.metadata.total_oncore_placements_sent || 0}
                  requiredCount={row.original.number_of_workers || 0}
                  variant="compact"
                  tooltipText="Hiring multiple candidates"
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'location',
      header: 'Location',
      cell: ({ row }) => {
        return (
          <>
            {(row.original.location || row.original.city) && (
              <LocationTag
                location={row.original.city || row.original.location || ''}
                useCountryCode
              />
            )}
          </>
        );
      },
    },
    {
      id: 'team',
      header: 'Team',
      cell: ({ row }) => {
        const bubbles = [
          // Owner
          ...(row.original?.owner
            ? [
                {
                  id: row.original?.owner?.id,
                  name: getFullName(row.original?.owner) || 'Role Owner',
                  image_url: row.original?.owner?.image_url,
                },
              ]
            : []),
          // Members
          ...row.original.members.map((member) => ({
            id: member?.id,
            name: getFullName(member) || 'Role Member',
            image_url: member.image_url,
          })),
          // Partners
          ...row.original.partners.map((partner) => ({
            id: partner?.id,
            name: partner.name || 'Role Partner',
            image_url: partner.logo_url,
            fallbackType: 'company',
          })),
        ];
        return (
          <div className="flex">
            <ProfileBubbles
              bubbles={bubbles}
              maxBubbles={4}
            />
          </div>
        );
      },
    },
    {
      id: 'id',
      cell: ({ row }) => {
        return (
          row.original.status === JOB_POST_STATUS.OPEN && (
            <Button
              variant="link"
              onClick={() => {
                setPipelineData([
                  {
                    id: row.original.id,
                    title: row.original.title,
                  },
                ]);
                navigate({ to: getPipelineUrl({ dashboard }) });
              }}
            >
              View pipeline
            </Button>
          )
        );
      },
    },
    {
      id: 'actions',
      size: 24,
      cell: ({ row }) => {
        return (
          <div className="flex justify-end">
            <RolesActionButton
              roleId={row.original.id!}
              oncoreTenantId={row.original.business.oncore_tenant_id}
              oncorePositionId={row.original.oncore_position_id}
              onDeleteClick={() => {
                setSelectedRoleId(row.original.id!);
                setOpenDeleteDraftModal(true);
              }}
              onCloseClick={() => {
                setSelectedRoleId(row.original.id!);
                setOpenCloseRoleModal(true);
              }}
              status={row.original.status as JOB_POST_STATUS}
              isQuickApplyEnabled={Boolean(row.original.is_quick_apply_enabled)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        columns={columnDefs}
        data={data?.items || []}
        title={'Roles'}
        isLoading={isPending}
        pagination={{
          state: paginationState,
          options: {
            rowCount: data?.total || 0,
            onPaginationChange: (pagination) => {
              setFilters(typeof pagination === 'function' ? pagination(paginationState) : pagination);
            },
          },
        }}
        search={{
          value: filters.query || '',
          onChange: (value) => {
            setFilters({ ...filters, query: value });
          },
        }}
        noData={getEmptyBusinessRolesDescription(filters.status)}
        sorting={{
          state: sortingState,
          onSortingChange: (updaterOrValue) => {
            const newSortingState =
              typeof updaterOrValue === 'function' ? updaterOrValue(sortingState) : updaterOrValue;
            return setFilters({ sortBy: stateToSortBy(newSortingState) });
          },
        }}
        headerButtons={
          <BusinessRolesFilter
            filters={filters}
            setFilters={setFilters}
          />
        }
      />
      {selectedRoleId && (
        <>
          <DeleteDraftModal
            open={openDeleteDraftModal}
            onOpenChange={setOpenDeleteDraftModal}
            roleId={selectedRoleId}
          />
          <CloseRoleModal
            roleId={selectedRoleId}
            open={openCloseRoleModal}
            onOpenChange={setOpenCloseRoleModal}
          />
        </>
      )}
    </>
  );
}
