import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { PasswordInput } from '@/components/ui/password-input';

import { Logo } from '@/components/logo';

import { useCreateCandidatePassword } from '@/hooks/candidate';

import { createPasswordSchema, TCreatePasswordFormData } from '@/validation-schemas/invitation-password-schema';

export const Route = createFileRoute('/invite-candidate/$token/')({
  component: InviteCandidatePage,
});

function InviteCandidatePage() {
  const form = useForm<TCreatePasswordFormData>({
    resolver: zodResolver(createPasswordSchema),
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });
  const { token } = Route.useParams();

  const { mutate: createPassword, isPending } = useCreateCandidatePassword(token);

  const onSubmit = (data: TCreatePasswordFormData) => {
    createPassword({
      body: {
        password: data.newPassword,
        token,
      },
    });
  };

  return (
    <main className={'flex justify-center py-8'}>
      <Card className="w-[32.75rem] justify-center p-8 pb-10 shadow-lg">
        <CardHeader>
          <div className="w-full pb-4">
            <Logo
              variant="full"
              className="mx-auto"
            />
          </div>
          <div className="flex w-full flex-col gap-4 py-4 text-center">
            <span className="text-base font-medium leading-normal text-primary-dark-60">
              Create your password to continue.
            </span>
          </div>
        </CardHeader>
        <CardContent>
          <FormProvider {...form}>
            <Form
              className="space-y-4"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <FormField
                control={form.control}
                name="newPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Create password</FormLabel>
                    <FormControl>
                      <PasswordInput
                        {...field}
                        placeholder="New password"
                        error={!!form.formState.errors?.newPassword}
                      />
                    </FormControl>
                    <FormError />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Confirm password</FormLabel>
                    <FormControl>
                      <PasswordInput
                        {...field}
                        placeholder="Re-enter new password"
                        error={!!form.formState.errors?.confirmPassword}
                      />
                    </FormControl>
                    <FormError />
                  </FormItem>
                )}
              />
              <Button
                className="w-full"
                type="submit"
                size={'sm'}
                disabled={isPending}
                loading={isPending}
                loadingText="Creating account..."
              >
                Continue
              </Button>
            </Form>
          </FormProvider>
        </CardContent>
      </Card>
    </main>
  );
}
