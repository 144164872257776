import { DevTool, PLACEMENT } from '@hookform/devtools';
import { useFormContext } from 'react-hook-form';

interface ReactHookFormDevToolsProps {
  placement?: PLACEMENT;
}

const ReactHookFormDevTools = ({ placement = 'top-left' }: ReactHookFormDevToolsProps) => {
  const { control } = useFormContext();

  if (!import.meta.env.DEV) return null;

  return (
    <DevTool
      control={control}
      placement={placement}
    />
  );
};

export default ReactHookFormDevTools;
