import { useMutation } from '@tanstack/react-query';

import {
  checkDuplicateCandidateSubmission,
  submitCandidateToRole,
  TCheckDuplicateCandidateSubmissionRequest,
  TSubmitCandidateToRoleRequest,
} from '@/services/partner';

import { UseMutationOptions } from '../types';

export function useSubmitCandidate({ reactQueryOptions }: UseMutationOptions<TSubmitCandidateToRoleRequest>) {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: submitCandidateToRole,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
  });
}

export function useCheckDuplicateCandidateSubmission({
  reactQueryOptions,
  params,
}: UseMutationOptions<TCheckDuplicateCandidateSubmissionRequest>) {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: () => checkDuplicateCandidateSubmission({ params }),
  });
}
