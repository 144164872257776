import { forwardRef } from 'react';

import { ScrollArea } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';
import Spinner from '@/components/ui/spinner';

import NoData from '@/components/no-data';

import { TUserNotification } from '@/services/notification';

import { NotificationItem } from './notification-item';

export interface INotificationList {
  isLoading: boolean;
  notifications: TUserNotification[];
  isFetchingNextPage: boolean;
}

export const NotificationList = forwardRef<HTMLDivElement, INotificationList>(
  ({ isLoading, notifications, isFetchingNextPage }, ref) => {
    const renderLoadingSkeleton = () =>
      new Array(6).fill(null).map((_, index) => (
        <Skeleton
          key={index}
          className="mx-4 h-20 rounded-md"
        />
      ));

    return (
      <ScrollArea
        className="h-full flex-grow"
        type="scroll"
      >
        <div className="flex flex-col space-y-2 py-8">
          {isLoading ? (
            renderLoadingSkeleton()
          ) : notifications.length > 0 ? (
            notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))
          ) : (
            <NoData
              title="No notifications"
              description="You have no notifications at the moment."
            />
          )}
          {isFetchingNextPage && <Spinner className="mx-auto my-4" />}
          <div ref={ref} />
        </div>
      </ScrollArea>
    );
  }
);

NotificationList.displayName = 'NotificationList';
