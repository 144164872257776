import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';

import { Button } from '@/components/ui/button';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MultiSelect } from '@/components/ui/multi-select';
import { Select } from '@/components/ui/select';
import { toast } from '@/components/ui/toaster';

import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentCandidateSuspense, useUpdateCurrentCandidate } from '@/hooks/candidate';

import { currencyList, formatCurrency, parseCurrency } from '@/utils/currency';
import { getArrayFromString, getFormattedDataForAPI } from '@/utils/format';

import { getBenefitOptions } from '@/constants/benefts';

import { expectationsFormSchema, TExpectationsFormData } from '@/validation-schemas/expectation-schema';

import FormFooter from '../form-footer';
import FormNavigationBlocker from '../form-navigation-blocker';
import { IFormProps } from '../types';

const ExpectationForm: React.FC<IFormProps> = ({ onSuccess, isOnboarding }) => {
  const { data } = useGetCurrentCandidateSuspense({});

  const form = useForm<TExpectationsFormData>({
    resolver: zodResolver(expectationsFormSchema),
    defaultValues: {
      expectation_currency: 'AUD',
      expectation_annual_salary_from: null,
      expectation_annual_salary_to: null,
      expectation_day_rate_from: 0,
      expectation_day_rate_to: 0,
      expectation_hourly_rate_from: 0,
      expectation_hourly_rate_to: 0,
      other_expectations: [],
    },
    mode: 'onChange',
  });
  const [showSalaryExpectationForm, setShowSalaryExpectationForm] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = form;

  const selectedCurrency = watch('expectation_currency');

  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useUpdateCurrentCandidate({
    reactQueryOptions: {
      onSuccess: () => {
        if (!isOnboarding) {
          toast.success('Account updated successfully!');
        }
        onSuccess?.();
      },
    },
  });

  useEffect(() => {
    if (data) {
      const initialValues: TExpectationsFormData = {
        expectation_currency: data.expectation_currency || 'AUD',
        expectation_annual_salary_from: data.expectation_annual_salary_from || null,
        expectation_annual_salary_to: data.expectation_annual_salary_to || null,
        expectation_day_rate_from: data.expectation_day_rate_from || 0,
        expectation_day_rate_to: data.expectation_day_rate_to || 0,
        expectation_hourly_rate_from: data.expectation_hourly_rate_from || 0,
        expectation_hourly_rate_to: data.expectation_hourly_rate_to || 0,
        other_expectations: getArrayFromString(data.other_expectations) || [],
      };
      if (data.expectation_annual_salary_from && data.expectation_annual_salary_to) {
        setShowSalaryExpectationForm(true);
      }
      reset(initialValues);
    }
  }, [data, reset]);

  const dayRateFrom = useWatch({ control, name: 'expectation_day_rate_from' });
  const dayRateTo = useWatch({ control, name: 'expectation_day_rate_to' });
  const hourlyRateFrom = useWatch({ control, name: 'expectation_hourly_rate_from' });
  const hourlyRateTo = useWatch({ control, name: 'expectation_hourly_rate_to' });

  const [touchedFields, setTouchedFields] = useState({
    dayRateFrom: !!data?.expectation_day_rate_from || false,
    dayRateTo: !!data?.expectation_day_rate_to || false,
    hourlyRateFrom: !!data?.expectation_hourly_rate_from || false,
    hourlyRateTo: !!data?.expectation_hourly_rate_to || false,
  });

  useEffect(() => {
    if (!touchedFields.hourlyRateFrom && dayRateFrom) {
      setValue('expectation_hourly_rate_from', dayRateFrom / 8, { shouldDirty: true });
    }
  }, [dayRateFrom, hourlyRateFrom, setValue, touchedFields]);

  useEffect(() => {
    if (!touchedFields.hourlyRateTo && dayRateTo) {
      setValue('expectation_hourly_rate_to', dayRateTo / 8, { shouldDirty: true });
    }
  }, [dayRateTo, hourlyRateTo, setValue, touchedFields]);

  useEffect(() => {
    if (!touchedFields.dayRateFrom && hourlyRateFrom) {
      setValue('expectation_day_rate_from', hourlyRateFrom * 8, { shouldDirty: true });
    }
  }, [hourlyRateFrom, dayRateFrom, setValue, touchedFields]);

  useEffect(() => {
    if (!touchedFields.dayRateTo && hourlyRateTo) {
      setValue('expectation_day_rate_to', hourlyRateTo * 8, { shouldDirty: true });
    }
  }, [hourlyRateTo, dayRateTo, setValue, touchedFields]);

  const handleFieldTouched = (field: string) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
  };

  const onSubmit: SubmitHandler<TExpectationsFormData> = (data) => {
    const { expectation_hourly_rate_from, expectation_hourly_rate_to, ...rest } = data;

    updateCandidate({
      body: {
        ...getFormattedDataForAPI(rest),
        expectation_hourly_rate_from: expectation_hourly_rate_from ? Math.round(expectation_hourly_rate_from) : null,
        expectation_hourly_rate_to: expectation_hourly_rate_to ? Math.round(expectation_hourly_rate_to) : null,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6 rounded-lg border border-primary-dark-10 bg-primary-white-100 p-6">
          <div className="inline-flex">
            <FormField
              name="expectation_currency"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Currency</FormLabel>
                  <FormControl>
                    <Select
                      className="flex"
                      options={currencyList.map((s) => ({ value: s.label, label: s.label }))}
                      selected={field.value}
                      placeholder="Currency"
                      onChange={(value) => field.onChange(value)}
                      error={!!errors?.expectation_currency?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
          </div>
          <div className="flex flex-col space-y-4">
            <FormLabel>Day rate expectation (Base):</FormLabel>
            <div className="flex space-x-8">
              <FormField
                name="expectation_day_rate_from"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>From</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="w-full"
                        placeholder="Enter amount"
                        {...field}
                        value={formatCurrency(field.value, selectedCurrency)}
                        onChange={(event) => field.onChange(parseCurrency(event.target.value))}
                        error={!!errors.expectation_day_rate_from?.message}
                        onFocus={() => handleFieldTouched('dayRateFrom')}
                      />
                    </FormControl>
                    <FormError />
                  </FormItem>
                )}
              />
              <FormField
                name="expectation_day_rate_to"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>To</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="w-full"
                        placeholder="Enter amount"
                        {...field}
                        value={formatCurrency(field.value, selectedCurrency)}
                        onChange={(event) => field.onChange(parseCurrency(event.target.value))}
                        error={!!errors.expectation_day_rate_to?.message}
                        onFocus={() => handleFieldTouched('dayRateTo')}
                      />
                    </FormControl>
                    <FormError />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <FormLabel>Hourly expectation (Base):</FormLabel>
            <div className="flex space-x-8">
              <FormField
                name="expectation_hourly_rate_from"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>From</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="w-full"
                        placeholder="Enter amount"
                        {...field}
                        value={formatCurrency(field.value, selectedCurrency)}
                        onChange={(event) => field.onChange(parseCurrency(event.target.value))}
                        error={!!errors.expectation_hourly_rate_from?.message}
                        onFocus={() => handleFieldTouched('hourlyRateFrom')}
                      />
                    </FormControl>
                    <FormError />
                  </FormItem>
                )}
              />
              <FormField
                name="expectation_hourly_rate_to"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>To</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="w-full"
                        placeholder="Enter amount"
                        {...field}
                        value={formatCurrency(field.value, selectedCurrency)}
                        onChange={(event) => field.onChange(parseCurrency(event.target.value))}
                        error={!!errors.expectation_hourly_rate_to?.message}
                        onFocus={() => handleFieldTouched('hourlyRateTo')}
                      />
                    </FormControl>
                    <FormError />
                  </FormItem>
                )}
              />
            </div>
          </div>
          {!showSalaryExpectationForm && (
            <div className="flex w-full justify-start">
              <Button
                onClick={() => setShowSalaryExpectationForm(!showSalaryExpectationForm)}
                variant="link"
                className="flex w-auto font-semibold text-primary-blue-100"
              >
                + Add salary expectations
              </Button>
            </div>
          )}
          {showSalaryExpectationForm && (
            <div className="flex flex-col space-y-4">
              <FormLabel>Annual salary expectation (Optional):</FormLabel>
              <div className="flex space-x-8">
                <FormField
                  name="expectation_annual_salary_from"
                  control={control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>From</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          className="w-full"
                          placeholder="Enter amount"
                          {...field}
                          value={formatCurrency(field.value, selectedCurrency)}
                          onChange={(event) => field.onChange(parseCurrency(event.target.value))}
                          error={!!errors.expectation_annual_salary_from?.message}
                        />
                      </FormControl>
                      <FormError />
                    </FormItem>
                  )}
                />
                <FormField
                  name="expectation_annual_salary_to"
                  control={control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>To</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          className="w-full"
                          placeholder="Enter amount"
                          {...field}
                          value={formatCurrency(field.value, selectedCurrency)}
                          onChange={(event) => field.onChange(parseCurrency(event.target.value))}
                          error={!!errors.expectation_annual_salary_to?.message}
                        />
                      </FormControl>
                      <FormError />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          )}
        </div>
        <FormField
          name="other_expectations"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Additional expectations</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value}
                  options={data?.looking_for ? getBenefitOptions(data?.looking_for) : []}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.other_expectations?.message}
                  ref={field.ref}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      {isOnboarding ? (
        <OnboardingFooter
          isLoading={isUpdatingCandidate}
          handleNext={handleSubmit(onSubmit)}
        />
      ) : (
        <>
          <FormNavigationBlocker />
          <FormFooter
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isUpdatingCandidate}
          />
        </>
      )}
    </FormProvider>
  );
};
export default ExpectationForm;
