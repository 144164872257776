import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import Avatar from '@/components/ui/avatar';
import { Card, CardDescription, CardTitle } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { FormControl, FormError, FormField, FormItem, FormLabel } from '@/components/ui/form';
import Pagination from '@/components/ui/pagination';
import Search from '@/components/ui/search';
import Separator from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { Switch } from '@/components/ui/switch';
import TeamMemberSelect from '@/components/ui/team-member-select';
import Heading from '@/components/ui/typography/heading';

import NoData from '@/components/no-data';
import SettingContainer from '@/components/settings/setting-container';

import { useGetBusinessPartners, useGetBusinessTeamMembers, useGetCurrentBusiness } from '@/hooks/business';

import { Filters } from '@/services/types';

import { DEFAULT_PAGE_INDEX } from '@/constants/table';

import { TStep7FormData } from '@/validation-schemas/business/create-role-schema';

const DEFAULT_PAGE_SIZE = 6;

type Partner = {
  id: number;
  name: string;
  logoUrl?: string;
  description: string;
};
// Extract to individual file if needed
interface PartnerSearchProps {
  selectedPartners: number[];
  onSelectedPartnersChange: (partner: Partner, select: boolean) => void;
}

const PartnerSearch = ({ selectedPartners, onSelectedPartnersChange }: PartnerSearchProps) => {
  const [filters, setFilters] = useState<Filters>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
    query: '',
  });

  const paginationState = {
    pageIndex: filters.pageIndex ?? DEFAULT_PAGE_INDEX,
    pageSize: filters.pageSize ?? DEFAULT_PAGE_SIZE,
  };
  const { data: business } = useGetCurrentBusiness({});

  const { data, isPending } = useGetBusinessPartners({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        sort: 'name',
        limit: paginationState.pageSize.toString(),
        offset: (paginationState.pageIndex * paginationState.pageSize).toString(),
        q: filters.query,
      },
    },
    reactQueryOptions: {
      enabled: !!business,
      queryKey: [filters],
    },
  });

  const renderPartners = () => {
    const dummyDescription = 'This is a short description describing the partner and what they specialize in.';
    if (isPending)
      return (
        <div className="grid grid-cols-2 gap-4">
          {new Array(6).fill(null).map((_, index) => (
            <Skeleton
              key={index}
              className="h-36 w-full"
            />
          ))}
        </div>
      );

    if (data?.items.length === 0)
      return (
        <NoData
          title="No partners found"
          description="No partners found for your search. Please try again."
          className="min-h-full"
        />
      );
    return (
      <div className="grid grid-cols-2 gap-4">
        {data?.items.map((partner) => (
          <Card
            key={partner.id}
            className="space-y-4 p-6 3xl:p-8"
          >
            <CardTitle className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-2">
                <Avatar
                  src={partner.logo_url}
                  alt={partner.name}
                  fallbackType="company"
                />
                <span className="text-sm font-semibold">{partner.name}</span>
              </div>
              <Switch
                checked={selectedPartners.includes(partner.id)}
                onCheckedChange={(checked) => {
                  onSelectedPartnersChange(
                    {
                      id: partner.id,
                      name: partner.name,
                      logoUrl: partner.logo_url || '',
                      description: dummyDescription,
                    },
                    checked
                  );
                }}
              />
            </CardTitle>
            <CardDescription className="text-sm text-primary-dark-60">{dummyDescription}</CardDescription>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-8">
      <div>
        <Search
          onChange={(value) => setFilters({ query: value, pageIndex: DEFAULT_PAGE_INDEX, pageSize: DEFAULT_PAGE_SIZE })}
          value={filters.query}
        />
      </div>
      <div className="min-h-[33.1rem] 2xl:min-h-[30rem] 3xl:min-h-[32.2rem]">{renderPartners()}</div>
      <Pagination
        state={paginationState}
        onPaginationChange={(pagination) => {
          setFilters(typeof pagination === 'function' ? pagination(paginationState) : pagination);
        }}
        rowCount={data?.total || 0}
      />
    </div>
  );
};

const PartnerCard = ({
  partner,
  onSelectedPartnersChange,
  index,
}: {
  index: number;
  partner: TStep7FormData['partners'][number];
  onSelectedPartnersChange: (index: number) => void;
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<TStep7FormData>();

  const { data: teamMembersData, isPending: isLoadingTeamMembers } = useGetBusinessTeamMembers({
    params: {
      path: {
        businessId: partner?.id.toString() ?? '',
      },
      query: {
        sort: 'last_active',
        is_email_verified: true,
        has_signed_in: true,
      },
    },
  });

  const teamMembersOptions = teamMembersData?.items?.map((member) => ({
    id: member.id.toString(),
    fullName: `${member.first_name} ${member.last_name}`,
    imageUrl: member.image_url,
  }));

  return (
    <Card className="space-y-4 p-6 3xl:p-8">
      <CardTitle className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          <Avatar
            src={partner.logoUrl}
            alt={partner.name}
            fallbackType="company"
          />
          <span className="text-sm font-semibold">{partner.name}</span>
        </div>
        <Switch
          defaultChecked
          onCheckedChange={() => {
            onSelectedPartnersChange(index);
          }}
        />
      </CardTitle>
      <CardDescription className="text-sm text-primary-dark-60">{partner.description}</CardDescription>
      <FormField
        name={`partners.${index}.members`}
        control={control}
        render={({ field }) => (
          <FormItem className="col-span-3">
            <FormLabel>Team Members</FormLabel>
            <FormControl>
              <TeamMemberSelect
                selected={field.value}
                options={teamMembersOptions ?? []}
                isLoading={isLoadingTeamMembers}
                onChange={(value) => {
                  if (Array.isArray(value)) {
                    return field.onChange(value);
                  }
                  return field.onChange([]);
                }}
                error={!!errors?.partners?.[index]?.members}
                isMulti
              />
            </FormControl>
            <FormError />
          </FormItem>
        )}
      />
    </Card>
  );
};

const Step7Fields = () => {
  const { control } = useFormContext<TStep7FormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'partners',
    keyName: '_id',
  });

  return (
    <div>
      <SettingContainer>
        <Heading
          title="Quick Apply"
          subtitle="Enable quick apply for this role."
        />
        <FormField
          control={control}
          name="enableQuickApply"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="flex h-[3.75rem] items-center rounded-lg border border-primary-dark-20 px-4">
                  <Checkbox
                    id="enableQuickApply"
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    label="Enable quick apply for this role"
                  />
                </div>
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Partner selection"
          subtitle="Select one or multiple of your preferred partner to recruit for this role."
        />

        <div>
          <div className="grid grid-cols-1 gap-4">
            {fields.map((field, index) => (
              <PartnerCard
                key={field.id}
                index={index}
                partner={field}
                onSelectedPartnersChange={remove}
              />
            ))}
          </div>
          {fields.length > 0 && <Separator />}

          <PartnerSearch
            selectedPartners={fields.map((field) => field.id)}
            onSelectedPartnersChange={(partner, select) => {
              if (select) {
                return append({
                  id: partner.id,
                  name: partner.name,
                  logoUrl: partner.logoUrl || null,
                  description: partner.description,
                  members: [],
                });
              }

              const partnerIndex = fields.findIndex((field) => field.id === partner.id);
              if (partnerIndex !== -1) {
                remove(partnerIndex);
              }
            }}
          />
        </div>
      </SettingContainer>
    </div>
  );
};

export default Step7Fields;
