import { TRecruiter } from './pipeline.types';

export const hasPartnerBeenSelectedForPipelineCandidate = (partners: TRecruiter[]) => {
  return !!partners?.some((recruiter) => recruiter.recruiting_candidate?.is_selected);
};

export const getReferrerInformation = (partners: TRecruiter[], partnerId?: number) => {
  if (!partners?.length) return null;

  // Check for selected partner
  const selectedPartner = partners.find((recruiter) => recruiter.recruiting_candidate?.is_selected);
  if (selectedPartner) return selectedPartner;

  // Check if current business is a partner
  const currentBusinessAsPartner = partnerId && partners.find((recruiter) => recruiter.id === partnerId);
  if (currentBusinessAsPartner) return currentBusinessAsPartner;

  // Default to first partner
  return partners[0];
};
