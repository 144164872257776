import { Tag } from '@/components/ui/tag';

import { useGetCurrentUser } from '@/hooks/user';

import { TCandidateForPipeline, TCandidateForPipelineStatus } from '@/services/pipeline';
import { USER_TYPE } from '@/services/user';

interface DuplicatePartnersTagProps {
  partners: TCandidateForPipeline['recruiters'];
  candidateStatus: TCandidateForPipelineStatus;
}
export const DuplicatePartnersTag: React.FC<DuplicatePartnersTagProps> = ({ partners, candidateStatus }) => {
  const { data: user } = useGetCurrentUser({});

  if (user?.user_type !== USER_TYPE.BUSINESS || candidateStatus !== 'APPLIED' || !partners || partners.length <= 1)
    return null;

  return (
    <Tag
      variant="warn"
      size="sm"
    >
      Duplicate
    </Tag>
  );
};
