import { createFileRoute, redirect, useNavigate } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';

import { ResetPasswordForm } from '@/components/forms/reset-password-form';

import useAuth from '@/hooks/useAuth';

import { verifyResetPasswordToken } from '@/services/auth/auth.services';

export const Route = createFileRoute('/reset-password/$token')({
  component: ResetPasswordPage,
  beforeLoad: async ({ params }) => {
    const token = params.token;
    try {
      const isTokenValid = await verifyResetPasswordToken({ params: { path: { token } } });
      if (!isTokenValid) {
        throw new Error('Invalid token');
      }
    } catch (error) {
      throw redirect({ to: '/reset-password/invalid-token' });
    }
  },
});

function ResetPasswordPage() {
  const { logout } = useAuth();

  const navigate = useNavigate();

  return (
    <div className={'flex justify-center py-8'}>
      <Card className="w-[28.75rem] justify-center p-8 shadow-lg">
        <ResetPasswordForm
          onSuccess={() => {
            logout();
            navigate({ to: '/login' });
          }}
        />
      </Card>
    </div>
  );
}
