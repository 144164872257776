import {
  UilBuilding,
  UilCheckCircle,
  UilLinkAlt,
  UilMapMarker,
  UilStore,
  UilSuitcase,
  UilUsdCircle,
  UilUsersAlt,
} from '@iconscout/react-unicons';

import Avatar from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import Separator from '@/components/ui/separator';
import Heading from '@/components/ui/typography/heading';
import ShowMoreParagraph from '@/components/ui/typography/show-more-paragraph';

import RichTextViewer from '@/components/rich-text/rich-text-viewer';

import { TJobPost } from '@/services/business-roles/business-roles.types';
import { TCandidateJobPost } from '@/services/candidate';
import { TPostPublic } from '@/services/post';

import { getArrayFromString } from '@/utils/format';
import { getPayRangeFromRole } from '@/utils/role';
import { capitalizeFirstLetter } from '@/utils/string';

import { JOB_STATUS_MAP, PAY_TYPE, PAY_TYPES_MAP } from '@/constants/post';

import LinkedInIcon from '@/assets/linkedin-icon.svg';
import RolesPlaceHolderImg from '@/assets/roles-placeholder.jpeg';

import RoleCandidatesCountPill from '../../role-candidates-count-pill';

const RoleDetails = ({ role }: { role: TJobPost | TCandidateJobPost | TPostPublic }) => {
  const roleTypes = getArrayFromString(role.role_type!);
  const domainExperiences = getArrayFromString(role.experience!).map((experience) => `${experience} years`);
  const technicalSkills = getArrayFromString(role.skills!);
  const softSkills = getArrayFromString(role.soft_skills!);

  const { payRange } = getPayRangeFromRole(role);
  const country = role.country || 'Australia';
  const city = role.city || role.location;
  const location = city ? `${city}, ${country}` : '-';

  return (
    <Card className="space-y-8 p-8">
      <img
        src={role.cover_image_url || RolesPlaceHolderImg}
        alt="roles placeholder image"
        className="min-h-40 w-full rounded-lg object-contain"
      />
      <section className="flex justify-between">
        <div className="flex gap-4">
          <Avatar
            fallbackType="company"
            size="2xl"
            src={role.business.logo_url}
          />
          <div className="flex flex-col gap-2">
            <span className="text-2xl font-semibold">{role.business.name}</span>
            {role.business.business_size && (
              <div className="flex items-center gap-2 text-primary-dark-40">
                <UilUsersAlt className="!text-primary-dark-40 default-icon" />
                <span className="mt-0.5 leading-none">{role.business.business_size}</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2">
          {role.business.linkedin_url && (
            <a
              href={`https://${role.business.linkedin_url}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                variant="tertiary"
                size={'icon'}
              >
                <img
                  src={LinkedInIcon}
                  alt="Linkedin Icon"
                />
              </Button>
            </a>
          )}
          {role.business.website && (
            <a
              href={`https://${role.business.website}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="tertiary">
                <UilLinkAlt className="!text-primary-dark-100 default-icon-sm" />
                Website
              </Button>
            </a>
          )}
        </div>
      </section>
      <h1 className="text-2xl font-semibold 3xl:text-3xl">{role.title}</h1>
      <section className="space-y-3 font-semibold">
        <div className="grid grid-cols-[12rem_auto] items-center rounded-lg border border-primary-dark-10 2xl:grid-cols-[14rem_auto]">
          <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
            <UilCheckCircle className="!text-primary-dark-40 default-icon" />
            <span className="text-primary-dark-40">Status</span>
          </div>
          <p className="px-4 py-3 text-primary-dark-100">{JOB_STATUS_MAP[role.status as number]}</p>
        </div>
        <div className="grid grid-cols-[12rem_auto] items-center rounded-lg border border-primary-dark-10 2xl:grid-cols-[14rem_auto]">
          <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
            <UilMapMarker className="!text-primary-dark-40 default-icon" />
            <span className="text-primary-dark-40">Location</span>
          </div>
          <p className="px-4 py-3 text-primary-dark-100">{location}</p>
        </div>
        <div className="grid grid-cols-[12rem_auto] items-center rounded-lg border border-primary-dark-10 2xl:grid-cols-[14rem_auto]">
          <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
            <UilBuilding className="!text-primary-dark-40 default-icon" />
            <span className="text-primary-dark-40">Industry</span>
          </div>
          <p className="px-4 py-3 text-primary-dark-100">{role.business.industry || '-'}</p>
        </div>
        <div className="grid grid-cols-[12rem_auto] items-center rounded-lg border border-primary-dark-10 2xl:grid-cols-[14rem_auto]">
          <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
            <UilSuitcase className="!text-primary-dark-40 default-icon" />
            <span className="text-primary-dark-40">Role</span>
          </div>
          <p className="px-4 py-3 text-primary-dark-100">{role.employment_type || '-'}</p>
        </div>
        <div className="grid grid-cols-[12rem_auto] items-center rounded-lg border border-primary-dark-10 2xl:grid-cols-[14rem_auto]">
          <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
            <UilStore className="!text-primary-dark-40 default-icon" />
            <span className="text-primary-dark-40">Workplace</span>
          </div>
          <p className="px-4 py-3 text-primary-dark-100">
            {role.workplace_type ? capitalizeFirstLetter(role.workplace_type) : '-'}
          </p>
        </div>
        <div className="grid grid-cols-[12rem_auto] items-center rounded-lg border border-primary-dark-10 2xl:grid-cols-[14rem_auto]">
          <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
            <UilUsdCircle className="!text-primary-dark-40 default-icon" />
            <span className="text-primary-dark-40">Pay</span>
          </div>
          <p className="px-4 py-3 text-primary-dark-100">
            {role.pay_type && role.pay_currency
              ? `${PAY_TYPES_MAP[role.pay_type as PAY_TYPE].name} • ${payRange}`
              : '-'}
          </p>
        </div>
        {role.is_hiring_multiple_candidates && (
          <div className="grid grid-cols-[12rem_auto] items-center rounded-lg border border-primary-dark-10 2xl:grid-cols-[14rem_auto]">
            <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
              <UilUsersAlt className="!text-primary-dark-40 default-icon" />
              <span className="text-primary-dark-40">Hiring</span>
            </div>
            <div>
              <p className="px-4 py-3 text-primary-dark-100">
                {(role as TJobPost)?.oncore_position_id && Boolean((role as TJobPost)?.number_of_workers) ? (
                  <RoleCandidatesCountPill
                    filledCount={(role as TJobPost)?.metadata?.total_oncore_placements_sent || 0}
                    requiredCount={(role as TJobPost)?.number_of_workers || 0}
                    variant="full"
                    tooltipText="Hiring multiple candidates"
                  />
                ) : (
                  'Hiring multiple candidates'
                )}
              </p>
            </div>
          </div>
        )}
      </section>
      <Separator />
      <section className="space-y-2">
        <Heading
          title="About the company"
          size="subtitle"
        />
        <div className="font-medium text-primary-dark-60">
          <ShowMoreParagraph text={role.business.bio || '-'} />
        </div>
      </section>
      {roleTypes.length > 0 && (
        <>
          <Separator />
          <section className="space-y-2">
            <Heading
              title="Role family"
              size="subtitle"
            />
            <div className="flex flex-wrap gap-2">
              {roleTypes.map((roleType) => (
                <Badge
                  key={roleType}
                  hideDot
                  variant="success"
                >
                  {roleType}
                </Badge>
              ))}
            </div>
          </section>
        </>
      )}
      <Separator />
      <section className="space-y-2">
        <Heading
          title="About the role"
          size="subtitle"
        />
        <div className="font-medium text-primary-dark-60">
          <RichTextViewer content={role.description!} />
        </div>
      </section>
      {domainExperiences.length > 0 && (
        <>
          <Separator />
          <section className="space-y-2">
            <Heading
              title="Domain Experience"
              size="subtitle"
            />
            <div className="flex flex-wrap gap-2">
              {domainExperiences.map((experience) => (
                <Badge
                  key={experience}
                  hideDot
                  variant="success"
                >
                  {experience}
                </Badge>
              ))}
            </div>
          </section>
        </>
      )}
      {technicalSkills.length > 0 && (
        <>
          <Separator />
          <section className="space-y-2">
            <Heading
              title="Technical skills"
              size="subtitle"
            />
            <div className="flex flex-wrap gap-2">
              {technicalSkills.map((skill) => (
                <Badge
                  key={skill}
                  hideDot
                  variant="success"
                >
                  {skill}
                </Badge>
              ))}
            </div>
          </section>
        </>
      )}
      {softSkills.length > 0 && (
        <>
          <Separator />
          <section className="space-y-2">
            <Heading
              title="Soft skills"
              size="subtitle"
            />
            <div className="flex flex-wrap gap-2">
              {softSkills.map((skill) => (
                <Badge
                  key={skill}
                  hideDot
                  variant="success"
                >
                  {skill}
                </Badge>
              ))}
            </div>
          </section>
        </>
      )}
    </Card>
  );
};

export default RoleDetails;
