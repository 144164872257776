import { FormControl, FormItem, FormLabel } from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';

import { cn } from '@/lib/utils';

import { INTERVIEW_PLATFORMS } from '@/constants/post';

interface InterviewPlatformRadioGroupProps {
  value?: string;
  onChange: (value: string) => void;
}
const InterviewPlatformRadioGroup = ({ value, onChange }: InterviewPlatformRadioGroupProps) => {
  return (
    <RadioGroup
      className="flex justify-start gap-4"
      value={value}
      onValueChange={onChange}
    >
      {INTERVIEW_PLATFORMS.map((platform) => (
        <FormItem key={platform.id}>
          <FormLabel
            className={cn(
              'flex h-full max-w-[19.5rem] cursor-pointer items-center justify-between gap-2 self-stretch rounded-lg border border-primary-dark-20 p-4 ring-offset-white 2xl:p-6 3xl:p-8',
              {
                'border-2 border-primary-blue-100 ring-4 ring-primary-blue-10': value === platform.id,
              },
              {
                'cursor-not-allowed opacity-50': platform.disabled,
              }
            )}
          >
            <div className="flex flex-1 items-center gap-4">
              <img
                src={platform.icon}
                alt={platform.name}
                className="size-6 3xl:size-8"
              />
              <span className="font-semibold 3xl:text-lg">{platform.name}</span>
            </div>

            <FormControl>
              <RadioGroupItem
                value={platform.id}
                disabled={platform.disabled}
              />
            </FormControl>
          </FormLabel>
        </FormItem>
      ))}
    </RadioGroup>
  );
};

export default InterviewPlatformRadioGroup;
