import client, { TRequestOptions } from '@/lib/api';

import { PIPELINE_ENDPOINTS } from './pipeline.endpoints';
import {
  TGetAllCandidatesForPipelineRequest,
  TGetCandidateDetailsRequest,
  TPostCandidateFavoriteRequest,
  TPostCandidateHireRequest,
  TPostCandidateMakeOfferRequest,
  TPostCandidateMoveToInterviewRequest,
  TPostCandidateReinstateRequest,
  TPostCandidateRejectRequest,
  TPostCandidateShortlistRequest,
  TPostCandidateUnFavoriteRequest,
  TSelectPartnerForCandidateRequest,
} from './pipeline.types';

export const getAllCandidatesForPipeline = async ({
  signal,
  params,
}: TRequestOptions<TGetAllCandidatesForPipelineRequest>) => {
  const { data } = await client.GET(PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, {
    signal,
    params,
  });

  return {
    ...data?.data,
    items: data?.data?.items.map((item) => ({ ...item, cardId: `${item.id}-${item.job_post.id}` })),
  };
};

export const postCandidateMakeOffer = async ({
  signal,
  params,
  body,
}: TRequestOptions<TPostCandidateMakeOfferRequest>) => {
  const { data } = await client.POST(PIPELINE_ENDPOINTS.POST_OFFER_CANDIDATE, {
    signal,
    params,
    body,
  });

  return data;
};

export const postCandidateReinstate = async ({ signal, params }: TRequestOptions<TPostCandidateReinstateRequest>) => {
  const { data } = await client.POST(PIPELINE_ENDPOINTS.POST_REINSTATE_CANDIDATE, {
    signal,
    params,
  });

  return data;
};

export const postCandidateShortlist = async ({ signal, params }: TRequestOptions<TPostCandidateShortlistRequest>) => {
  const { data } = await client.POST(PIPELINE_ENDPOINTS.POST_SHORTLIST_CANDIDATE, {
    signal,
    params,
  });

  return data;
};

export const postCandidateMoveToInterview = async ({
  signal,
  params,
  body,
}: TRequestOptions<TPostCandidateMoveToInterviewRequest>) => {
  const { data } = await client.POST(PIPELINE_ENDPOINTS.POST_MOVE_TO_INTERVIEW_STAGE, {
    signal,
    params,
    body,
  });

  return data;
};

export const postCandidateHire = async ({ signal, params }: TRequestOptions<TPostCandidateHireRequest>) => {
  const { data } = await client.POST(PIPELINE_ENDPOINTS.POST_HIRE_CANDIDATE, {
    signal,
    params,
  });

  return data;
};

export const postCandidateReject = async ({ signal, params, body }: TRequestOptions<TPostCandidateRejectRequest>) => {
  const { data } = await client.POST(PIPELINE_ENDPOINTS.POST_REJECT_CANDIDATE, {
    signal,
    params,
    body,
  });

  return data;
};

export const postCandidateFavorite = async ({ signal, params }: TRequestOptions<TPostCandidateFavoriteRequest>) => {
  const { data } = await client.POST(PIPELINE_ENDPOINTS.POST_FAVORITE_CANDIDATE, {
    signal,
    params,
  });

  return data;
};

export const postCandidateUnFavorite = async ({ signal, params }: TRequestOptions<TPostCandidateUnFavoriteRequest>) => {
  const { data } = await client.POST(PIPELINE_ENDPOINTS.POST_UNFAVORITE_CANDIDATE, {
    signal,
    params,
  });

  return data;
};

export const getCandidateDetailsForAPost = async ({ signal, params }: TRequestOptions<TGetCandidateDetailsRequest>) => {
  const { data } = await client.GET(PIPELINE_ENDPOINTS.GET_CANDIDATE_DETAILS, {
    signal,
    params,
  });

  return data?.data;
};

export const selectPartnerForCandidate = async ({
  signal,
  params,
}: TRequestOptions<TSelectPartnerForCandidateRequest>) => {
  const { data } = await client.POST(PIPELINE_ENDPOINTS.SELECT_PARTNER_FOR_CANDIDATE, {
    signal,
    params,
  });

  return data;
};
