import { createFileRoute } from '@tanstack/react-router';

import PipelineCandidateProfile from '@/components/pipeline/business/pipeline-candidate-profile';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetCandidateDetailsForAPost } from '@/hooks/pipeline';

export const Route = createFileRoute(
  '/_authenticated/business/_dashboard/pipeline-candidate-profile/$postId/$candidateId/profile'
)({
  component: Profile,
});

function Profile() {
  const { candidateId, postId } = Route.useParams();
  const { data: business } = useGetCurrentBusiness({});

  const { data, isLoading } = useGetCandidateDetailsForAPost({
    params: {
      path: { businessId: business!.id.toString(), postId: postId, userId: candidateId },
    },
    reactQueryOptions: {
      enabled: Boolean(postId && candidateId && business?.id),
    },
  });

  if (isLoading) return null;

  const candidateProfile = data?.candidate_profile;
  const jobPost = data?.job_post;

  if (!candidateProfile || !jobPost) return null;

  return (
    <PipelineCandidateProfile
      candidateProfile={candidateProfile}
      jobPost={jobPost}
    />
  );
}

export default Profile;
