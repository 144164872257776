import { useInfiniteQuery, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import {
  CANDIDATE_ENDPOINTS,
  getCandidateById,
  getCurrentCandidate,
  getCurrentCandidateAppliedMatches,
  getCurrentCandidateArchivedJobPosts,
  getCurrentCandidateHiredMatches,
  getCurrentCandidateMatches,
  getCurrentCandidateOffers,
  getCurrentCandidateSingleJobDetails,
  getSavedPost,
  TGetCandidateByIdRequest,
  TGetCurrentCandidateAppliedMatchesRequest,
  TGetCurrentCandidateArchivedJobPostsRequest,
  TGetCurrentCandidateHiredMatchesRequest,
  TGetCurrentCandidateMatchesRequest,
  TGetCurrentCandidateOffersRequest,
  TGetCurrentCandidateRequest,
  TGetCurrentCandidateSingleJobDetailsRequest,
  TGetSavedPostRequest,
} from '@/services/candidate';

import { DEFAULT_PAGE_SIZE } from '@/constants/table';

import { type UseQueryOptions } from '../types';

const { GET_CURRENT_CANDIDATE } = CANDIDATE_ENDPOINTS;
export function useGetCurrentCandidate({ reactQueryOptions }: UseQueryOptions<TGetCurrentCandidateRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [GET_CURRENT_CANDIDATE],
    queryFn: getCurrentCandidate,
    staleTime: Infinity,
  });
}

export function useGetCurrentCandidateSuspense({ reactQueryOptions }: UseQueryOptions<TGetCurrentCandidateRequest>) {
  return useSuspenseQuery({
    ...reactQueryOptions,
    queryKey: [GET_CURRENT_CANDIDATE],
    queryFn: getCurrentCandidate,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
}

export function useGetCurrentCandidateMatches({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentCandidateMatchesRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;
  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_MATCHES, ...queryKey],
    queryFn: ({ signal }) => getCurrentCandidateMatches({ signal, params }),
  });
}

export function useGetCurrentCandidateMatchesInfinite({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentCandidateMatchesRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;
  return useInfiniteQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_MATCHES, ...queryKey],
    queryFn: ({ pageParam = 0, signal }) =>
      getCurrentCandidateMatches({
        signal,
        params: {
          ...params,
          query: {
            ...params?.query,
            offset: ((pageParam || 0) * Number(params?.query?.limit || DEFAULT_PAGE_SIZE)).toString(),
          },
        },
      }),
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage?.items?.length) return undefined;
      return pages.length;
    },
    initialPageParam: 0,
  });
}

export function useGetCurrentCandidateSingleJobDetails({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentCandidateSingleJobDetailsRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;
  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_SINGLE_JOB_POST, ...queryKey, params.path.postId],
    queryFn: ({ signal }) => getCurrentCandidateSingleJobDetails({ signal, params }),
  });
}

export function useGetCurrentCandidateAppliedMatches({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentCandidateAppliedMatchesRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;
  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_APPLIED_MATCHES, ...queryKey],
    queryFn: ({ signal }) => getCurrentCandidateAppliedMatches({ signal, params }),
  });
}

export function useGetCurrentCandidateHiredMatches({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentCandidateHiredMatchesRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;
  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_HIRED_MATCHES, ...queryKey],
    queryFn: ({ signal }) => getCurrentCandidateHiredMatches({ signal, params }),
  });
}

export function useGetCurrentCandidateArchivedMatches({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentCandidateArchivedJobPostsRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;

  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_ARCHIVED_JOB_POST, ...queryKey],
    queryFn: ({ signal }) => getCurrentCandidateArchivedJobPosts({ signal, params }),
  });
}

export function useGetSavedPost({ reactQueryOptions = {}, params }: UseQueryOptions<TGetSavedPostRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;

  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_SAVED_POST, ...queryKey],
    queryFn: ({ signal }) => getSavedPost({ signal, params }),
  });
}

export function useGetCandidateById({ reactQueryOptions = {}, params }: UseQueryOptions<TGetCandidateByIdRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;
  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CANDIDATE_BY_ID, ...queryKey],
    queryFn: ({ signal }) => getCandidateById({ signal, params }),
  });
}

export function useGetCurrentCandidateOffers({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentCandidateOffersRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;
  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_OFFERS, ...queryKey],
    queryFn: ({ signal }) => getCurrentCandidateOffers({ signal, params }),
  });
}
