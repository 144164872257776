import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UilTimes } from '@iconscout/react-unicons';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Form, FormControl, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import Search from '@/components/ui/search';
import { Select } from '@/components/ui/select';
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/ui/sheet';

import FilterButton from '@/components/filter/filter-button';

import { TCandidateForPipelineSort, TCandidateForPipelineStatus, TPipelineCandidateFilters } from '@/services/pipeline';
import { SortParams } from '@/services/types';

import { truncateString } from '@/utils/string';
import { ORDER, sortByToQuery } from '@/utils/table-sort-mapper';

import { PORTAL_IDS } from '@/constants/portal-ids';

const formSchema = z.object({
  search: z.string().optional(),
  candidateName: z.enum(['asc', 'desc']).nullable().optional(),
  matchPercentage: z.enum(['asc', 'desc']).nullable().optional(),
  candidateAdded: z.enum(['asc', 'desc']).nullable().optional(),
  status: z.string().nullable().optional(),
  showOnlyFavorites: z.boolean().nullable().optional(),
});

type TFormData = z.infer<typeof formSchema>;

interface IPipelinesFilterFormProps {
  values: TFormData;
  onSubmit: (values: TFormData) => void;
  onClose: () => void;
  hideSearch?: boolean;
}
const statusOptions: {
  label: string;
  value: TCandidateForPipelineStatus;
}[] = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Applied',
    value: 'APPLIED',
  },
  {
    label: 'Shortlisted',
    value: 'SHORTLIST_CANDIDATE',
  },
  {
    label: 'Interviewing',
    value: 'INTERVIEWING',
  },
  {
    label: 'Rejected',
    value: 'REJECT_CANDIDATE',
  },
  {
    label: 'Offered',
    value: 'OFFER',
  },
  {
    label: 'Hired',
    value: 'HIRED',
  },
];

const PipelinesFilterForm: React.FC<IPipelinesFilterFormProps> = ({ values, onSubmit, onClose, hideSearch }) => {
  const form = useForm<TFormData>({
    resolver: zodResolver(formSchema),
    values,
  });

  const { control, setValue, handleSubmit } = form;

  return (
    <FormProvider {...form}>
      <div className="flex flex-grow flex-col justify-between">
        <Form
          className="flex-grow px-6 py-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          {!hideSearch && (
            <FormField
              control={control}
              name="search"
              render={({ field }) => (
                <Search
                  {...field}
                  debounce={0}
                  placeholder="Search candidate"
                />
              )}
            />
          )}
          <FormField
            control={form.control}
            name="candidateName"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel className="text-lg font-semibold">Candidate name</FormLabel>
                <FormControl>
                  <RadioGroup
                    defaultValue={field.value as string | undefined}
                    value={field.value as string | undefined}
                    className="flex gap-x-1"
                    onValueChange={(value: ORDER) => {
                      field.onChange(value);
                      setValue('matchPercentage', null);
                      setValue('candidateAdded', null);
                    }}
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="asc" />
                      </FormControl>
                      <FormLabel className="text-lg">A to Z</FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="desc" />
                      </FormControl>
                      <FormLabel className="text-lg">Z to A</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="matchPercentage"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel className="text-lg font-semibold">Match</FormLabel>
                <FormControl>
                  <RadioGroup
                    defaultValue={field.value as string | undefined}
                    value={field.value as string | undefined}
                    className="flex flex-col gap-y-1"
                    onValueChange={(value: ORDER) => {
                      field.onChange(value);
                      setValue('candidateName', null);
                      setValue('candidateAdded', null);
                    }}
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="desc" />
                      </FormControl>
                      <FormLabel className="text-lg">High to Low</FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="asc" />
                      </FormControl>
                      <FormLabel className="text-lg">Low to High</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="candidateAdded"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel className="text-lg font-semibold">Candidate added</FormLabel>
                <FormControl>
                  <RadioGroup
                    defaultValue={field.value as string | undefined}
                    value={field.value as string | undefined}
                    className="flex flex-col gap-y-1"
                    onValueChange={(value: ORDER) => {
                      field.onChange(value);
                      setValue('candidateName', null);
                      setValue('matchPercentage', null);
                    }}
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="desc" />
                      </FormControl>
                      <FormLabel className="text-lg">Newest to Oldest</FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="asc" />
                      </FormControl>
                      <FormLabel className="text-lg">Oldest to Newest</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="showOnlyFavorites"
            render={({ field }) => (
              <FormItem className="flex items-center">
                <FormControl>
                  <Checkbox
                    checked={!!field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabel className="!mt-0 text-lg font-semibold">Show only favorites</FormLabel>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel className="text-lg font-semibold">Status</FormLabel>
                <FormControl>
                  <Select
                    // @ts-expect-error status is a string but it has type of the status
                    options={statusOptions}
                    selected={field.value || null}
                    onChange={(value) => field.onChange(value)}
                    disableAlphabeticalSorting
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </Form>
        <SheetFooter className="flex justify-end border-t border-primary-dark-10">
          <Button
            variant="tertiary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Apply
          </Button>
        </SheetFooter>
      </div>
    </FormProvider>
  );
};

const getLabelFromFilter = (key: keyof TPipelineCandidateFilters, value: string) => {
  if (key === 'query') return truncateString(value);

  if (key === 'sortBy') {
    const sortQuery = sortByToQuery<TCandidateForPipelineSort>(value as SortParams['sortBy']);
    if (sortQuery?.id === 'name') {
      return sortQuery.order === 'asc' ? 'A to Z' : 'Z to A';
    }
    if (sortQuery?.id === 'metadata.match_percentage') {
      return sortQuery.order === 'asc' ? 'High to Low' : 'Low to High';
    }
    if (sortQuery?.id === 'bpcandidates.created_at') {
      return sortQuery.order === 'desc' ? 'Newest to Oldest' : 'Oldest to Newest';
    }
  }

  if (key === 'status') {
    return statusOptions.find((option) => option.value === value)?.label;
  }
  if (key === 'showOnlyFavorites') {
    return value && 'Show only favorites';
  }

  return null;
};
const AppliedFilters: React.FC<{
  filters: TPipelineCandidateFilters;
  setFilters: (filters: TPipelineCandidateFilters) => void;
}> = ({ filters, setFilters }) => {
  const portalNode = document.getElementById(PORTAL_IDS.pipelineRolesAppliedFilters);

  if (!portalNode) return null;

  const renderFilters = () => {
    return Object.entries(filters).map(([key, value]) => {
      if (!value) return null;

      return (
        <Button
          key={key}
          variant="tertiary"
          onClick={() => {
            setFilters({ ...filters, [key]: undefined });
          }}
        >
          <span>{getLabelFromFilter(key as keyof TPipelineCandidateFilters, value.toString())}</span>
          <UilTimes className="size-[1.125rem] text-primary-dark-100" />
        </Button>
      );
    });
  };

  return createPortal(<div className="flex flex-wrap gap-4">{renderFilters()}</div>, portalNode);
};

interface RolesFilterProps {
  usePortalForAppliedFilters?: boolean;
  hideSearch?: boolean;
  filters: TPipelineCandidateFilters;
  setFilters: (filters: TPipelineCandidateFilters) => void;
}

const getSortByValue = (values: TFormData) => {
  if (values.candidateName) {
    return `name.${values.candidateName}`;
  }
  if (values.matchPercentage) {
    return `metadata.match_percentage.${values.matchPercentage}`;
  }
  if (values.candidateAdded) {
    return `bpcandidates.created_at.${values.candidateAdded}`;
  }
  return undefined;
};

const PipelineFilter: React.FC<RolesFilterProps> = ({ filters, setFilters, hideSearch }) => {
  const [open, setOpen] = useState(false);
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalNode(document.getElementById(PORTAL_IDS.pipelineRolesFilter));
  }, []);

  if (!portalNode) return null;
  return createPortal(
    <>
      <Sheet
        open={open}
        onOpenChange={setOpen}
      >
        <FilterButton onClick={() => setOpen(true)} />
        <SheetContent className="flex flex-col gap-0">
          <SheetHeader>
            <SheetTitle>Filter</SheetTitle>
          </SheetHeader>
          <PipelinesFilterForm
            values={{
              search: filters.query,
              candidateName: filters.sortBy?.startsWith('name') ? sortByToQuery(filters.sortBy)!.order : null,
              matchPercentage: filters.sortBy?.startsWith('metadata.match_percentage')
                ? sortByToQuery(filters.sortBy)!.order
                : null,
              status: filters?.status || null,
              showOnlyFavorites: !!filters?.showOnlyFavorites,
              candidateAdded: filters.sortBy?.startsWith('bpcandidates.created_at')
                ? sortByToQuery(filters.sortBy)!.order
                : null,
            }}
            onSubmit={(values) => {
              const sortBy = getSortByValue(values);
              setFilters({
                query: values.search,
                sortBy: sortBy ? (sortBy as SortParams['sortBy']) : undefined,
                status: (values.status || null) as TCandidateForPipelineStatus,
                showOnlyFavorites: !!values.showOnlyFavorites,
              });
              setOpen(false);
            }}
            onClose={() => setOpen(false)}
            hideSearch={hideSearch}
          />
        </SheetContent>
      </Sheet>
      <AppliedFilters
        filters={filters}
        setFilters={setFilters}
      />
    </>,
    portalNode
  );
};

export default PipelineFilter;
