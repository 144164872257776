import { Tag, TDotVariant } from '@/components/ui/tag';

import { TCandidateJobStatus, TCandidateMatchStatus } from '@/services/candidate';

export const jobStatusToTagVariantMap: Record<NonNullable<TCandidateJobStatus>, TDotVariant> = {
  1: 'success',
  2: 'alert',
  3: 'warn',
  4: 'warn',
  5: 'default',
};

export const jobStatusToLabelMap: Record<NonNullable<TCandidateJobStatus>, string> = {
  1: 'Open',
  2: 'Deleted',
  3: 'Hold',
  4: 'Closed',
  5: 'Draft',
};

export const statusToTagVariantMap: Record<NonNullable<TCandidateMatchStatus>, TDotVariant> = {
  SHORTLIST_CANDIDATE: 'success',
  MEETING_COMPLETED: 'success',
  OFFER: 'success',
  MEETING_SCHEDULED: 'success',
  HIRED: 'success',
  MATCHING: 'primary',
  APPLIED: 'primary',
  INTERVIEWING: 'warn',
  REJECT_CONNECTION: 'warn',
  REJECT_CANDIDATE: 'alert',
  ACCEPT_CONNECTION: 'default',
};

export const statusToLabelMap: Record<NonNullable<TCandidateMatchStatus>, string> = {
  SHORTLIST_CANDIDATE: 'Shortlisted',
  MEETING_COMPLETED: 'Interview completed',
  OFFER: 'Offer received',
  MEETING_SCHEDULED: 'Interview',
  HIRED: 'Hired',
  APPLIED: 'Applied',
  MATCHING: 'Matching',
  INTERVIEWING: 'Interview',
  REJECT_CONNECTION: 'Declined',
  REJECT_CANDIDATE: 'Rejected',
  ACCEPT_CONNECTION: 'Accepted',
};

interface IProps {
  candidateMatchStatus?: TCandidateMatchStatus;
  jobPostStatus: TCandidateJobStatus;
}

export const MatchStatusTag = ({ candidateMatchStatus, jobPostStatus }: IProps) => {
  if (!candidateMatchStatus && !jobPostStatus) return null;

  const shouldShowJobPostTag =
    !candidateMatchStatus ||
    (jobPostStatus > 1 &&
      !['REJECT_CANDIDATE', 'REJECT_CONNECTION', 'SHORTLIST_CANDIDATE'].includes(candidateMatchStatus));

  const variant = shouldShowJobPostTag
    ? jobStatusToTagVariantMap[jobPostStatus]
    : statusToTagVariantMap[candidateMatchStatus!];

  const label = shouldShowJobPostTag ? jobStatusToLabelMap[jobPostStatus] : statusToLabelMap[candidateMatchStatus!];

  return (
    <Tag
      variant={variant}
      size="sm"
    >
      {label}
    </Tag>
  );
};
