import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';

import ComboBox from '@/components/ui/combo-box';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { MultiSelect } from '@/components/ui/multi-select';
import { Select } from '@/components/ui/select';
import { toast } from '@/components/ui/toaster';

import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentBusinessSuspense, useUpdateCurrentBusiness } from '@/hooks/business';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { getArrayFromString, getFormattedDataForAPI } from '@/utils/format';
import { getOptionsForSelectFromValuesMap } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

import {
  businessProfileDetailsSchema,
  TBusinessProfileDetailsFormData,
} from '@/validation-schemas/business/business-details';

import FormFooter from '../form-footer';
import FormNavigationBlocker from '../form-navigation-blocker';
import { IFormProps } from '../types';

const BusinessProfileDetailsForm: React.FC<IFormProps> = ({ onSuccess, isOnboarding, isDisabled }) => {
  const { data: currentBusiness } = useGetCurrentBusinessSuspense({});
  const { mutateAsync: updateBusiness, isPending: isUpdatingBusiness } = useUpdateCurrentBusiness({
    reactQueryOptions: {
      onSuccess: () => {
        if (!isOnboarding) {
          toast.success('Account updated successfully!');
        }
        onSuccess?.();
      },
    },
  });
  const { data: businessValuesList, isLoading: isBusinessValueLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.CANDIDATE_VALUES,
      },
    },
  });
  const { data: businessTypesList, isLoading: isBusinessTypeLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.BUSINESS_TYPES,
      },
    },
  });
  const { data: businessIndustryList, isLoading: isBusinessIndustryLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.INDUSTRY_TYPE,
      },
    },
  });

  const form = useForm<TBusinessProfileDetailsFormData>({
    disabled: isDisabled,
    resolver: zodResolver(businessProfileDetailsSchema),
    defaultValues: {
      business_type: currentBusiness?.business_type || '',
      industry: currentBusiness?.industry || '',
      company_values: getArrayFromString(currentBusiness?.company_values as string) || [],
    },
    values: {
      business_type: currentBusiness?.business_type || '',
      industry: currentBusiness?.industry || '',
      company_values: getArrayFromString(currentBusiness?.company_values as string) || [],
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit: SubmitHandler<TBusinessProfileDetailsFormData> = (data) => {
    const formattedData = getFormattedDataForAPI(data);
    updateBusiness({ body: formattedData });
  };
  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name="business_type"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Business Type</FormLabel>
              <FormControl>
                <Select
                  disabled={field.disabled}
                  className=""
                  options={getOptionsForSelectFromValuesMap(businessTypesList)}
                  selected={field.value as string | null}
                  placeholder="Select Option"
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.business_type?.message}
                  isLoading={isBusinessTypeLoading}
                  disableAlphabeticalSorting
                  ref={field.ref}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="industry"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Industry</FormLabel>
              <FormControl>
                <ComboBox
                  disabled={field.disabled}
                  className=""
                  placeholder="Start typing here…"
                  options={getOptionsForSelectFromValuesMap(businessIndustryList)}
                  selected={field.value ? { value: field.value, label: field.value } : null}
                  onSelect={(value) => field.onChange(value)}
                  error={!!errors?.industry?.message}
                  isLoading={isBusinessIndustryLoading}
                  ref={field.ref}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="company_values"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Values (Max 10)</FormLabel>
              <FormControl>
                <MultiSelect
                  disabled={field.disabled}
                  selected={field.value || []}
                  placeholder="Start typing or select"
                  options={getOptionsForSelectFromValuesMap(businessValuesList)}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.company_values?.message}
                  isLoading={isBusinessValueLoading}
                  maxSelections={10}
                  ref={field.ref}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>

      {isOnboarding ? (
        <OnboardingFooter
          handleNext={handleSubmit(onSubmit)}
          domain="business"
          isLoading={isUpdatingBusiness}
        />
      ) : (
        <>
          <FormNavigationBlocker />
          <FormFooter
            isDisabled={isDisabled}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isUpdatingBusiness}
          />
        </>
      )}
    </FormProvider>
  );
};
export default BusinessProfileDetailsForm;
