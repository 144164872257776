import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import {
  getAllUserNotifications,
  getAllUserNotificationSettings,
  NOTIFICATION_ENDPOINTS,
  NOTIFICATION_SETTINGS_ENDPOINTS,
  TGetAllUserNotificationSettingsRequest,
  TGetAllUserNotificationsRequest,
} from '@/services/notification';

import { DEFAULT_PAGE_SIZE } from '@/constants/table';

import { type UseQueryOptions } from '../types';

export function useGetAllUserNotifications({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetAllUserNotificationsRequest>) {
  const { queryKey = [], ...rest } = reactQueryOptions;
  return useInfiniteQuery({
    ...rest,
    queryKey: [NOTIFICATION_ENDPOINTS.GET_USER_NOTIFICATIONS, queryKey],
    queryFn: ({ pageParam = 0, signal }) =>
      getAllUserNotifications({
        signal,
        params: {
          ...params,
          query: {
            ...params?.query,
            offset: ((pageParam || 0) * Number(params?.query?.limit || DEFAULT_PAGE_SIZE)).toString(),
          },
        },
      }),
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage?.items?.length) return undefined;
      return pages.length;
    },
    initialPageParam: 0,
  });
}

export function useGetAllUserNotificationSettings({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetAllUserNotificationSettingsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [NOTIFICATION_SETTINGS_ENDPOINTS.GET_USER_NOTIFICATION_SETTINGS],
    queryFn: ({ signal }) => getAllUserNotificationSettings({ signal, params }),
  });
}
