import { useCreateRoleStore } from '@/store/create-role-store';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { FormProvider, useForm } from 'react-hook-form';

import CreateRoleFooter from '@/components/roles/business/create-role/create-role-footer';
import CreateRoleNavigation from '@/components/roles/business/create-role/create-role-navigation';
import Step2Fields from '@/components/roles/business/create-role/form-fields/step2-fields';
import SaveAsDraftButton from '@/components/roles/business/create-role/save-as-draft-button';
import SaveChangesButton from '@/components/roles/business/create-role/save-changes-button';

import ReactHookFormDevTools from '@/lib/react-hook-form/dev-tools';

import { step2Schema, TStep2FormData } from '@/validation-schemas/business/create-role-schema';

export const Route = createFileRoute('/_authenticated/business/create-role/step2')({
  component: Step2,
});

function Step2() {
  const { data, setStepData } = useCreateRoleStore();
  const form = useForm<TStep2FormData>({
    resolver: zodResolver(step2Schema),
    values: {
      ...data,
      startDate: data.startDate ? new Date(data.startDate) : null,
      endDate: data.endDate ? new Date(data.endDate) : null,
    },

    mode: 'onChange',
  });

  const onSubmit = (data: TStep2FormData) => {
    setStepData(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Step2Fields />
        <CreateRoleFooter onSubmit={form.handleSubmit(onSubmit)} />
        <SaveChangesButton />
        <SaveAsDraftButton />
      </form>
      <ReactHookFormDevTools />
      <CreateRoleNavigation saveFormData={form.handleSubmit(onSubmit)} />
    </FormProvider>
  );
}

export default Step2;
