import { useMemo } from 'react';
import { UilArrowUpRight, UilSuitcase } from '@iconscout/react-unicons';
import { Link } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';

import { useGetCurrentBusiness, useGetCurrentRoleBusiness } from '@/hooks/business';
import { useGetRolesStatsByBusinessId } from '@/hooks/business-roles';
import { useGetCurrentDashboardURL } from '@/hooks/user';

interface INewApplicantsCardProps {
  isForPartner?: boolean;
}

const NewApplicantsCard: React.FC<INewApplicantsCardProps> = ({ isForPartner }) => {
  const business = useGetCurrentRoleBusiness();
  const { data: currentBusiness } = useGetCurrentBusiness({});

  const { data: stats } = useGetRolesStatsByBusinessId({
    params: {
      path: {
        businessId: business?.id?.toString() as string,
      },
      query: {
        partnerId: isForPartner ? currentBusiness?.id?.toString() : undefined,
      },
    },
    reactQueryOptions: {
      enabled: !!business,
    },
  });

  const formattedCount = useMemo(() => stats?.total_new_applicants || 0, [stats]);
  const dashboardUrl = useGetCurrentDashboardURL();

  return (
    <Card className="flex gap-x-6">
      <div className="self-start rounded-lg border border-primary-dark-10 p-3">
        <UilSuitcase className="size-8 text-primary-dark-100" />
      </div>
      <div className="space-y-2 font-semibold">
        <h3 className="text-xl">New applicants</h3>
        <p className="text-sm text-primary-dark-40">{formattedCount} Applications</p>
        <Link
          from={dashboardUrl}
          to="./pipeline"
        >
          <Button
            variant="ghost"
            className="text-primary-blue-100"
          >
            <span>View all </span> <UilArrowUpRight className="size-[1.125rem]" />
          </Button>
        </Link>
      </div>
    </Card>
  );
};

export default NewApplicantsCard;
