import client, { publicClient, TRequestOptions } from '@/lib/api';

import { POST_ENDPOINTS } from './post.endpoints';
import {
  TApplyForAPostRequest,
  TGetCandidateForAPostRequest,
  TGetCurrentPostMatchesRequest,
  TGetPostByIdPubliclyRequest,
  TGetPostByIdRequest,
} from './post.types';

export const getPostById = async ({ signal, params }: TRequestOptions<TGetPostByIdRequest>) => {
  const { data } = await client.GET(POST_ENDPOINTS.GET_POST_BY_ID, {
    signal,
    params,
  });

  return data?.data;
};
export const getPostByIdPublicly = async ({ signal, params }: TRequestOptions<TGetPostByIdPubliclyRequest>) => {
  const { data } = await publicClient.GET(POST_ENDPOINTS.GET_POST_PUBLICLY, {
    signal,
    params,
  });

  return data?.data;
};

export const getCurrentPostMatches = async ({ signal, params }: TRequestOptions<TGetCurrentPostMatchesRequest>) => {
  const { data } = await client.GET(POST_ENDPOINTS.GET_CURRENT_POST_MATCHES, {
    signal,
    params,
  });

  return data?.data;
};

export const applyForARole = async ({ signal, params }: TRequestOptions<TApplyForAPostRequest>) => {
  await client.POST(POST_ENDPOINTS.APPLY_FOR_A_ROLE, {
    signal,
    params: {
      path: {
        id: params.path.id,
      },
      query: params.query,
    },
  });
};

export const getCandidateForAPost = async ({ signal, params }: TRequestOptions<TGetCandidateForAPostRequest>) => {
  const { data } = await client.GET(POST_ENDPOINTS.GET_CANDIDATE_FOR_A_POST, {
    signal,
    params,
  });

  return data?.data;
};
