import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { PhoneInput } from '@/components/ui/phone-input';
import { Select } from '@/components/ui/select';

import { GENDER_OPTIONS } from '@/constants/user';

import { profileDetailsSchema, TProfileDetailsFormData } from '@/validation-schemas/personal-details.schema';

import FormFooter from './form-footer';
import FormNavigationBlocker from './form-navigation-blocker';

interface IProfileDetailsFormProps {
  defaultValues: TProfileDetailsFormData;
  onSubmit: (data: TProfileDetailsFormData) => void;
  isSubmitting?: boolean;
}
const ProfileDetailsForm: React.FC<IProfileDetailsFormProps> = ({ defaultValues, onSubmit, isSubmitting }) => {
  const form = useForm<TProfileDetailsFormData>({
    resolver: zodResolver(profileDetailsSchema),
    defaultValues,
    values: defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  return (
    <FormProvider {...form}>
      <Form>
        <div className="flex items-center gap-x-8">
          <FormField
            control={control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter your first name"
                    {...field}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter your last name"
                    {...field}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter your email"
                  {...field}
                  disabled
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="primary_phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Phone Number</FormLabel>
              <FormControl>
                <PhoneInput
                  {...field}
                  error={!!errors?.primary_phone?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="pronouns"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Pronouns</FormLabel>
              <FormControl>
                <Select
                  options={GENDER_OPTIONS}
                  selected={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.pronouns?.message}
                  isLoading={false}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormNavigationBlocker />
      </Form>
      <FormFooter
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isSubmitting}
      />
    </FormProvider>
  );
};

export default ProfileDetailsForm;
