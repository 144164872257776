import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { addMinutes } from 'date-fns/addMinutes';
import { View } from 'react-big-calendar';

import Heading from '@/components/ui/typography/heading';

import BusinessCalendar from '@/components/big-calendar/business/business-calendar';
import CalendarTabNav from '@/components/big-calendar/components/calendar-tab-nav';

import { useGetMeetings } from '@/hooks/meetings';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/calendar')({
  component: BusinessCalendarPage,
});

function BusinessCalendarPage() {
  const [view, setView] = useState<View>('month');
  const { data: meetings, isLoading } = useGetMeetings({
    reactQueryOptions: {
      enabled: true,
    },
  });

  const events =
    meetings
      ?.filter((timeslot) => !!timeslot)
      ?.map((timeslot) => ({
        start: new Date(timeslot?.start_time || 0),
        end: new Date(addMinutes(new Date(timeslot?.start_time || 0), parseInt(timeslot.stage_duration)) || 0),
        title: timeslot?.stage_name || '',
        data: timeslot,
      })) || [];

  return (
    <div className="space-y-6">
      <Heading
        title="Calendar"
        size="page"
      />
      <CalendarTabNav
        view={view}
        onChangeView={setView}
      />
      <BusinessCalendar
        view={view}
        onChangeView={setView}
        events={events}
        isLoading={isLoading}
      />
    </div>
  );
}
