import { addYears } from 'date-fns/addYears';
import { useFormContext } from 'react-hook-form';

import ComboBox from '@/components/ui/combo-box';
import DatePicker from '@/components/ui/date-picker';
import { FormControl, FormError, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MultiSelect } from '@/components/ui/multi-select';
import { Select } from '@/components/ui/select';
import Separator from '@/components/ui/separator';
import Heading from '@/components/ui/typography/heading';

import SettingContainer from '@/components/settings/setting-container';

import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { currencyList, formatCurrency, parseCurrency } from '@/utils/currency';
import { getOptionsForSelectFromValuesMap } from '@/utils/string';

import { getBenefitOptions } from '@/constants/benefts';
import { VALUES_GROUP } from '@/constants/values-map';

import { TStep2FormData } from '@/validation-schemas/business/create-role-schema';

import PayTypeRadioGroup from '../pay-type-radio-group';

const Step2Fields = () => {
  const {
    control,
    formState: { errors },
    watch,
    resetField,
  } = useFormContext<TStep2FormData>();

  const { data: locationData, isLoading: isLocationLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.LOCATION,
      },
    },
  });
  const { data: lookingForData, isLoading: isLookingForLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.LOOKING_FOR,
      },
    },
  });

  const selectedCurrency = watch('payTypeCurrency');
  const selectedRoleType = watch('employmentType');

  return (
    <>
      <SettingContainer>
        <Heading
          title="Workplace type"
          subtitle="Select one option for this role."
        />
        <FormField
          name="workplaceType"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Workplace type</FormLabel>
              <FormControl>
                <Select
                  selected={field.value}
                  options={[
                    { label: 'On-site', value: 'on-site' },
                    { label: 'Remote', value: 'remote' },
                    { label: 'Hybrid', value: 'hybrid' },
                  ]}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.workplaceType}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Location"
          subtitle="Select the preferred location of this role."
        />
        <FormField
          name="location"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Location</FormLabel>
              <FormControl>
                <ComboBox
                  options={getOptionsForSelectFromValuesMap(locationData)}
                  selected={field.value ? { value: field.value, label: field.value } : null}
                  onSelect={(value) => field.onChange(value)}
                  error={!!errors?.location?.message}
                  isLoading={isLocationLoading}
                  placeholder="Start typing here…"
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Role type"
          subtitle="Select one option for this role."
        />
        <FormField
          name="employmentType"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Role type</FormLabel>
              <FormControl>
                <Select
                  selected={field.value}
                  options={getOptionsForSelectFromValuesMap(lookingForData)}
                  onChange={(value) => {
                    field.onChange(value);
                    resetField('benefits');
                  }}
                  error={!!errors?.employmentType}
                  isLoading={isLookingForLoading}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Pay type"
          subtitle="Choose how you prefer to pay for this role."
        />
        <div className="space-y-8">
          <FormField
            name="payType"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <PayTypeRadioGroup
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-[6.5rem_auto_auto] gap-4">
            <FormField
              name="payTypeCurrency"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Currency</FormLabel>
                  <FormControl>
                    <Select
                      className="flex"
                      options={currencyList.map((s) => ({ value: s.label, label: s.label }))}
                      selected={field.value}
                      placeholder="Currency"
                      onChange={(value) => field.onChange(value)}
                      error={!!errors?.payTypeCurrency?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <FormField
              name="amountFrom"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Amount from</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      className="w-full"
                      placeholder={formatCurrency(0, selectedCurrency)}
                      {...field}
                      value={formatCurrency(field.value, selectedCurrency)}
                      onChange={(event) => field.onChange(parseCurrency(event.target.value))}
                      error={!!errors.amountFrom?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <FormField
              name="amountTo"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Amount to</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      className="w-full"
                      placeholder={formatCurrency(0, selectedCurrency)}
                      {...field}
                      value={formatCurrency(field.value, selectedCurrency)}
                      onChange={(event) => field.onChange(parseCurrency(event.target.value))}
                      error={!!errors.amountTo?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
          </div>
        </div>
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Duration"
          subtitle="Choose an estimated start and end date of this role."
        />
        <div className="flex gap-4">
          <FormField
            name="startDate"
            control={control}
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Start date</FormLabel>
                <FormControl>
                  <DatePicker
                    selected={field.value}
                    onChange={field.onChange}
                    error={!!errors?.startDate?.message}
                    placeholderText="Start date"
                    showYearDropdown
                    minDate={new Date()}
                    maxDate={addYears(new Date(), 10)}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            name="endDate"
            control={control}
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>End date</FormLabel>
                <FormControl>
                  <DatePicker
                    selected={field.value}
                    onChange={field.onChange}
                    error={!!errors?.endDate?.message}
                    placeholderText="End date"
                    showYearDropdown
                    maxDate={addYears(new Date(), 10)}
                    minDate={new Date()}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
        </div>
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Benefits"
          subtitle="Select one or more benefits for this role."
        />
        <FormField
          name="benefits"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Benefits</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value || []}
                  options={getBenefitOptions(selectedRoleType)}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.benefits}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
    </>
  );
};

export default Step2Fields;
