import { useCallback, useMemo, useState } from 'react';
import { usePipelineStore } from '@/store/pipeline-store';
import {
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  pointerWithin,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useQueryClient } from '@tanstack/react-query';
import { createPortal } from 'react-dom';

import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';
import { toast } from '@/components/ui/toaster';

import { useGetCurrentBusiness } from '@/hooks/business';
import { usePipelineActions } from '@/hooks/pipeline/pipeline.async';
import useFilters from '@/hooks/useFilters';
import { useGetCurrentUser } from '@/hooks/user';

// import { toast } from '@/components/ui/toaster';

import { hasCandidateCompletedOnboarding, TCandidateMatchStatus } from '@/services/candidate';
import {
  PIPELINE_ENDPOINTS,
  TCandidateForPipelineBoard,
  TGetAllCandidatesForPipelineResponseForBoard,
  TPipelineCandidateFilters,
} from '@/services/pipeline';
import { hasPartnerBeenSelectedForPipelineCandidate } from '@/services/pipeline/piepline.utils';
import { USER_TYPE } from '@/services/user';

import { ROLE_CANDIDATE_STATUS } from '@/utils/application-status';

import { cn } from '@/lib/utils';

import { MEETING_STATUS } from '@/constants/meeting';

import CandidateCard from './candidate-card';
import NextStepModal, { TNextStepModalStatus } from './next-step-modal';
import OfferSentModal from './offer-sent-modal';
import RejectCandidateModal, { TRejectCandidateForm } from './reject-candidate-modal';
import SelectPartnerModal from './select-partner-modal';
import StatusColumn from './status-columns';
import { DndElementType, TDndData } from './types';
import { columns } from './utils';

interface IProps {
  isViewOnly?: boolean;
  className?: string;
  candidates: TCandidateForPipelineBoard[];
  isLoading?: boolean;
}

const BusinessPipelineBoard: React.FC<IProps> = ({ className, candidates, isLoading, isViewOnly }) => {
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openNextStepModal, setOpenNextStepModal] = useState(false);
  const [nextStepModalStatus, setNextStepModalStatus] = useState<TNextStepModalStatus | undefined>(undefined);
  const [openOfferSentModal, setOpenOfferSentModal] = useState(false);
  const [openSelectPartnerModal, setOpenSelectPartnerModal] = useState(false);

  const [pendingDragEndEvent, setPendingDragEndEvent] = useState<DragEndEvent | null>(null);

  const [activeCandidate, setActiveCandidate] = useState<TCandidateForPipelineBoard | null>(null);
  const [originalCandidateStatus, setOriginalCandidateStatus] = useState<TCandidateMatchStatus | undefined>(undefined);

  const { data: selectedRoles } = usePipelineStore();
  const selectedRolesIds = selectedRoles.map((item) => item.id);

  const { data: user } = useGetCurrentUser({});
  const filterURL =
    user?.user_type === USER_TYPE.PARTNER
      ? '/_authenticated/partner/_dashboard/pipeline'
      : '/_authenticated/business/_dashboard/pipeline';
  const { filters } = useFilters<TPipelineCandidateFilters>(filterURL);

  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, filters, selectedRolesIds],
    [filters, selectedRolesIds]
  );

  const sensors = useSensors(
    useSensor(TouchSensor, { activationConstraint: { distance: 30 } }),
    useSensor(PointerSensor, { activationConstraint: { distance: 30 } })
  );

  const { data: business } = useGetCurrentBusiness({});

  // Pipeline mutations for actions
  const { shortlist, hire, reinstate, reject } = usePipelineActions({
    queryKey,
  });

  const columnsWithData = useMemo(() => {
    return columns.map(({ label, status }) => ({
      label,
      status: status[0],
      data: candidates.filter((item) => {
        if (!item?.metadata?.candidate_status) return false;
        return status.includes(item.metadata.candidate_status);
      }),
    }));
  }, [candidates]);

  const cancelDragInCache = () => {
    queryClient.setQueryData(queryKey, (oldData: TGetAllCandidatesForPipelineResponseForBoard) => ({
      ...oldData,
      items: oldData.items.map((item) => {
        if (item.cardId === activeCandidate?.cardId) {
          return {
            ...item,
            metadata: {
              ...item.metadata,
              candidate_status: activeCandidate?.metadata?.candidate_status,
            },
          };
        }
        return item;
      }),
    }));
    setActiveCandidate(null);
  };

  const proceedDragInCache = (overStatus: TCandidateMatchStatus) => {
    queryClient.setQueryData(queryKey, (oldData: TGetAllCandidatesForPipelineResponseForBoard) => ({
      ...oldData,
      items: oldData.items.map((item) => {
        if (item.cardId === activeCandidate?.cardId) {
          return {
            ...item,
            metadata: {
              ...item.metadata,
              candidate_status: overStatus,
            },
          };
        }
        return item;
      }),
    }));

    setActiveCandidate(null);
  };

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const { id } = event.active;
      const candidate = candidates.find((item) => item.cardId === id);

      if (!candidate) return;
      setActiveCandidate(candidate);
      setOriginalCandidateStatus(candidate.metadata?.candidate_status);
    },
    [candidates]
  );

  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;
      if (!active || !over) return;

      const candidateId = active?.id as string;
      const overData = over.data.current as TDndData;

      const overStatus =
        overData?.type === DndElementType.CARD
          ? candidates.find((item) => item.cardId === over.id)?.metadata?.candidate_status
          : (over.id as TCandidateMatchStatus);
      /**
       * if candidate has a schedule meeting and overstatus is shortlisted or applied, then return
       * so no drop indicator is shown
       */
      if (
        activeCandidate?.meeting?.status === MEETING_STATUS.scheduled &&
        (overStatus === ROLE_CANDIDATE_STATUS.SHORTLIST_CANDIDATE || overStatus === ROLE_CANDIDATE_STATUS.APPLIED)
      ) {
        return;
      }

      queryClient.setQueryData(queryKey, (oldData: TGetAllCandidatesForPipelineResponseForBoard) => ({
        ...oldData,
        items: oldData.items.map((item) => {
          if (item.cardId === candidateId) {
            return {
              ...item,
              metadata: {
                ...item.metadata,
                candidate_status: overStatus,
              },
            };
          }
          return item;
        }),
      }));
    },
    [queryClient, candidates, activeCandidate, queryKey]
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { over } = event;
    if (!over) return;

    const overData = over.data.current as TDndData;

    const overStatus =
      overData?.type === DndElementType.CARD
        ? candidates.find((item) => item.cardId === over.id)?.metadata?.candidate_status
        : (over.id as TCandidateMatchStatus);

    if (originalCandidateStatus === overStatus || !overStatus) return;

    /**
     * Candidates in APPLIED status can only be moved to SHORTLIST or REJECT
     * They must be shortlisted before they can be moved to interview or offer stages
     */
    const allowedOverStatusFromApplied = [
      ROLE_CANDIDATE_STATUS.SHORTLIST_CANDIDATE,
      ROLE_CANDIDATE_STATUS.REJECT_CANDIDATE,
    ];
    if (
      originalCandidateStatus === ROLE_CANDIDATE_STATUS.APPLIED &&
      !allowedOverStatusFromApplied.includes(overStatus as ROLE_CANDIDATE_STATUS)
    ) {
      toast.error('Candidate needs to be shortlisted first');
      return cancelDragInCache();
    }

    // Prevent moving a shortlisted candidate to interview or offer stages if they haven't completed onboarding
    // This ensures candidates complete their profile before progressing beyond shortlisting
    const allowedStatusesWithoutOnboarding = [ROLE_CANDIDATE_STATUS.REJECT_CANDIDATE, ROLE_CANDIDATE_STATUS.APPLIED];
    if (
      originalCandidateStatus === ROLE_CANDIDATE_STATUS.SHORTLIST_CANDIDATE &&
      !allowedStatusesWithoutOnboarding.includes(overStatus as ROLE_CANDIDATE_STATUS) &&
      !hasCandidateCompletedOnboarding(activeCandidate?.candidate_profile)
    ) {
      toast.error('Candidate has not completed their onboarding yet');
      return cancelDragInCache();
    }

    if (
      activeCandidate?.meeting?.status === MEETING_STATUS.scheduled &&
      (overStatus === ROLE_CANDIDATE_STATUS.SHORTLIST_CANDIDATE || overStatus === ROLE_CANDIDATE_STATUS.APPLIED)
    ) {
      /**
       * if candidate has a schedule meeting and overstatus is shortlisted or applied, then return
       * to prevent candidate from being moved to shortlisted or applied status
       */
      return;
    }

    if (originalCandidateStatus && business?.id && activeCandidate) {
      if (overStatus === ROLE_CANDIDATE_STATUS.APPLIED) {
        reinstate.mutateAsync({
          params: {
            path: {
              businessId: business?.id?.toString(),
              postId: activeCandidate?.job_post.id.toString(),
              userId: activeCandidate.id.toString(),
            },
            query: {
              from_status: originalCandidateStatus,
            },
          },
        });
      }

      if (overStatus === ROLE_CANDIDATE_STATUS.SHORTLIST_CANDIDATE) {
        shortlist.mutateAsync({
          params: {
            path: {
              businessId: business?.id?.toString(),
              postId: activeCandidate?.job_post.id.toString(),
              userId: activeCandidate.id.toString(),
            },
            query: {
              from_status: originalCandidateStatus,
            },
          },
        });
      }

      if (overStatus === ROLE_CANDIDATE_STATUS.HIRED) {
        hire.mutateAsync({
          params: {
            path: {
              businessId: business?.id?.toString(),
              postId: activeCandidate?.job_post.id.toString(),
              userId: activeCandidate.id.toString(),
            },
            query: {
              from_status: originalCandidateStatus,
            },
          },
        });
      }
    }

    if (overStatus === 'INTERVIEWING') {
      setNextStepModalStatus('INTERVIEWING');
      return setOpenNextStepModal(true);
    }

    if (overStatus === 'OFFER') {
      setNextStepModalStatus('OFFER');
      return setOpenNextStepModal(true);
    }

    if (overStatus === 'REJECT_CANDIDATE') {
      return setOpenRejectModal(true);
    }

    if (overStatus) proceedDragInCache(overStatus);
  };

  const handleDragEndWithEnsuredSelectedPartner = (event: DragEndEvent) => {
    const { over } = event;
    if (!over) return;

    const overData = over.data.current as TDndData;

    const overStatus =
      overData?.type === DndElementType.CARD
        ? candidates.find((item) => item.cardId === over.id)?.metadata?.candidate_status
        : (over.id as TCandidateMatchStatus);

    if (originalCandidateStatus === overStatus) return;

    // Only proceed with partner selection if the candidate is being dragged from APPLIED status
    // and a partner hasn't been selected yet
    if (
      originalCandidateStatus === 'APPLIED' &&
      activeCandidate?.recruiters &&
      activeCandidate?.recruiters.length > 1 &&
      !hasPartnerBeenSelectedForPipelineCandidate(activeCandidate?.recruiters || [])
    ) {
      setPendingDragEndEvent(event);
      setOpenSelectPartnerModal(true);
      return;
    }

    // Otherwise, proceed with normal drag end handling
    return handleDragEnd(event);
  };

  const onSelectPartnerSuccess = () => {
    if (pendingDragEndEvent) {
      handleDragEnd(pendingDragEndEvent);
      setOpenSelectPartnerModal(false);
      setPendingDragEndEvent(null);
    }
  };

  const onSelectPartnerCancel = () => {
    cancelDragInCache();
    setPendingDragEndEvent(null);
  };

  const rejectSuccessHandler = (data: TRejectCandidateForm) => {
    if (activeCandidate && business?.id && originalCandidateStatus) {
      reject.mutateAsync(
        {
          params: {
            path: {
              businessId: business.id.toString(),
              postId: activeCandidate.job_post.id.toString(),
              userId: activeCandidate.id.toString(),
            },
            query: {
              from_status: originalCandidateStatus,
            },
          },
          body: {
            /** Send reject reason here  */
            reject_reason: data.reason,
            description: data.description,
          },
        },
        {
          onSuccess() {
            toast.success('Candidate rejected');
          },
        }
      );
    }
  };

  const renderDragOverlay = createPortal(
    <DragOverlay
      zIndex={30}
      dropAnimation={{ ...defaultDropAnimation, duration: 120, easing: 'cubic-bezier(0.32, 0, 0.67, 0)' }}
    >
      {activeCandidate ? (
        <CandidateCard
          isViewOnly={isViewOnly}
          candidate={candidates.find((candidate) => candidate.cardId === activeCandidate.cardId)!}
        />
      ) : null}
    </DragOverlay>,
    document.body
  );

  const renderColumns = () => {
    if (isLoading)
      return columns.map((column) => (
        <Skeleton
          key={column.label}
          className="h-[70dvh] w-[20.625rem]"
        />
      ));

    return columnsWithData.map(({ label, status, data }) => (
      <StatusColumn
        key={status}
        status={status}
        label={label}
        data={data}
        isViewOnly={isViewOnly}
        setOriginalCandidateStatus={setOriginalCandidateStatus}
        setActiveCandidate={setActiveCandidate}
        setOpenNextStepModal={setOpenNextStepModal} //To pass the openNextStepModal function to the candidate card
        defaultHidden={!!filters?.status && filters?.status !== status}
      />
    ));
  };

  return (
    <>
      <ScrollArea className={cn('h-full w-[calc(100vw-296px)] 3xl:w-[calc(100vw-330px)]', className)}>
        <DndContext
          sensors={sensors}
          collisionDetection={pointerWithin}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEndWithEnsuredSelectedPartner}
        >
          <div className="flex gap-4 px-8">{renderColumns()}</div>
          {renderDragOverlay}
        </DndContext>
        <ScrollBar
          orientation="horizontal"
          className="hidden"
        />
      </ScrollArea>
      <RejectCandidateModal
        open={openRejectModal}
        onOpenChange={setOpenRejectModal}
        onCancel={cancelDragInCache}
        onSuccess={rejectSuccessHandler}
        // candidateId={candidateId}
        // postId={data[0].application_meta!.post_id!}
      />
      {activeCandidate && (
        <>
          <NextStepModal
            candidate={activeCandidate}
            originalCandidateStatus={originalCandidateStatus}
            business={business}
            open={openNextStepModal}
            onOpenChange={setOpenNextStepModal}
            pipelineQueryKey={queryKey}
            onInterviewSuccess={() => {
              setOpenNextStepModal(false);
            }}
            onInterviewCancel={() => {
              cancelDragInCache();
              setOpenNextStepModal(false);
            }}
            onOfferSuccess={() => {
              setOpenNextStepModal(false);
              setTimeout(() => {
                setOpenOfferSentModal(true);
              }, 200);
            }}
            onOfferCancel={() => {
              cancelDragInCache();
              setOpenNextStepModal(false);
            }}
            status={nextStepModalStatus}
          />
          <SelectPartnerModal
            open={openSelectPartnerModal}
            onOpenChange={setOpenSelectPartnerModal}
            onSuccess={onSelectPartnerSuccess}
            onCancel={onSelectPartnerCancel}
            candidate={activeCandidate}
            queryKey={queryKey}
          />
        </>
      )}
      <OfferSentModal
        open={openOfferSentModal}
        onOpenChange={setOpenOfferSentModal}
      />
    </>
  );
};

export default BusinessPipelineBoard;
