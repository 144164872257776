import { createFileRoute, Link } from '@tanstack/react-router';

import BackButton from '@/components/back-button';
import SubmitCandidateForm from '@/components/forms/partner/submit-candidate/submit-candidate-form';
import FullPageLoader from '@/components/full-page-loader';
import { Logo } from '@/components/logo';
import RoleDetails from '@/components/roles/business/role-details/role-details';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetJobPostById } from '@/hooks/business-roles';
import { useGetPostByIdPublicly } from '@/hooks/post';

export const Route = createFileRoute('/_authenticated/partner/submit-candidate/$roleId')({
  component: SubmitCandidate,
});

function SubmitCandidate() {
  const { roleId } = Route.useParams();
  const { data: partner } = useGetCurrentBusiness({});

  const { data: publicRole } = useGetPostByIdPublicly({
    params: { path: { postId: roleId } },
    reactQueryOptions: {
      enabled: !!roleId && !!partner?.id,
    },
  });

  const { data: role, isLoading: isRoleLoading } = useGetJobPostById({
    params: { path: { postId: roleId, businessId: publicRole?.business_id?.toString() || '' } },
    reactQueryOptions: {
      enabled: !!roleId && !!publicRole?.business_id,
    },
  });

  if (isRoleLoading) {
    return <FullPageLoader />;
  }

  if (!role) {
    return <div>Role not found</div>;
  }

  if (!role.is_quick_apply_enabled) {
    return <div>Quick apply is not enabled for this role</div>;
  }

  return (
    <main className="space-y-8 px-6 py-8 3xl:gap-6 3xl:px-8 3xl:py-12">
      <BackButton
        backRoute="/partner"
        className="ml-6 3xl:ml-8"
      />
      <div className="grid grid-cols-[1.5fr_3fr] gap-8">
        <aside className="space-y-8 px-6 3xl:px-8">
          <header className="space-y-4">
            <Link to="/partner">
              <Logo className="-ml-3.5" />
            </Link>
            <p className="font-medium text-primary-dark-60">
              Please fill out the below form to submit a Candidate for this role
            </p>
          </header>
          <SubmitCandidateForm
            roleId={roleId}
            partnerId={partner?.id.toString() || ''}
          />
        </aside>
        <RoleDetails role={role} />
      </div>
    </main>
  );
}
