import client, { TRequestOptions } from '@/lib/api';

import { BUSINESS_PARTNER_STATS_ENDPOINTS } from './business-partner-stats.endpoints';
import { TGetPartnerDetailStatsRequest, TGetPartnerStatsRequest } from './business-partner-stats.types';

export const getPartnerStats = async ({ signal, params }: TRequestOptions<TGetPartnerStatsRequest>) => {
  const { data } = await client.GET(BUSINESS_PARTNER_STATS_ENDPOINTS.GET_PARTNER_STATS, {
    signal,
    params,
  });

  return data?.data;
};

export const getPartnerDetailStats = async ({ signal, params }: TRequestOptions<TGetPartnerDetailStatsRequest>) => {
  const { data } = await client.GET(BUSINESS_PARTNER_STATS_ENDPOINTS.GET_PARTNER_DETAIL_STATS, {
    signal,
    params,
  });

  return data?.data;
};
