import { UilExclamationCircle } from '@iconscout/react-unicons';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';

interface DuplicateCandidateModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  isSubmitting: boolean;
  onSubmitConfirm: () => void;
}

const DuplicateCandidateModal = ({ open, onOpenChange, onSubmitConfirm }: DuplicateCandidateModalProps) => {
  return (
    <AlertDialog
      open={open}
      onOpenChange={onOpenChange}
    >
      <AlertDialogContent className="max-w-[32.75rem]">
        <AlertDialogHeader>
          <AlertDialogTitle>
            <div className="flex items-center gap-2">
              <span className="flex size-8 items-center justify-center rounded-lg border border-primary-dark-10 shadow-xs">
                <UilExclamationCircle className="size-6" />
              </span>
              Candidate Already Submitted
            </div>
          </AlertDialogTitle>
          <AlertDialogDescription>
            <p>This candidate has already been submitted for this role.</p>
            <br />
            <p>If you submit this candidate, your client team will be informed and will reach out to discuss.</p>
            <br />
            <p>Alternatively, please contact your client representative or Cancel your submission.</p>
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onSubmitConfirm}>Submit</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DuplicateCandidateModal;
