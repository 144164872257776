import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';

import { AlertDialogCancel, AlertDialogFooter } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MultiSelect } from '@/components/ui/multi-select';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Select } from '@/components/ui/select';

import {
  useGetBusinessAuthMethods,
  useGetCurrentBusiness,
  useGetCurrentPartnerBusiness,
  useInviteBusinessTeamMember,
  useInvitePartnerBusinessTeamMember,
} from '@/hooks/business';
import { useGetCurrentUser } from '@/hooks/user';

import { USER_TYPE } from '@/services/user';

import { Optional } from '@/lib/types';

import { TAccountTypeSelectOption, TBusinessAuthMethod, teamTypeOptions } from '@/constants/business-team';

import { inviteTeamSchema, TInviteTeamFormData } from '@/validation-schemas/business/invite-team-schema';

const accountTypeOptions = [
  {
    label: 'Admin',
    value: 'admin',
    description: 'Can change member permissions. Can invite team members to the company page.',
  },
  {
    label: 'Member',
    value: 'member',
    description:
      'Cannot change company page settings or invite team members. Permissions granted by Workspace Owner and/or Admin user.',
  },
  {
    label: 'View only',
    value: 'view_only',
    description:
      'Can view roles and pipeline only and no action(s) can be taken. Users will need to be promoted to Members by Workspace Owner and/or Admin users if further access is required.',
  },
];

export const InviteTeamMemberForm: React.FC<{ onInviteSent: () => void }> = ({ onInviteSent }) => {
  const { data: user } = useGetCurrentUser({});
  const { data: business } = useGetCurrentBusiness({});
  const { data: partnerSelectedBusiness } = useGetCurrentPartnerBusiness();
  const { data: authMethodsResponse } = useGetBusinessAuthMethods({
    params: {
      path: {
        businessId: business?.id?.toString() ?? '',
      },
    },
    reactQueryOptions: {
      enabled: !!business?.id,
    },
  });

  const isPartner = user?.user_type === USER_TYPE.PARTNER;
  const partnerId = isPartner ? business?.id?.toString() : '';

  const authMethods = authMethodsResponse?.items || [];
  const defaultAuthMethod = authMethods.find((method) => method.is_default);

  const authMethodOptions = authMethods.map((method) => ({
    label: method.is_default ? `${method.label} (Default)` : method.label,
    value: method.name,
  }));

  const form = useForm<TInviteTeamFormData>({
    resolver: zodResolver(inviteTeamSchema),
    defaultValues: {
      account_type: '',
      email: '',
      team: [],
      first_name: '',
      last_name: '',
      user_auth_method: defaultAuthMethod?.name ?? undefined,
    },
    values: {
      account_type: '',
      email: '',
      team: [],
      first_name: '',
      last_name: '',
      user_auth_method: defaultAuthMethod?.name ?? null,
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = form;

  const { mutate: inviteBusinessTeamMember, isPending: isInviteBusinessTeamMemberPending } =
    useInviteBusinessTeamMember({
      params: { path: { businessId: business?.id?.toString() ?? '' } },
      reactQueryOptions: {
        onSuccess: () => {
          onInviteSent();
        },
      },
    });

  const { mutate: invitePartnerBusinessTeamMember, isPending: isInvitePartnerBusinessTeamMemberPending } =
    useInvitePartnerBusinessTeamMember({
      params: { path: { businessId: partnerSelectedBusiness?.id?.toString() ?? '', partnerId: partnerId ?? '' } },
      reactQueryOptions: {
        onSuccess: () => {
          onInviteSent();
        },
      },
    });

  const onSubmit: SubmitHandler<TInviteTeamFormData> = (data) => {
    const body = {
      email: data.email,
      team: data.team.join(';'),
      account_type: data.account_type as TAccountTypeSelectOption['value'],
      first_name: data.first_name,
      last_name: data.last_name,
      user_auth_method: data.user_auth_method as Optional<TBusinessAuthMethod>,
    };

    if (isPartner) {
      invitePartnerBusinessTeamMember({
        params: {
          path: {
            businessId: partnerSelectedBusiness?.id?.toString() ?? '',
            partnerId: partnerId ?? '',
          },
        },
        body,
      });
    } else {
      inviteBusinessTeamMember({
        params: {
          path: {
            businessId: business?.id?.toString() ?? '',
          },
        },
        body,
      });
    }
  };

  return (
    <FormProvider {...form}>
      <Form
        className="space-y-4 overflow-auto"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex items-center gap-x-8">
          <FormField
            control={control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="First name"
                    {...field}
                    error={!!errors?.first_name?.message}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Last name"
                    {...field}
                    error={!!errors?.last_name?.message}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
        </div>
        <FormField
          name="account_type"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Account type</FormLabel>
              <FormControl>
                <Select
                  options={accountTypeOptions}
                  selected={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.account_type?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="email"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  error={!!errors?.email?.message}
                  placeholder="Email address"
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="team"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Team</FormLabel>
              <FormControl>
                <MultiSelect
                  options={teamTypeOptions.map((option) => ({ label: option, value: option }))}
                  selected={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.team?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        {/* Auth Method */}
        {authMethodOptions.length > 1 && (
          <FormField
            control={form.control}
            name="user_auth_method"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel>Select login method</FormLabel>
                <FormControl>
                  <RadioGroup
                    defaultValue={field.value as string | undefined}
                    value={field.value as string | undefined}
                    className="flex gap-x-1"
                    onValueChange={(value: string) => {
                      field.onChange(value);
                      setValue('user_auth_method', value);
                    }}
                  >
                    {authMethodOptions.map((option) => (
                      <FormItem
                        key={option.value}
                        className="flex items-center space-x-3 space-y-0"
                      >
                        <FormControl>
                          <RadioGroupItem value={option.value || ''} />
                        </FormControl>
                        <FormLabel>{option.label}</FormLabel>
                      </FormItem>
                    ))}
                  </RadioGroup>
                </FormControl>
              </FormItem>
            )}
          />
        )}
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            type="submit"
            loading={isPartner ? isInvitePartnerBusinessTeamMemberPending : isInviteBusinessTeamMemberPending}
            loadingText="Sending invite"
          >
            Send invite
          </Button>
        </AlertDialogFooter>
      </Form>
    </FormProvider>
  );
};
