import { ChangeEvent, forwardRef, Fragment, useState } from 'react';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Input } from '@headlessui/react';
import { UilAngleDown, UilCheck } from '@iconscout/react-unicons';

import useFloatingForDropdown from '@/hooks/useFloatingForDropdown';

import { cn } from '@/lib/utils';

import { inputVariants } from './input';

interface IOption {
  value: string;
  label: string;
}

interface IComboBoxProps {
  options: IOption[];
  selected: IOption | null;
  onSelect: (value: string) => void;
  placeholder?: string;
  error?: boolean;
  className?: string;
  isLoading?: boolean;
  onQueryChange?: (query: string) => void;
  disableAlphabeticalSorting?: boolean;
  disabled?: boolean;
}

const ComboBox = forwardRef<HTMLInputElement, IComboBoxProps>(
  (
    {
      selected,
      onSelect,
      options,
      className,
      error,
      onQueryChange,
      isLoading,
      placeholder,
      disableAlphabeticalSorting,
      disabled,
    },
    ref
  ) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { refs, floatingStyles } = useFloatingForDropdown();

    const isSelected = (value: string) => {
      return value === selected?.value;
    };

    const handleSelect = (value: string) => {
      onSelect(value);
      setSearchTerm('');
    };

    const sortedOptions = disableAlphabeticalSorting
      ? options
      : [...options].sort((a, b) => {
          const valueA = typeof a.value === 'string' ? a.value : '';
          const valueB = typeof b.value === 'string' ? b.value : '';
          return valueA.localeCompare(valueB);
        });

    const filteredOptions =
      !onQueryChange && searchTerm
        ? sortedOptions.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
        : sortedOptions;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchTerm(value);
      onQueryChange?.(value);
    };

    return (
      <Combobox
        value={selected}
        onChange={(option) => handleSelect(option?.value || '')}
        onClose={() => {
          onQueryChange?.('');
        }}
        disabled={disabled}
      >
        <ComboboxButton
          as="div"
          ref={refs.setReference}
          className="relative w-full [&[data-open]>div>svg]:rotate-180"
        >
          <ComboboxInput as={Fragment}>
            <Input
              aria-label="Assignee"
              value={searchTerm || selected?.label || ''}
              onChange={handleChange}
              className={cn(inputVariants({ variant: error ? 'error' : 'default' }), className)}
              placeholder={placeholder || 'Start typing here...'}
              ref={ref}
            />
          </ComboboxInput>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">
            <UilAngleDown className="size-6 text-primary-dark-60 transition-transform duration-200" />
          </div>
        </ComboboxButton>

        <ComboboxOptions
          as="div"
          ref={refs.setFloating}
          style={floatingStyles}
          className="z-50 !max-h-[18.75rem] w-[var(--input-width)] overflow-y-auto rounded-lg border border-primary-dark-10 bg-white shadow-lg outline-none scrollbar dark:border-slate-800 dark:bg-slate-950 dark:text-slate-50"
        >
          {isLoading ? (
            <div className="flex min-h-20 w-full items-center justify-center">Loading data</div>
          ) : filteredOptions.length ? (
            filteredOptions?.map((option) => (
              <ComboboxOption
                key={option.value}
                value={option}
                className={cn(
                  'group flex cursor-pointer items-center justify-between px-4 py-3 font-medium text-primary-dark-60 outline-none data-[focus]:bg-primary-dark-10 data-[selected]:bg-primary-dark-5 data-[focus]:text-primary-dark-100 data-[selected]:text-primary-dark-100',
                  isSelected(option.value) && 'bg-primary-dark-5 text-primary-dark-100'
                )}
              >
                <span>{option.label}</span>
                {isSelected(option.value) && <UilCheck className="size-5 text-primary-blue-100" />}
              </ComboboxOption>
            ))
          ) : (
            <div className="flex min-h-20 w-full items-center justify-center">
              <p className="text-center">No results found</p>
            </div>
          )}
        </ComboboxOptions>
      </Combobox>
    );
  }
);

ComboBox.displayName = 'ComboBox';

export default ComboBox;
