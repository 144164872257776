import React, { useEffect, useState } from 'react';
import { VariantProps } from 'class-variance-authority';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button, buttonVariants } from '@/components/ui/button';
import { toast } from '@/components/ui/toaster';

import TimeSlotBooker from '@/components/time-slot-booker';

import { useAddOwnerAvailability, useGetMeetingTimeSlots, useUpdateMeetingOwnerAvailability } from '@/hooks/meetings';

import { MEETING_STATUS, TMeetingStatus } from '@/constants/meeting';

interface SetAvailabilityButtonProps {
  owner_id: number;
  meeting_id: number;
  candidate_id: number;
  interval: number;
  meetingStatus?: TMeetingStatus;
  buttonVariant?: VariantProps<typeof buttonVariants>['variant'];
}

export const SetAvailabilityButton: React.FC<SetAvailabilityButtonProps> = ({
  meeting_id,
  owner_id,
  candidate_id,
  interval,
  meetingStatus,
  buttonVariant = 'default',
}) => {
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<Date[]>([]);
  const [open, setOpen] = useState(false);
  const { data: existingTimeSlots } = useGetMeetingTimeSlots({
    params: {
      path: {
        meeting_id: meeting_id,
      },
    },
    reactQueryOptions: {
      enabled: meetingStatus === MEETING_STATUS.pending,
    },
  });

  //if existingTimeSlots is provided, set the selectedTimeSlots to the existingTimeSlots
  // Used for updating availability
  useEffect(() => {
    if (existingTimeSlots && existingTimeSlots.length > 0 && open) {
      setSelectedTimeSlots(existingTimeSlots.map((slot) => new Date(slot.start_time)));
    }
  }, [existingTimeSlots, open]);

  const handleSelectSlot = (slot: Date) => {
    //If the slot is already selected, deselect it, else add it to the selected slots
    setSelectedTimeSlots((prevSlots) =>
      prevSlots.some((selectedSlot) => selectedSlot.getTime() === slot.getTime())
        ? prevSlots.filter((selectedSlot) => selectedSlot.getTime() !== slot.getTime())
        : [...prevSlots, slot]
    );
  };

  const { mutate: addOwnerAvailability, isPending } = useAddOwnerAvailability({
    reactQueryOptions: {
      onSuccess: () => {
        toast.success('Availability saved successfully');
        setSelectedTimeSlots([]);
        setOpen(false);
      },
    },
  });

  const { mutate: updateMeetingOwnerAvailability, isPending: isUpdatingAvailability } =
    useUpdateMeetingOwnerAvailability({
      reactQueryOptions: {
        onSuccess: () => {
          toast.success('Availability updated successfully');
          setSelectedTimeSlots([]);
          setOpen(false);
        },
      },
    });

  const onSaveAvailability = () => {
    const sortedSelectedTimeSlots = selectedTimeSlots.sort((a, b) => a.getTime() - b.getTime());
    const selectedTimeSlotsJSON = sortedSelectedTimeSlots.map((slot) => slot.toJSON());

    if (meetingStatus === MEETING_STATUS.draft || !existingTimeSlots || existingTimeSlots.length === 0) {
      addOwnerAvailability({
        body: {
          dateTimes: selectedTimeSlotsJSON,
          meeting_id,
          owner_id,
          candidate_id,
        },
      });
    } else {
      updateMeetingOwnerAvailability({
        body: {
          dateTimes: selectedTimeSlotsJSON,
          meeting_id,
          owner_id,
        },
      });
    }
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={setOpen}
    >
      <AlertDialogTrigger asChild>
        <Button
          variant={buttonVariant}
          className="w-full"
        >
          {existingTimeSlots && existingTimeSlots.length > 0 ? 'Update Availability' : 'Set Availability'}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent
        className="gap-6"
        onPointerDown={(e) => e.stopPropagation()}
      >
        <AlertDialogHeader>
          <AlertDialogTitle> Availability </AlertDialogTitle>
          <AlertDialogDescription className="pb-0">
            Please provide your availability for a 2 week timeframe so that candidates are able to book a slot.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <TimeSlotBooker
          selectedSlots={selectedTimeSlots}
          onSlotSelect={handleSelectSlot}
          isSettingAvailability={true}
          interval={interval}
          defaultSelectedDate={
            existingTimeSlots?.[0]?.start_time ? new Date(existingTimeSlots[0].start_time) : undefined
          }
        />
        <AlertDialogFooter>
          {!isPending && <AlertDialogCancel onClick={() => setSelectedTimeSlots([])}>Cancel</AlertDialogCancel>}
          <Button
            onClick={onSaveAvailability}
            disabled={selectedTimeSlots.length === 0 || isPending || isUpdatingAvailability}
            loading={isPending || isUpdatingAvailability}
          >
            Confirm availability
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
