import { keepPreviousData, useQuery } from '@tanstack/react-query';

import {
  BUSINESS_ROLES_ENDPOINTS,
  getAllRolesByBusinessIdV2,
  getJobPostById,
  getRolesStatsByBusinessId,
  TGetAllBusinessRolesV2Request,
  TGetBusinessRolesStatsRequest,
  TGetJobPostByIdRequest,
} from '@/services/business-roles';
import { ONCORE_POSITION_ENDPOINTS } from '@/services/business-roles/oncore-position.endpoints';
import { validateOncorePosition } from '@/services/business-roles/oncore-position.services';
import { TGetOncorePositionValidationRequest } from '@/services/business-roles/oncore-position.types';

import { UseQueryOptions } from '../../hooks/types';

export const useGetRolesStatsByBusinessId = ({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetBusinessRolesStatsRequest>) => {
  const { queryKey = [], ...rest } = reactQueryOptions;
  return useQuery({
    ...rest,
    queryKey: [BUSINESS_ROLES_ENDPOINTS.GET_ROLES_STATS, params.path.businessId, ...queryKey],
    queryFn: ({ signal }) => getRolesStatsByBusinessId({ signal, params }),
  });
};

export const useGetAllRolesByBusinessIdV2 = ({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetAllBusinessRolesV2Request>) => {
  const { queryKey = [], ...rest } = reactQueryOptions;
  return useQuery({
    ...rest,
    queryKey: [BUSINESS_ROLES_ENDPOINTS.GET_ALL_ROLES_BY_BUSINESS_ID_V2, params.path.businessId, ...queryKey],
    queryFn: ({ signal }) => getAllRolesByBusinessIdV2({ signal, params }),
    placeholderData: keepPreviousData,
  });
};

export const useGetJobPostById = ({ reactQueryOptions = {}, params }: UseQueryOptions<TGetJobPostByIdRequest>) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ROLES_ENDPOINTS.GET_JOB_POST_BY_ID, params.path.businessId, params.path.postId],
    queryFn: ({ signal }) => getJobPostById({ signal, params }),
  });
};

/**
 * Oncore Positions
 */
export const useGetOncorePositionValidation = ({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetOncorePositionValidationRequest>) => {
  return useQuery({
    ...reactQueryOptions,
    retry: false,
    queryKey: [ONCORE_POSITION_ENDPOINTS.VALIDATE_ONCORE_POSITION, params.path.tenantId, params.path.positionId],
    queryFn: ({ signal }) => validateOncorePosition({ signal, params }),
  });
};
