import { useEffect } from 'react';
import hotjar from '@hotjar/browser';

const HotjarInject = () => {
  const hotjarVersion = 6;

  useEffect(() => {
    // Only initialize Hotjar in non-production environments
    if (import.meta.env.MODE !== 'production') {
      hotjar.init(import.meta.env.VITE_HOTJAR_ID, hotjarVersion);
    }
  }, []);

  return null;
};

export default HotjarInject;
