import { TCandidateMatchStatus } from '@/services/candidate';

import { ROLE_CANDIDATE_STATUS } from '@/utils/application-status';

// This should happen in the server
type StatusActionStatusMap = {
  status: number;
  actionStatus: string;
  value: TCandidateMatchStatus;
};

export const statusActionMap: StatusActionStatusMap[] = [
  { status: 0, actionStatus: '0', value: 'APPLIED' },
  { status: 1, actionStatus: '1', value: 'SHORTLIST_CANDIDATE' },
  { status: 2, actionStatus: '2', value: 'REJECT_CANDIDATE' },
  { status: 2, actionStatus: '3', value: 'REJECT_CONNECTION' },
  { status: 1, actionStatus: '4', value: 'MEETING_SCHEDULED' },
  { status: 1, actionStatus: '5', value: 'ACCEPT_CONNECTION' },
  { status: 2, actionStatus: '5', value: 'MEETING_COMPLETED' },
  { status: 1, actionStatus: '6', value: 'INTERVIEWING' },
  { status: 1, actionStatus: '7', value: 'OFFER' },
  { status: 1, actionStatus: '8', value: 'HIRED' },
];

export const mapStatus = (status: number, actionStatus: string): TCandidateMatchStatus | undefined => {
  const match = statusActionMap.find((item) => item.status === status && item.actionStatus === actionStatus);
  return match ? match.value : undefined;
};

export const columns: { label: string; status: TCandidateMatchStatus[] }[] = [
  {
    label: 'Candidates',
    status: ['APPLIED', 'MATCHING'],
  },
  {
    label: 'Shortlisted',
    status: ['SHORTLIST_CANDIDATE', ROLE_CANDIDATE_STATUS.ACCEPT_CONNECTION],
  },
  {
    label: 'Interviewing',
    status: ['INTERVIEWING', 'MEETING_SCHEDULED', 'MEETING_COMPLETED'],
  },
  {
    label: 'Rejected',
    status: ['REJECT_CANDIDATE', 'REJECT_CONNECTION'],
  },
  {
    label: 'Offer',
    status: ['OFFER'],
  },
  {
    label: 'Hired',
    status: ['HIRED'],
  },
];
