import { useCreateRoleStore } from '@/store/create-role-store';
import { UilCell, UilClipboardNotes, UilGrin, UilSortAmountDown, UilStar, UilSuitcase } from '@iconscout/react-unicons';
import { Link, useSearch } from '@tanstack/react-router';
import { createPortal } from 'react-dom';

import FeaturedIcon from '@/components/icons/featured-icon';
import { INavLinkProps } from '@/components/navigation/nav-link';

import useCreateRoleStepper from '@/hooks/useCreateRoleStepper';

import { cn } from '@/lib/utils';

import { PORTAL_IDS } from '@/constants/portal-ids';

import { step1Schema, step2Schema, step3Schema, step6Schema } from '@/validation-schemas/business/create-role-schema';

interface ICreateRoleNavigationProps extends INavLinkProps {
  hide?: boolean;
}

const navItems: ICreateRoleNavigationProps[] = [
  {
    label: 'About',
    href: '/business/create-role/step1',
    icon: <UilSuitcase className="size-5 text-primary-dark-100" />,
  },
  {
    label: 'Details',
    href: '/business/create-role/step2',
    icon: <UilClipboardNotes className="size-5 text-primary-dark-100" />,
  },
  {
    label: 'Skills',
    href: '/business/create-role/step3',
    icon: <UilStar className="size-5 text-primary-dark-100" />,
  },
  {
    label: 'Personality',
    href: '/business/create-role/step4',
    icon: <UilGrin className="size-5 text-primary-dark-100" />,
  },
  {
    label: 'Culture',
    href: '/business/create-role/step5',
    icon: <UilCell className="size-5 text-primary-dark-100" />,
  },
  {
    label: 'Stages',
    href: '/business/create-role/step6',
    icon: <UilSortAmountDown className="size-5 text-primary-dark-100" />,
  },
  {
    label: 'Partners',
    href: '/business/create-role/step7',
    icon: <UilSuitcase className="size-5 text-primary-dark-100" />,
  },
  {
    label: 'Review',
    href: '/business/create-role/step8',
    icon: null,
    hide: true,
  },
];

const CreateRoleNavigation = ({ saveFormData }: { saveFormData?: () => void }) => {
  const { currentStep } = useCreateRoleStepper();
  const { data } = useCreateRoleStore();
  const search = useSearch({ from: '/_authenticated/business/create-role' });
  const createRoleContainer = document.getElementById(PORTAL_IDS.createRoleNavigation);

  if (!createRoleContainer) return null;

  const isStepCompleted = (step: number) => {
    switch (step) {
      case 1:
        return step1Schema.safeParse(data).success;
      case 2: {
        const parsedData = {
          ...data,
          startDate: data.startDate ? new Date(data.startDate) : null,
          endDate: data.endDate ? new Date(data.endDate) : null,
        };
        return step2Schema.safeParse(parsedData).success;
      }
      case 3:
        return step3Schema.safeParse(data).success;
      case 4:
        return data.personalityTraits.length > 0;
      case 5:
        return data.cultureFactors.length > 0;
      case 6:
        return step6Schema.safeParse(data).success;
      case 7:
        return data.partners.length > 0 || currentStep === 8;
      default:
        return false;
    }
  };

  const canNavigateToStep = (step: number) => {
    if (step === 4 || step === 5) {
      return isStepCompleted(3);
    }

    if (step === 7) {
      return isStepCompleted(6);
    }

    return isStepCompleted(step - 1) || step === currentStep || isStepCompleted(step);
  };

  const render = () => (
    <div className="flex flex-col gap-4">
      {navItems.map(
        ({ href, icon, label, exact, hide }, index) =>
          !hide && (
            <Link
              key={href}
              to={href}
              search={search}
              className={cn(
                'flex items-center gap-4 rounded-2xl border bg-white p-4 text-lg font-semibold text-primary-dark-100 shadow-xs 2xl:gap-6 3xl:text-xl',
                canNavigateToStep(index + 1) ? 'cursor-pointer' : 'cursor-not-allowed'
              )}
              activeProps={{
                className: 'ring-4 border-primary-blue-40 ring-primary-blue-10',
              }}
              inactiveProps={{
                className: 'border-primary-dark-10',
              }}
              activeOptions={{
                exact,
              }}
              mask={{
                to: '/business/create-role',
                search,
                unmaskOnReload: false,
              }}
              disabled={!canNavigateToStep(index + 1)}
              onClick={saveFormData ? () => saveFormData() : undefined}
            >
              {isStepCompleted(index + 1) ? (
                <FeaturedIcon variant="success" />
              ) : (
                <span className="flex size-8 items-center justify-center rounded-lg border border-primary-dark-10 bg-white shadow-xs">
                  {icon}
                </span>
              )}
              <span className="mt-0.5">{label}</span>
            </Link>
          )
      )}
    </div>
  );

  return createPortal(render(), createRoleContainer);
};

export default CreateRoleNavigation;
