import { useEffect } from 'react';
import { UilInfoCircle } from '@iconscout/react-unicons';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';

import Avatar from '@/components/ui/avatar';
import ComboBox from '@/components/ui/combo-box';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Select } from '@/components/ui/select';
import { toast } from '@/components/ui/toaster';

import FileUploader from '@/components/file-uploader';
import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentCandidateSuspense, useUpdateCurrentCandidate } from '@/hooks/candidate';
import { useCityList } from '@/hooks/useCityList';
import { useCountryList } from '@/hooks/useCountryList';
import { useGetCurrentUser } from '@/hooks/user';
import { useSearchable } from '@/hooks/useSearchable';
import { useGetValuesMapByGroup, useGetValuesMapByGroupAndCode } from '@/hooks/values-map';

import { updateCurrentUser } from '@/services/user';

import { getFormattedDataForAPI } from '@/utils/format';
import { emojiToCountryCode, getOptionsForSelectFromValuesMap } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

import FormFooter from '../form-footer';
import FormNavigationBlocker from '../form-navigation-blocker';
import { IFormProps } from '../types';

type FormValues = {
  residency_status: string;
  city: string;
  country: string;
  image_url: string;
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: Record<keyof FormValues, { type: string; message: string }> = {} as Record<
    keyof FormValues,
    { type: string; message: string }
  >;

  if (!values.residency_status || values.residency_status === '') {
    errors.residency_status = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.country || values.country === '') {
    errors.country = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.city || values.city === '') {
    errors.city = {
      type: 'required',
      message: 'This is required.',
    };
  }

  return {
    values: Object.keys(errors).length === 0 ? values : {},
    errors,
  };
};

const ProfileFrom: React.FC<IFormProps> = ({ onSuccess, isOnboarding }) => {
  const { data } = useGetCurrentCandidateSuspense({});
  const { data: user } = useGetCurrentUser({});

  const form = useForm<FormValues>({
    defaultValues: {
      residency_status: data?.residency_status || '',
      city: data?.city || '',
      country: data?.country || '',
      image_url: data?.image_url || '',
    },
    values: {
      residency_status: data?.residency_status || '',
      city: data?.city || '',
      country: data?.country || '',
      image_url: data?.image_url || '',
    },
    resolver,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = form;

  const selectedCountry = watch('country');
  const selectedCity = watch('city');

  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useUpdateCurrentCandidate({
    reactQueryOptions: {
      onSuccess: () => {
        if (!isOnboarding) {
          toast.success('Account updated successfully!');
        }
        onSuccess?.();
      },
    },
  });

  const { data: countryList, isLoading: isCountryListLoading } = useCountryList();

  const { data: cityList, isLoading: isCityListLoading } = useCityList(selectedCountry);
  const { setSearchText: setCitySearchText, filteredData: filteredCityList } = useSearchable({
    data: cityList?.data || [],
    limit: 10,
  });
  const selectedCountryCode = emojiToCountryCode(
    countryList?.find((country) => country.name.common?.toLowerCase() === selectedCountry.toLowerCase())?.flag || null
  );

  const { data: cityListFromValueMap, isLoading: isCitiesListLoading } = useGetValuesMapByGroupAndCode({
    params: {
      query: {
        value_group: VALUES_GROUP.CITY,
        code: selectedCountryCode || '',
      },
    },
    reactQueryOptions: {
      enabled: selectedCountryCode === 'AU' || selectedCountryCode === 'NZ', // Enable only when country code is AU or NZ
    },
  });

  const formattedCitiesListFromValueMap =
    cityListFromValueMap?.items.map((s) => s.description).filter((s): s is string => s !== undefined && s !== null) ||
    [];

  const { setSearchText: setValueMapCitySearch, filteredData: filteredCitiesFormValueMap } = useSearchable({
    data: formattedCitiesListFromValueMap,
  });

  const citiesOptions =
    selectedCountryCode === 'AU' || selectedCountryCode === 'NZ'
      ? filteredCitiesFormValueMap.map((city) => ({
          value: city,
          label: city,
        })) || []
      : filteredCityList
          ?.filter((s): s is string => s !== undefined && s !== null)
          .map((city) => ({
            value: city,
            label: city,
          })) || [];

  const { data: residencyStatusList, isLoading: isResidencyStatusListLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.RESIDENCY,
      },
    },
  });

  const handleFileUpload = (url: string) => {
    setValue('image_url', url, { shouldDirty: true });
  };

  const handleCityQueryChange = (value: string) => {
    if (selectedCountryCode === 'AU' || selectedCountryCode === 'NZ') {
      setValueMapCitySearch(value);
    } else {
      setCitySearchText(value);
    }
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    updateCurrentUser({
      body: {
        dob: user?.dob || null,
        email: user?.email || '',
        image_url: data.image_url,
        secondary_phone: user?.secondary_phone || null,
        title: user?.title || null,
        username: user?.email || '',
        country: data.country,
      },
    });
    updateCandidate({ body: { ...getFormattedDataForAPI(data) } });
  };

  // Reset the city when the country is changed
  useEffect(() => {
    if (selectedCountryCode) {
      const citiesList1 = citiesOptions.map((s) => s.value);
      if (!!citiesList1 && !citiesList1.includes(selectedCity)) {
        setValue('city', '');
      }
    }
  }, [selectedCountry]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8">
          <div className="flex h-[70px] items-center gap-4">
            <Avatar
              src={getValues('image_url')}
              size="xl"
            />
            <div className="flex flex-col gap-4">
              <FileUploader
                acceptedFileTypes={['image/jpeg', 'image/png']}
                keyPrefix={`candidate/${data?.id}`}
                onFileUpload={handleFileUpload}
                formError={!!errors?.image_url?.message}
              />
              <p className="text-xs font-medium leading-[18px] text-primary-dark-60">
                Upload a JPEG or PNG file no bigger than 6mb
              </p>
            </div>
          </div>
          <div className="flex w-max rounded-lg bg-primary-blue-5 px-2 py-1 text-base font-medium leading-normal text-primary-blue-100">
            <UilInfoCircle
              width={18}
              height={18}
              className="my-auto mr-2"
            />
            <span>Your profile photo will be visible to employers</span>
          </div>
        </div>

        <FormField
          name="country"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Country of residence</FormLabel>
              <FormControl>
                <ComboBox
                  options={
                    countryList
                      ?.map((country) => country.name.common)
                      ?.filter((s): s is string => s !== undefined && s !== null)
                      .map((country) => ({
                        value: country,
                        label: country,
                      })) || []
                  }
                  selected={field.value ? { value: field.value, label: field.value } : null}
                  onSelect={(value) => field.onChange(value)}
                  error={!!errors?.country?.message}
                  isLoading={isCountryListLoading}
                  disableAlphabeticalSorting
                  placeholder="Start typing here…"
                  ref={field.ref}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="city"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>City</FormLabel>
              <FormControl>
                <ComboBox
                  options={citiesOptions}
                  selected={field.value ? { value: field.value, label: field.value } : null}
                  onSelect={(value) => field.onChange(value)}
                  error={!!errors?.city?.message}
                  isLoading={isCityListLoading || isCitiesListLoading}
                  onQueryChange={handleCityQueryChange}
                  placeholder="Start typing here…"
                  ref={field.ref}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="residency_status"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Residency</FormLabel>
              <FormControl>
                <Select
                  options={getOptionsForSelectFromValuesMap(residencyStatusList)}
                  selected={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.residency_status?.message}
                  isLoading={isResidencyStatusListLoading}
                  disableAlphabeticalSorting
                  ref={field.ref}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      {isOnboarding ? (
        <OnboardingFooter
          isLoading={isUpdatingCandidate}
          handleNext={handleSubmit(onSubmit)}
        />
      ) : (
        <>
          <FormNavigationBlocker />
          <FormFooter
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isUpdatingCandidate}
          />
        </>
      )}
    </FormProvider>
  );
};

export default ProfileFrom;
