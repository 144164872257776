import { useQuery } from '@tanstack/react-query';

import {
  BUSINESS_PARTNER_STATS_ENDPOINTS,
  getPartnerDetailStats,
  getPartnerStats,
} from '@/services/business-partner-stats';
import {
  TGetPartnerDetailStatsRequest,
  TGetPartnerStatsRequest,
} from '@/services/business-partner-stats/business-partner-stats.types';

import { type UseQueryOptions } from '../types';

export function useGetPartnerStats({ reactQueryOptions, params }: UseQueryOptions<TGetPartnerStatsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_PARTNER_STATS_ENDPOINTS.GET_PARTNER_STATS, params?.path.businessId],
    queryFn: ({ signal }) => getPartnerStats({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetPartnerDetailStats({
  reactQueryOptions,
  params,
}: UseQueryOptions<TGetPartnerDetailStatsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [
      BUSINESS_PARTNER_STATS_ENDPOINTS.GET_PARTNER_DETAIL_STATS,
      params?.path.businessId,
      params?.path.partnerId,
    ],
    queryFn: ({ signal }) => getPartnerDetailStats({ signal, params }),
    staleTime: Infinity,
  });
}
