import * as React from 'react';
import { UilSearch, UilSuitcaseAlt } from '@iconscout/react-unicons';
import { useNavigate } from '@tanstack/react-router';
import { CommandLoading, CommandSeparator } from 'cmdk';

import Avatar from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import Separator from '@/components/ui/separator';

import { useGetCurrentBusiness, useGetCurrentPartnerBusiness } from '@/hooks/business';
import { useGlobalSearch } from '@/hooks/global-search';
import { useDebounce } from '@/hooks/useDebounce';
import { useGetCurrentDashboardURL } from '@/hooks/user';

import { USER_TYPE } from '@/services/user';

interface GlobalSearchProps {
  role: USER_TYPE;
}

export default function GlobalSearch({ role }: GlobalSearchProps) {
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const debounceSearchText = useDebounce(searchText, 300);
  const dashboardUrl = useGetCurrentDashboardURL();
  const navigate = useNavigate();

  const { data: partnerSelectedBusiness } = useGetCurrentPartnerBusiness();
  const { data: businessOrPartner } = useGetCurrentBusiness({});

  const isPartner = role === USER_TYPE.PARTNER;
  const businessId = isPartner ? partnerSelectedBusiness?.id?.toString() : businessOrPartner?.id?.toString();
  const partnerId = isPartner ? businessOrPartner?.id?.toString() : null;

  const { data: searchResults, isLoading } = useGlobalSearch({
    params: {
      query: {
        q: debounceSearchText,
        ...(businessId && { businessId }),
        ...(partnerId && { partnerId }),
      },
    },
    reactQueryOptions: {
      enabled: !!businessId, // Only fetch if businessId is available
    },
  });

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleSelect = (selectFn: () => void) => {
    return () => {
      setOpen(false);
      setSearchText('');
      setTimeout(() => {
        selectFn();
      }, 300);
    };
  };

  const navigateToRole = (roleId: string) => {
    navigate({ from: dashboardUrl, to: './role/$roleId', params: { roleId } });
  };

  const navigateToTeam = (userId: string) => {
    navigate({ from: dashboardUrl, to: './team/$profile', params: { profile: userId } });
  };

  const navigateToPartner = (partnerId: string) => {
    navigate({ to: '/business/partners/$partnerId/profile', params: { partnerId } });
  };

  const roles = debounceSearchText ? searchResults?.job_posts?.items : searchResults?.job_posts?.items?.slice(0, 3);
  const team = debounceSearchText ? searchResults?.teams?.items : searchResults?.teams?.items?.slice(0, 3);
  const partners = debounceSearchText ? searchResults?.partners?.items : searchResults?.partners?.items?.slice(0, 3);

  const renderRoles = () => {
    if (roles?.length === 0 || isLoading) return null;

    return (
      <>
        <CommandGroup heading="Roles">
          {roles?.map((role) => (
            <CommandItem
              key={role.id}
              onSelect={handleSelect(() => navigateToRole(role.id.toString()))}
              value={`role-${role.id}`}
            >
              <UilSuitcaseAlt className="-mt-0.5 size-6" />
              <span>{role.title}</span>
              {/* <span className="hidden">role-{role.id}</span> */}
            </CommandItem>
          ))}
        </CommandGroup>
      </>
    );
  };

  const renderTeam = () => {
    if (team?.length === 0 || isLoading) return null;

    return (
      <CommandGroup heading="Team">
        {team?.map((member) => (
          <CommandItem
            key={member.id}
            onSelect={handleSelect(() => navigateToTeam(member.id.toString()))}
            value={`team-${member.id}`}
          >
            <Avatar
              size="xs"
              src={member.image_url}
            />
            <span>
              {member.first_name} {member.last_name}
            </span>
            {/* <span className="hidden">member-{member.id}</span> */}
          </CommandItem>
        ))}
      </CommandGroup>
    );
  };

  const renderPartners = () => {
    if (partners?.length === 0 || isLoading || isPartner) return null;

    return (
      <CommandGroup heading="Partners">
        {partners?.map((partner) => (
          <CommandItem
            key={partner.id}
            onSelect={handleSelect(() => navigateToPartner(partner.id.toString()))}
            value={`partner-${partner.id}`}
          >
            <Avatar
              size="xs"
              src={partner.logo_url}
            />
            <span>{partner.name}</span>
            {/* <span className="hidden">partner-{partner.id}</span> */}
          </CommandItem>
        ))}
      </CommandGroup>
    );
  };

  const renderLoading = () => {
    return (
      <div className="my-6 flex flex-col items-center justify-center gap-4 text-sm">
        {/* {new Array(6).fill(null).map((_, index) => (
          <Skeleton
            key={index}
            className="h-8 w-full rounded-none"
          />
        ))} */}
        Loading...
      </div>
    );
  };

  return (
    <>
      <Button
        variant="tertiary"
        className="w-full justify-start text-base text-primary-dark-60"
        size="md"
        onClick={() => setOpen(true)}
      >
        <UilSearch className="size-6" />
        Search
      </Button>
      <CommandDialog
        open={open}
        onOpenChange={() => {
          setOpen(false);
          setSearchText('');
        }}
      >
        <Command shouldFilter={false}>
          <div className="min-h-[400px]">
            <div className="flex items-center pl-4">
              <UilSearch className="size-5 text-primary-dark-60" />
              <CommandInput
                placeholder="Search"
                hideIcon
                inputClassName="!border-none !ring-0 shadow-none pl-2"
                className="flex-1"
                onValueChange={handleSearch}
                value={searchText}
              />
            </div>
            <Separator size="xs" />
            <CommandList>
              {isLoading ? (
                <CommandLoading>{renderLoading()}</CommandLoading>
              ) : (
                <CommandEmpty>{'No results found'}</CommandEmpty>
              )}

              {renderRoles()}
              <CommandSeparator />
              {renderTeam()}
              <CommandSeparator />
              {renderPartners()}

              {/* <CommandSeparator />
            <CommandGroup heading="Team">
              <CommandItem>
                <Avatar size="xs" />
                <span>Jon Doe Junior</span>
              </CommandItem>
            </CommandGroup> */}
            </CommandList>
          </div>
        </Command>
      </CommandDialog>
    </>
  );
}
