import { zodResolver } from '@hookform/resolvers/zod';
// import { parse, parseISO } from 'date-fns';
import { useForm } from 'react-hook-form';

import { AlertDialogCancel, AlertDialogFooter } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Select } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';

import useAuth from '@/hooks/useAuth';

import { USER_TYPE } from '@/services/user';

import {
  RESCHEDULE_MEETING_REASON_OPTIONS_BUSINESS,
  RESCHEDULE_MEETING_REASON_OPTIONS_CANDIDATE,
} from '@/constants/meeting';

import { rescheduleMeetingSchema, TRescheduleMeetingFormData } from '@/validation-schemas/reschedule-meeting-schema';

const RescheduleForm: React.FC<{ onSubmit: (data: TRescheduleMeetingFormData) => void }> = ({ onSubmit }) => {
  const { user } = useAuth();
  const isCandidate = user?.app_user_type === USER_TYPE.CANDIDATE;
  const form = useForm<TRescheduleMeetingFormData>({
    resolver: zodResolver(rescheduleMeetingSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  return (
    <FormProvider {...form}>
      <Form className="space-y-4">
        <FormField
          name="reason"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Reason</FormLabel>
              <FormControl>
                <Select
                  options={
                    isCandidate
                      ? RESCHEDULE_MEETING_REASON_OPTIONS_CANDIDATE
                      : RESCHEDULE_MEETING_REASON_OPTIONS_BUSINESS
                  }
                  selected={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.reason?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="description"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter description here..."
                  {...field}
                  error={!!errors?.description?.message}
                  rows={4}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <AlertDialogFooter>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </Button>
      </AlertDialogFooter>
    </FormProvider>
  );
};

export default RescheduleForm;
