import { forwardRef, ReactNode } from 'react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { UilAngleDown, UilCheck } from '@iconscout/react-unicons';

import useFloatingForDropdown from '@/hooks/useFloatingForDropdown';

import { cn } from '@/lib/utils';

import { inputVariants } from './input';

export type OptionType<T = unknown> = {
  value: string;
  label: string | ReactNode;
  description?: string;
  metadata?: T;
  disabled?: boolean;
};

interface SelectProps<T> {
  options: OptionType<T>[];
  selected: string | null;
  onChange: (value: string) => void;
  placeholder?: string;
  error?: boolean;
  className?: string;
  isLoading?: boolean;
  disableAlphabeticalSorting?: boolean;
  disabled?: boolean;
  itemComponent?: (option: OptionType<T>) => ReactNode;
}

const SelectComponent = <T,>(
  {
    options,
    selected,
    onChange,
    placeholder = 'Select Option',
    error,
    isLoading,
    disableAlphabeticalSorting,
    itemComponent,
    ...props
  }: SelectProps<T>,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const { refs, floatingStyles } = useFloatingForDropdown();

  const sortedOptions = disableAlphabeticalSorting
    ? options
    : [...options].sort((a, b) => {
        const valueA = typeof a.value === 'string' ? a.value : '';
        const valueB = typeof b.value === 'string' ? b.value : '';
        return valueA.localeCompare(valueB);
      });

  const selectedOption = options.find((option) => option.value === selected);
  const selectedLabel = selectedOption?.label || placeholder;

  const renderCustomOption = (option: OptionType<T>, selected?: boolean) => {
    if (itemComponent) {
      return itemComponent(option);
    }
    return (
      <span className={cn('font-medium leading-[1.125rem] text-primary-dark-60', selected && 'text-primary-dark-100')}>
        {option.label}
      </span>
    );
  };

  const renderOption = (option: OptionType<T>, selected?: boolean) => {
    return (
      <div className="flex flex-col space-y-1">
        <span
          className={cn('font-medium leading-[1.125rem] text-primary-dark-60', selected && 'text-primary-dark-100')}
        >
          {option.label}
        </span>
        {option.description && (
          <span
            className={cn(
              'text-xs font-medium leading-[1.125rem] text-primary-dark-40',
              selected && 'text-primary-dark-60'
            )}
          >
            {option.description}
          </span>
        )}
      </div>
    );
  };

  return (
    <Listbox
      value={selected}
      onChange={onChange}
      disabled={props.disabled}
      as="div"
    >
      <ListboxButton
        as="div"
        ref={refs.setReference}
      >
        <button
          className={cn(
            inputVariants({ variant: error ? 'error' : 'default' }),
            'relative w-full min-w-28 justify-between [&[data-open]>svg]:rotate-180',
            props.className
          )}
          type="button"
          ref={ref}
        >
          {itemComponent && selectedOption ? (
            renderCustomOption(selectedOption)
          ) : (
            <span
              className={cn('w-[95%] truncate text-start', selected ? 'text-primary-dark-100' : 'text-primary-dark-60')}
            >
              {selectedLabel}
            </span>
          )}
          <UilAngleDown
            className={cn(
              'pointer-events-none absolute right-4 size-6 text-primary-dark-60 transition-transform duration-200'
            )}
          />
        </button>
      </ListboxButton>
      <ListboxOptions
        ref={refs.setFloating}
        style={floatingStyles}
        className="z-50 !max-h-[18.75rem] w-[var(--button-width)] overflow-y-auto rounded-lg border border-primary-dark-10 bg-white shadow-lg outline-none scrollbar dark:border-slate-800 dark:bg-slate-950 dark:text-slate-50"
      >
        {isLoading ? (
          <div className="px-2 py-1.5 text-sm text-primary-dark-60">Loading...</div>
        ) : sortedOptions.length === 0 ? (
          <div className="px-2 py-1.5 text-sm text-primary-dark-60">No results found.</div>
        ) : (
          sortedOptions.map((option) => (
            <ListboxOption
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              className={cn(
                'relative cursor-pointer select-none py-4 pl-3 pr-9 data-[focus]:bg-primary-dark-10 data-[selected]:bg-primary-dark-5',
                option.disabled && 'cursor-not-allowed opacity-50'
              )}
            >
              <div className="flex items-center justify-between">
                {itemComponent
                  ? renderCustomOption(option, selected === option.value)
                  : renderOption(option, selected === option.value)}
                {selected === option.value && <UilCheck className="absolute right-3 size-5 text-primary-blue-100" />}
              </div>
            </ListboxOption>
          ))
        )}
      </ListboxOptions>
    </Listbox>
  );
};

SelectComponent.displayName = 'Select';

export const Select = forwardRef(SelectComponent) as <T>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => React.ReactElement;
