import { useState } from 'react';
import { UilExclamationCircle } from '@iconscout/react-unicons';
import { QueryKey } from '@tanstack/react-query';

import { Button, ButtonProps } from '@/components/ui/button';

import { TCandidateForPipeline } from '@/services/pipeline';
import { hasPartnerBeenSelectedForPipelineCandidate } from '@/services/pipeline/piepline.utils';

import SelectPartnerModal from './select-partner-modal';

interface PartnerInfoProps {
  candidate: TCandidateForPipeline;
  queryKey: QueryKey;
  variant?: ButtonProps['variant'];
  showIcon?: boolean;
}

const SelectPartnerButton = ({ candidate, queryKey, showIcon, variant = 'default' }: PartnerInfoProps) => {
  const [open, setOpen] = useState(false);

  if (candidate.metadata?.candidate_status !== 'APPLIED') return null;
  if (candidate?.recruiters && candidate?.recruiters?.length <= 1) return null;

  const hasPartnerBeenSelected = hasPartnerBeenSelectedForPipelineCandidate(candidate.recruiters || []);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="w-full"
        variant={variant}
      >
        {showIcon && <UilExclamationCircle className="size-[1.25rem]" />}
        {hasPartnerBeenSelected ? 'Change Partner' : 'Select Partner'}
      </Button>
      <SelectPartnerModal
        open={open}
        onOpenChange={setOpen}
        onSuccess={() => {
          setOpen(false);
        }}
        candidate={candidate}
        queryKey={queryKey}
      />
    </>
  );
};

export default SelectPartnerButton;
