import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';

type TRequestNewTimeButtonProps = {
  isDisabled: boolean;
  onRequestNewTimesClick: () => void;
};

const RequestNewTimeButton = ({ isDisabled, onRequestNewTimesClick }: TRequestNewTimeButtonProps) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          variant="ghost"
          className="font-medium text-primary-blue-80"
          size={'sm'}
          disabled={isDisabled}
        >
          Request new times
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle iconVariant="warning">Request new times</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to request new time slots for this interview?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>No</AlertDialogCancel>
          <AlertDialogAction
            variant="default"
            onClick={onRequestNewTimesClick}
          >
            Yes
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RequestNewTimeButton;
